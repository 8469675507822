import "./_style.less";
import VuiTitle from "../../../@vendor/components/atoms/Title";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  VuiAlert,
  VuiAuthorized,
  VuiButton,
  VuiCheckbox,
  VuiContentLoading,
  VuiFormCard,
  VuiHelmet,
  VuiNumberFormat,
  VuiSecondaryTitleWrapper,
  VuiStep,
  VuiSteps,
} from "../../../@vendor/components";
import {
  BagIcon,
  BillIcon,
  CartDoneStep,
  CartItem,
  CartPaymentStep,
  DeleteModal,
  ListTextModal,
  NoContentCard,
  StopIcon,
  TickIcon,
} from "../../../components";
import { Divider } from "antd";
import { Cart } from "../../../models/Cart";
import CartRepository from "../../../repositories/CartRepository";
import {
  groupCartListHelper,
  handleErrorLoadDataResponse,
  showNotification,
  useIsMounted,
  usePassportService,
} from "../../../@vendor/utils";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { useAppSelector } from "../../../stores/hooks";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { CreateBookingResponse } from "../../../models/Booking";

interface GroupedCart {
  customer_id: number;
  customer_name: string;
  is_active: boolean;
  is_disabled: boolean;
  list: Cart[];
}

export interface OrderSummary {
  subTotal: number;
  total: number;
}

type StepType = "cart" | "payment" | "done";

const StepsCurrent = {
  cart: 0,
  payment: 1,
  done: 2,
};

const AppCart = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { refetchUser } = usePassportService();
  const { account } = useAppSelector((state) => state.system);
  const [data, setData] = useState<GroupedCart[]>([]);
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [unavailableSchedule, setUnavailableSchedule] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedCart, setCheckedCart] = useState<Cart[]>([]);
  const [orderSummary, setOrderSummary] = useState<OrderSummary>({
    subTotal: 0,
    total: 0,
  });
  const [currentStep, setCurrentStep] = useState<StepType>(
    (searchParams.get("step") as StepType) || "cart"
  );
  const [hasInitialize, setHasInitialize] = useState<boolean>(false);
  const [tempData, setTempData] = useState<Cart[]>([]);
  const isMounted = useIsMounted();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [bookingResponse, setBookingResponse] =
    useState<CreateBookingResponse | null>(null);

  const isUnverifiedUser = !account?.phone_number_verified_at;

  const handleOpenDeleteModal = useCallback((cart: Cart) => {
    setDeleteId(cart.id);
    setShowDeleteModal(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const loadData = useCallback(async () => {
    if (!account) {
      return;
    }

    setContentLoading(true);

    await CartRepository.findAll({
      with: ["assessment.photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          const groupedCart: GroupedCart[] = groupCartListHelper(responseData);
          setUnavailableSchedule(
            responseData.filter((cart) => !cart.is_available).length
          );
          if (searchParams.has("selectAll") && !isUnverifiedUser) {
            searchParams.delete("selectAll");
            setSearchParams(searchParams);

            setData(
              groupedCart.map((grouped) => {
                return {
                  ...grouped,
                  is_active: true,
                  list: grouped.list.map((cart) => ({
                    ...cart,
                    is_active: cart.is_available,
                  })),
                };
              })
            );

            setCheckAll(true);
          } else {
            setData(groupedCart);
          }

          setTempData(responseData);
          setContentLoading(false);
          setHasInitialize(true);
        }
      })
      .catch(() => {
        if (isMounted) {
          handleErrorLoadDataResponse(t, t("common.text.cart"));
          setContentLoading(false);
        }
      });
  }, [isMounted, account]);

  const handleCheckChooseAll = useCallback((data: GroupedCart[]) => {
    const isCheckAll = data.filter((grouped) => grouped.is_active);
    let tempCheckedData: Cart[] = [];
    data.forEach((grouped) => {
      grouped.list.forEach((cart) => {
        if (cart.is_active) {
          tempCheckedData.push(cart);
        }
      });
    });

    if (isMounted) {
      setCheckAll(isCheckAll.length === data.length);
      setCheckedCart(tempCheckedData);
    }
  }, []);

  const handleCheckAll = useCallback(
    (event: CheckboxChangeEvent) => {
      const isChecked = event.target.checked;

      setData((prevState) => {
        let cloneState = [...prevState];
        cloneState = cloneState.map((grouped) => {
          return {
            ...grouped,
            is_active: isChecked,
            list: grouped.list.map((cart) => ({
              ...cart,
              is_active: cart.is_available ? isChecked : false,
            })),
          };
        });
        return cloneState;
      });

      setCheckAll(isChecked);
    },
    [data]
  );

  const handleCheckParent = useCallback(
    (event: CheckboxChangeEvent, item: GroupedCart) => {
      const isChecked = event.target.checked;

      const findDataIndex = data.findIndex((grouped) => {
        return grouped.customer_id === item.customer_id;
      });

      if (findDataIndex !== -1) {
        setData((prevState) => {
          const cloneState = [...prevState];
          cloneState[findDataIndex].is_active = isChecked;
          cloneState[findDataIndex].list = cloneState[findDataIndex].list.map(
            (listData) => {
              return {
                ...listData,
                ...(listData.is_available
                  ? { is_active: isChecked }
                  : { is_active: false }),
              };
            }
          );
          return cloneState;
        });
      }
    },
    [data]
  );

  const handleCheckChildren = useCallback(
    (
      event: CheckboxChangeEvent | null,
      item: Cart,
      checked: boolean = false
    ) => {
      const isChecked = event?.target.checked || checked;

      setData((prevState) => {
        let cloneState: GroupedCart[] = [...prevState];
        cloneState = cloneState.map((grouped) => ({
          ...grouped,
          list: grouped.list.map((listData) => {
            if (listData.id === item?.id) {
              return {
                ...listData,
                is_active: isChecked,
              };
            }
            return listData;
          }),
        }));

        cloneState = cloneState.map((grouped) => {
          const findActive = grouped.list.filter(
            (listData) => listData.is_active
          );
          if (findActive.length === grouped.list.length) {
            return {
              ...grouped,
              is_active: true,
            };
          }
          return {
            ...grouped,
            is_active: false,
          };
        });

        return cloneState;
      });
    },
    [data]
  );

  const handleCalculateSummary = useCallback((data: Cart[]) => {
    const getSubTotalData = data.map((cart) => cart.subtotal);
    const subTotalData = getSubTotalData.reduce(
      (accumulator, value) => accumulator + value,
      0
    );

    setOrderSummary((prevState) => ({
      ...prevState,
      subTotal: subTotalData,
      total: subTotalData,
    }));
  }, []);

  const renderData = useMemo(() => {
    return data.map((item, index) => (
      <VuiFormCard key={index}>
        <VuiCheckbox
          style={{ fontWeight: "bold", color: "#ff00ae" }}
          title={item.customer_name}
          checked={item.is_active}
          disabled={item.is_disabled || isUnverifiedUser}
          onChange={(event: CheckboxChangeEvent) => {
            handleCheckParent(event, item);
          }}
        />

        {item.list.map((cart) => (
          <CartItem
            onCheck={handleCheckChildren}
            data={cart}
            key={cart.id}
            onClickDelete={() => handleOpenDeleteModal(cart)}
            disabled={isUnverifiedUser}
          />
        ))}
      </VuiFormCard>
    ));
  }, [data, handleCheckParent, handleCheckChildren]);

  const handleCheckout = useCallback(() => {
    if (checkedCart.length) {
      setCurrentStep("payment");
      scrollToTop();
    } else {
      showNotification(
        "error",
        t("notification.error.selectOneItem", {
          item: t("common.text.schedule"),
        })
      );
    }
  }, [checkedCart]);

  const handleCheckoutPayment = useCallback((data: CreateBookingResponse) => {
    setBookingResponse(data);
    setCurrentStep("done");
    refetchUser();
    scrollToTop();
  }, []);

  const handleChangeQuery = useCallback(() => {
    const query = {
      carts: checkedCart.map((cart) => cart.id),
    };

    if (!hasInitialize) {
      Object.assign(query, {
        carts: searchParams.getAll("carts"),
      });
    }

    const queryParams = qs.stringify(query, { indices: false });
    setSearchParams(queryParams);
  }, [currentStep, checkedCart, searchParams, tempData, hasInitialize]);

  const handleChangeState = useCallback(() => {
    // change state base on query params on first load
    const cartsParams = searchParams.getAll("carts");
    if (cartsParams.length && tempData.length) {
      const tempCart: Cart[] = [];
      cartsParams.map((param) => {
        const findData = tempData.find((temp) => String(temp.id) === param);
        if (findData) {
          tempCart.push(findData);
        }
      });
      setCheckedCart(tempCart);
      tempCart.forEach((temp) => {
        handleCheckChildren(null, temp, true);
      });
    }
  }, [searchParams, tempData, handleCheckChildren]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  useEffect(() => {
    handleCheckChooseAll(data);
  }, [data]);

  useEffect(() => {
    handleCalculateSummary(checkedCart);
  }, [checkedCart]);

  useEffect(() => {
    handleChangeQuery();
  }, [currentStep, checkedCart]);

  useEffect(() => {
    handleChangeState();
  }, [tempData]);

  return (
    <VuiAuthorized>
      <div id="app-cart-page">
        <VuiHelmet title={t("common.text.cart")} />

        <DeleteModal
          visible={showDeleteModal}
          onClose={handleCloseDeleteModal}
          onSubmit={() => {
            loadData();
            refetchUser();
          }}
          itemName={t("common.text.schedule")}
          repository={CartRepository}
          deleteId={deleteId}
        />

        <div className="container">
          <VuiTitle text={t("common.text.cart")} />

          <div className="cart-steps-wrapper">
            <VuiSteps
              current={StepsCurrent[currentStep]}
              labelPlacement="vertical"
            >
              <VuiStep icon={<BagIcon />} title={t("common.text.cart")} />
              <VuiStep icon={<BillIcon />} title={t("common.text.payment")} />
              <VuiStep icon={<TickIcon />} title={t("common.text.done")} />
            </VuiSteps>
          </div>

          {contentLoading ? (
            <VuiContentLoading loading={contentLoading} />
          ) : (
            <div className="cart-content-wrapper">
              {data.length ? (
                <div className="cart-content">
                  {currentStep === "cart" && (
                    <>
                      <div className="card-list">
                        {unavailableSchedule > 0 && (
                          <VuiAlert
                            style={{ marginBottom: 12 }}
                            type="danger"
                            icon={<StopIcon />}
                            text={t("alert.scheduleUnavailable", {
                              total: unavailableSchedule,
                            })}
                          />
                        )}

                        <VuiFormCard>
                          <VuiCheckbox
                            style={{ fontWeight: "bold" }}
                            title={t("common.text.chooseAll")}
                            checked={checkAll}
                            disabled={isUnverifiedUser}
                            onChange={handleCheckAll}
                          />
                        </VuiFormCard>

                        {renderData}
                      </div>

                      <div className="cart-summary">
                        <VuiSecondaryTitleWrapper
                          title={t("common.text.orderSummary")}
                        />

                        <div style={{ marginBottom: 28, marginTop: 24 }}>
                          <ListTextModal
                            label={`${t("common.text.subTotal")} ${
                              checkedCart.length > 0
                                ? t("common.text.schedulesItem", {
                                    item: checkedCart.length,
                                  })
                                : ""
                            }`}
                            onlyFlex
                            value={
                              <VuiNumberFormat value={orderSummary.subTotal} />
                            }
                          />

                          <Divider />

                          <ListTextModal
                            label={t("common.text.total")}
                            labelTextWeight="bold"
                            valueTextWeight="bold"
                            onlyFlex
                            value={
                              <VuiNumberFormat value={orderSummary.total} />
                            }
                          />
                        </div>

                        <VuiButton
                          buttonProps={{
                            type: "primary",
                            onClick: handleCheckout,
                            disabled: isUnverifiedUser,
                          }}
                          label={t("common.button.checkout")}
                        />
                      </div>
                    </>
                  )}

                  {currentStep === "payment" && (
                    <CartPaymentStep
                      orderSummary={orderSummary}
                      checkedCart={checkedCart}
                      onCheckout={handleCheckoutPayment}
                    />
                  )}

                  {currentStep === "done" && (
                    <CartDoneStep data={bookingResponse} />
                  )}
                </div>
              ) : (
                <div className="cart-no-content">
                  <NoContentCard title={t("common.text.dontHaveCart")} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </VuiAuthorized>
  );
};

export default AppCart;

import "./_style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import clsx from "clsx";
import { FC, useCallback, useEffect, useState } from "react";
import NotificationRepository from "../../../../repositories/NotificationRepository";
import { Notification } from "../../../../models/Notification";
import { Empty } from "antd";
import { formatDateTime } from "../../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import VuiSpin from "../Spin";
import moment from "moment";
import { useAppSelector } from "../../../../stores/hooks";

interface IVuiNotification {
  show: boolean;
  onReadAll: () => void;
}

interface Query {
  page: number;
  per_page: number;
}

const VuiNotification: FC<IVuiNotification> = ({ show, onReadAll }) => {
  const { isLoggedIn } = useAppSelector((state) => state.system);
  const { t } = useTranslation();
  const [data, setData] = useState<Notification[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [query, setQuery] = useState<Query>({
    page: 1,
    per_page: 8,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMarkAllRead = useCallback(async () => {
    await NotificationRepository.markAllAsRead()
      .then(() => {
        setData((prevState) => {
          let cloneState = [...prevState];
          cloneState = cloneState.map((item) => {
            return {
              ...item,
              read_at: moment().toISOString(),
            };
          });
          return cloneState;
        });
        onReadAll();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [isLoading, data, onReadAll]);

  const loadData = useCallback(async () => {
    if (!isLoggedIn) {
      return;
    }

    setIsLoading(true);

    setQuery({
      ...query,
      page: query.page + 1,
    });

    await NotificationRepository.findAll(query)
      .then((response) => {
        const { data: responseData, meta } = response.data;
        setData((prevState) => [...prevState, ...responseData]);
        setTotalData(meta.total);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [query, isLoggedIn]);

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <div
      className={clsx({
        "vui-notification-wrapper": true,
        open: show,
      })}
    >
      <div className="vui-notification-header">
        <Paragraph className="vui-notification-header-title">
          {t("common.text.notification")}
        </Paragraph>
        <Paragraph
          onClick={handleMarkAllRead}
          className="vui-notification-header-action"
        >
          {t("common.text.markAllAsRead")}
        </Paragraph>
      </div>

      <div className="vui-notification-list" id="vui-notification-list">
        {data.length ? (
          <InfiniteScroll
            next={loadData}
            hasMore={data.length < totalData}
            loader={<VuiSpin spinning={isLoading} />}
            dataLength={data.length}
            pullDownToRefreshThreshold={50}
            scrollableTarget="vui-notification-list"
          >
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className={clsx({
                    "vui-notification-item": true,
                    unread: !item.read_at,
                  })}
                >
                  <div className="vui-notification-item-info">
                    <Paragraph className="vui-notification-item-info-message">
                      {item.data.message}
                    </Paragraph>
                    <Paragraph className="vui-notification-item-info-time">
                      {formatDateTime(item.created_at, "LT MMM DD, YYYY")}
                    </Paragraph>
                  </div>
                  <div className="vui-notification-item-status">
                    <span
                      className={`status ${item.read_at ? "" : "unread"}`}
                    />
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ paddingTop: 10, paddingBottom: 20 }}
          />
        )}
      </div>
    </div>
  );
};

export default VuiNotification;

import { api, IResource } from "../@vendor/utils";
import { Media } from "../models/Media";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { AxiosPromise } from "axios";
import { Photo } from "../models/Photo";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IMediaRepository<T> {
  findAll(params: T | null): AxiosPromise<T[]>;
  findOne(id: number, params: T | null): AxiosPromise<T>;
  create(payload: FormData): AxiosPromise<IResource<Photo>>;
}

const Repository: IMediaRepository<Media> = {
  findAll(params = null) {
    return api.get(`${endPoint()}/api/media`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne(id, params = null) {
    return api.get(`${endPoint()}/api/media/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  create(payload) {
    return api.post(`${endPoint()}/api/media`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

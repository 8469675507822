import "./style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";

import {
  VuiButton,
  VuiFormCard,
  VuiHelmet,
} from "../../../../@vendor/components";
import { TickIllustration } from "../../../../components";
import { useNavigate } from "react-router-dom";

const AuthRegisterSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div id="auth-register-success-page">
      <VuiHelmet title={t("common.text.registerSuccess")} />

      <VuiFormCard className="auth-register-success-wrapper">
        <TickIllustration style={{ marginBottom: 16 }} />

        <h4 className="auth-register-success-title">
          {t("common.text.successfullyRegisteredMark")}
        </h4>

        <Paragraph>{t("auth.register.success.description")}</Paragraph>

        <VuiButton
          buttonProps={{
            onClick: () => navigate("/auth/login"),
            type: "primary",
          }}
          label={t("common.button.login")}
        />
      </VuiFormCard>
    </div>
  );
};

export default AuthRegisterSuccessPage;

import { Input, Radio, Typography } from "antd";
import { VuiFormItem } from "../../../../@vendor/components";
import * as React from "react";

const { Text } = Typography;

const CounselingFormCard = () => {
  return (
    <div className="counseling-form-card">
      <div className="counseling-form-card-wrapper">
        <VuiFormItem
          rules={[
            {
              required: true,
            },
          ]}
          name={"name"}
          label={"send by"}
        >
          <Radio.Group>
            <Radio value={"personal"}>Personal Inference</Radio>
            <Radio value={"family"}>Family</Radio>
          </Radio.Group>
        </VuiFormItem>
      </div>
    </div>
  );
};

export default CounselingFormCard;

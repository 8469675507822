import { AnswerTable } from "../../../../models/FormQuestion";
import { VuiFormItem } from "../../../../@vendor/components";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { ICounselingForm } from "../interface";

const CounselingFormTable = ({ question }: ICounselingForm) => {
  const { t } = useTranslation();

  const column: AnswerTable | null = question.answers
    .map((answer) => {
      if (typeof answer !== "string" && answer.type === "column") {
        return answer;
      } else {
        return null;
      }
    })
    .filter((item) => item)[0];

  const row: AnswerTable | null = question.answers
    .map((answer) => {
      if (typeof answer !== "string" && answer.type === "row") {
        return answer;
      } else {
        return null;
      }
    })
    .filter((item) => item)[0];

  return (
    <>
      <div className="ant-col ant-form-item-label">
        <label
          htmlFor="15"
          className="ant-form-item-required"
          title={question.question}
        >
          {question.question}
        </label>
      </div>

      <table className="table-form-wrapper" style={{ width: "100%" }}>
        <thead>
          <tr>
            {column?.list.map((item, index) =>
              !question.has_first_column && !item && index === 0 ? null : (
                <th
                  key={
                    question.has_first_column && index === 0
                      ? "first_th"
                      : `${question.id}_${index}_th`
                  }
                >
                  {item}
                </th>
              )
            )}
          </tr>
        </thead>

        <tbody>
          {row?.list.map((item, rowIndex) => (
            <tr key={`${question.id}_${rowIndex}_tr`}>
              {question.has_first_column && <td>{item}</td>}
              {column?.list.map((columnItem, columnIndex) => {
                if (question.has_first_column && columnIndex === 0) {
                  return null;
                } else {
                  if (!Boolean(columnItem) && columnIndex === 0) {
                    return null;
                  }
                }

                return (
                  <td key={`${question.id}_${rowIndex}-${columnIndex}_td`}>
                    <VuiFormItem
                      rules={[
                        {
                          required: question.is_required,
                          message: t("validation.required", {
                            item: `${item || ""} ${columnItem}`,
                          }),
                        },
                      ]}
                      name={[
                        String(question.id),
                        `row-${rowIndex}-column-${columnIndex}`,
                      ]}
                    >
                      <Input />
                    </VuiFormItem>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CounselingFormTable;

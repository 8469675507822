import "./style.less";
import { FC } from "react";
import { Form, FormItemProps } from "antd";

interface IVuiFormItem {}

const VuiFormItem: FC<IVuiFormItem & FormItemProps> = (props) => {
  return <Form.Item validateFirst {...props} />;
};

export default VuiFormItem;

import "./_style.less";
import { AccountIcon } from "../../../../components";
import { Avatar } from "antd";
import { FC } from "react";
import { AvatarProps } from "antd/lib/avatar/avatar";

interface IVuiAvatar {}

const VuiAvatar: FC<IVuiAvatar & AvatarProps> = (props) => {
  const { size = 80, icon = <AccountIcon />, ...other } = props;
  return <Avatar className="vui-avatar" size={size} icon={icon} {...other} />;
};

export default VuiAvatar;

import { Form, Input, Modal, Radio, Space, Typography } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  VuiAvatar,
  VuiButton,
  VuiDatePicker,
  VuiFormItem,
  VuiFormLayout,
  VuiSecondaryTitleWrapper,
  VuiSelectSingle,
  VuiUploadImage,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import { UpdateUser } from "../../../../models/User";
import { Patient } from "../../../../models/Patient";
import { Photo } from "../../../../models/Photo";
import CustomerRepository from "../../../../repositories/CustomerRepository";
import {
  checkIsValidPhone,
  handleErrorSaveDataResponse,
  handleSuccessSaveDataResponse,
  usePassportService,
} from "../../../../@vendor/utils";
import moment from "moment";
import { AxiosError } from "axios";
import ConstantRepository from "../../../../repositories/ConstantRepository";
import { PatientFormData } from "./interface";
import PhoneInput from "../../../../@vendor/components/atoms/PhoneInput";
import { DEFAULT_PHONE_COUNTRY_CODE } from "../../../../constants";
import AccountRepository from "../../../../repositories/AccountRepository";

const { Text } = Typography;

interface IModal {
  visible: boolean;
  className?: string;
  onClose: () => void;
  onSubmit: (type: "create" | "update") => void;
  data: Patient | null;
  title?: string;
  showNotification?: boolean;
}

const PatientModal: FC<IModal> = ({
  visible,
  className,
  onSubmit,
  onClose,
  data,
  title,
  showNotification = true,
}) => {
  const { t } = useTranslation();
  const { refetchUser } = usePassportService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<Photo | null>(null);
  const [form] = Form.useForm<PatientFormData>();

  const isMainAccount = data?.is_parent_account;

  const handleDefaultValue = useCallback(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        date_of_birth: data?.date_of_birth
          ? moment(data.date_of_birth)
          : undefined,
        phone: {
          code: data?.phone_country
            ? Number(data?.phone_country)
            : DEFAULT_PHONE_COUNTRY_CODE,
          phone: data?.phone_number,
        },
      });

      // if (data.date_of_birth) {
      //   form.setFieldsValue({
      //     ...data,
      //     date_of_birth: moment(data.date_of_birth),
      //   });
      // }

      setAvatar(data.photo);
    } else {
      form.resetFields();
      setAvatar(null);
    }
  }, [data, form]);

  const onFinish = useCallback(
    async (values: PatientFormData) => {
      setIsLoading(true);

      const payload: UpdateUser = {
        address: values.address,
        date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
        email: values.email,
        gender: values.gender,
        phone_number: values.phone?.phone,
        phone_country: String(values.phone?.code),
        photo_id: avatar?.id || null,
        name: values.name,
        relation: values?.relation?.label,
      };

      if (data?.id) {
        await CustomerRepository.update(String(data.id), payload)
          .then(() => {
            if (showNotification) {
              handleSuccessSaveDataResponse(t, t("common.text.patient"), true);
            }
            setIsLoading(false);
            onClose();
            if (onSubmit) {
              onSubmit("update");
            }
          })
          .catch((error: AxiosError) => {
            setIsLoading(false);
            handleErrorSaveDataResponse(t, error);
          });
      } else {
        await CustomerRepository.create(payload)
          .then(() => {
            if (showNotification) {
              handleSuccessSaveDataResponse(t, t("common.text.patient"));
            }
            setIsLoading(false);
            onClose();
            form.resetFields();
            if (onSubmit) {
              onSubmit("create");
            }
          })
          .catch((error: AxiosError) => {
            setIsLoading(false);
            handleErrorSaveDataResponse(t, error);
          });
      }
    },
    [avatar?.id, data?.id, showNotification, onClose, onSubmit, t, form]
  );

  const updateMainAccount = useCallback(
    async (values: PatientFormData) => {
      setIsLoading(true);

      const payload: UpdateUser = {
        address: values.address,
        date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
        gender: values.gender,
        photo_id: avatar?.id || null,
        name: values.name,
      };

      await AccountRepository.updateProfile(payload)
        .then(() => {
          if (showNotification) {
            handleSuccessSaveDataResponse(t, t("common.text.patient"), true);
          }
          setIsLoading(false);
          onClose();
          refetchUser();
          if (onSubmit) {
            onSubmit("update");
          }
        })
        .catch((error: AxiosError) => {
          setIsLoading(false);
          handleErrorSaveDataResponse(t, error);
        });
    },
    [avatar?.id, onClose, onSubmit, refetchUser, showNotification, t]
  );

  const onChangeAvatar = useCallback(
    async (data: Photo) => {
      setAvatar(data);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        photo: data,
        photo_id: data.id,
      });
    },
    [form]
  );

  const handleRemoveAvatar = useCallback(() => {
    setAvatar(null);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      photo: null,
      photo_id: null,
    });
  }, [form]);

  const renderAvatar = useMemo(() => {
    return <VuiAvatar size={60} src={avatar?.url} />;
  }, [avatar]);

  useEffect(() => {
    handleDefaultValue();

    setTimeout(() => {
      handleDefaultValue();
    }, 10);
  }, [data, handleDefaultValue]);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper
          title={
            title
              ? title
              : data
              ? t("common.text.editPatient")
              : t("common.text.addPatient")
          }
          level={4}
        >
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <VuiFormLayout
        form={form}
        onFinish={(val) =>
          isMainAccount ? updateMainAccount(val) : onFinish(val)
        }
      >
        <div className="default-modal-body">
          <div className="default-modal-avatar">
            {renderAvatar}
            <Space direction="vertical" size={1}>
              <div className="default-modal-avatar-button-wrapper">
                <VuiUploadImage onChangeFile={onChangeAvatar} />

                <VuiButton
                  buttonProps={{
                    disabled: !avatar,
                    onClick: handleRemoveAvatar,
                  }}
                  label={t("common.button.removePhoto")}
                />
              </div>
              <Text type="secondary" className="fs-12">
                * {t("common.text.maxImageSize")}
              </Text>
            </Space>
          </div>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.fullName.label"),
                }),
              },
            ]}
            name={"name"}
            label={t("common.form.fullName.label")}
          >
            <Input placeholder={t("common.form.fullName.placeholder")} />
          </VuiFormItem>

          <VuiFormItem
            rules={[
              {
                type: "email",
                message: t("validation.type.email", {
                  item: t("common.form.email.label"),
                }),
              },
            ]}
            name={"email"}
            label={t("common.form.email.label")}
          >
            <Input
              disabled={!!isMainAccount}
              placeholder={t("common.form.email.placeholder")}
            />
          </VuiFormItem>

          <VuiFormItem
            initialValue={{
              code: DEFAULT_PHONE_COUNTRY_CODE,
            }}
            rules={[
              {
                validator: (_, value) => checkIsValidPhone(t, value),
              },
            ]}
            name={"phone"}
            label={t("common.form.phone.label")}
          >
            <PhoneInput
              disabled={!!isMainAccount}
              placeholder={t("common.form.phone.placeholder")}
            />
          </VuiFormItem>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.dateOfBirth.label"),
                }),
              },
            ]}
            name={"date_of_birth"}
            label={t("common.form.dateOfBirth.label")}
          >
            <VuiDatePicker />
          </VuiFormItem>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.gender.label"),
                }),
              },
            ]}
            name={"gender"}
            label={t("common.form.gender.label")}
          >
            <Radio.Group>
              <Radio value={"Laki - laki"}>{t("common.text.male")}</Radio>
              <Radio value={"Perempuan"}>{t("common.text.female")}</Radio>
            </Radio.Group>
          </VuiFormItem>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.address.label"),
                }),
              },
            ]}
            name={"address"}
            label={t("common.form.address.label")}
          >
            <Input placeholder={t("common.form.address.placeholder")} />
          </VuiFormItem>

          {!data?.is_parent_account && (
            <VuiFormItem
              name={"relation"}
              label={t("common.form.relationshipWithYou.label")}
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.relationshipWithYou.label"),
                  }),
                },
              ]}
            >
              <VuiSelectSingle
                repository={ConstantRepository}
                labelKey={"label"}
                valueKey={"label"}
                selectParams={{
                  for: "patient_relation",
                }}
                placeholder={t("common.form.relationship.placeholder")}
              />
            </VuiFormItem>
          )}
        </div>

        <div className="default-modal-footer">
          <VuiButton
            buttonProps={{
              type: "primary",
              htmlType: "submit",
            }}
            loading={isLoading}
            label={data ? t("common.button.save") : t("common.button.register")}
          />
        </div>
      </VuiFormLayout>
    </Modal>
  );
};

export default PatientModal;

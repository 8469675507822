import "./_style.less";
import { useTranslation } from "react-i18next";
import { VuiContentLoading, VuiHelmet } from "../../../@vendor/components";
import {
  IAuthRegisterForm,
  passportService,
  showNotification,
  usePassportService,
} from "../../../@vendor/utils";
import { useCallback, useEffect } from "react";
import { OAuth2TokenContract } from "../../../@vendor/utils/services/passport.service";
import { useLocalStorage } from "usehooks-ts";
import { localStorageKey } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { ProviderType } from "../../../repositories/AuthRepository";

const AuthSocialCallbackPage = () => {
  const { t } = useTranslation();
  const { fetchUser } = usePassportService();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const accessToken = url.searchParams.get("access_token");
  const expiresIn = url.searchParams.get("expires_in");
  const tokenType = url.searchParams.get("token_type");
  const message = url.searchParams.get("message");
  const isRegister = url.searchParams.get("is_register");
  const name = url.searchParams.get("name");
  const email = url.searchParams.get("email");
  const provider = url.searchParams.get("provider");
  const providerId = url.searchParams.get("provider_id");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [registerFormData, setRegisterFormData] = useLocalStorage<
    IAuthRegisterForm | undefined
  >(localStorageKey.authRegisterForm, undefined);

  const handleLogin = useCallback(() => {
    if (accessToken && expiresIn && tokenType) {
      const contract: OAuth2TokenContract = {
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: "",
        token_type: tokenType,
      };

      passportService.setToken(contract);
      fetchUser();
    } else {
      showNotification("error", message || "");
    }
  }, [accessToken, expiresIn, tokenType, fetchUser, message]);

  const handleRegister = useCallback(() => {
    if (isRegister === "1" && name && email && providerId) {
      setRegisterFormData({
        name: name,
        email: email,
        provider_id: providerId,
        provider: provider as ProviderType,
      });
      navigate(`/auth/register-phone`, { replace: true });
    } else {
      showNotification("error", message || "");
    }
  }, [
    email,
    isRegister,
    message,
    name,
    navigate,
    provider,
    providerId,
    setRegisterFormData,
  ]);

  useEffect(() => {
    if (isRegister === "1") {
      handleRegister();
    } else {
      handleLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="auth-social-callback-page">
      <VuiHelmet title={t("common.text.socialCallback")} />
      <div className="auth-social-callback-wrapper">
        <div>{t("common.text.socialCallback")}</div>
        <div>{t("common.text.pleaseWait")}</div>
        <VuiContentLoading loading />
      </div>
    </div>
  );
};

export default AuthSocialCallbackPage;

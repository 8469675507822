import "./_style.less";
import { FileSearchingIllustration } from "../../illustrations";
import Paragraph from "antd/lib/typography/Paragraph";

interface EmptyContentProps {
  title?: string;
}

const EmptyContent = ({ title = "No Data" }: EmptyContentProps) => {
  return (
    <div className="empty-content-wrapper">
      <FileSearchingIllustration />
      <Paragraph className="empty-content-title">{title}</Paragraph>
    </div>
  );
};

export default EmptyContent;

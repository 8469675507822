import { showNotification } from "./notification.helper";
import { TFunction } from "react-i18next";
import { AxiosError } from "axios";
import _ from "lodash";

export const handleErrorLoadDataResponse = (
  t: TFunction,
  item: string,
  err?: AxiosError
) => {
  console.log(err, "error handle");
  if (err?.code === "ERR_CANCELED") {
    return;
  }

  showNotification(
    "error",
    t("notification.error.invalidLoadItem", {
      item: item,
    })
  );
};

export const handleErrorDownloadDataResponse = (t: TFunction, item: string) => {
  showNotification(
    "error",
    t("notification.error.downloadItem", {
      item: item,
    })
  );
};

export const handleErrorSaveDataResponse = (
  t: TFunction,
  error: AxiosError
) => {
  const tempErrors: any[] = _.get(error, "response.data.errors");
  if (tempErrors && !_.isEmpty(tempErrors)) {
    const flattenError: any[] = Object.values(tempErrors).flat();
    if (flattenError.length > 0) {
      showNotification("error", flattenError[0]);
    }
  } else {
    const getErrorMessage = _.get(error, "response.data.message", null);
    if (getErrorMessage) {
      showNotification("error", _.get(error, "response.data.message"));
    } else {
      showNotification("error", t("notification.error.default"));
    }
  }
};

export const getErrorPhoneLogin = (t: TFunction, error: AxiosError) => {
  let msg: string = t("notification.error.default");
  if (_.get(error, "response.data.error") === "invalid_grant") {
    msg = t("notification.error.invalidPhoneOrPassword");
  } else {
    msg =
      _.get(error, "response.data.hint.message") ??
      _.get(error, "response.data.message");
  }

  return msg;
};

export const handleSuccessSaveDataResponse = (
  t: TFunction,
  item: string,
  isUpdate: boolean = false
) => {
  const checkIsUpdate = isUpdate
    ? "notification.success.updateItem"
    : "notification.success.createItem";

  showNotification(
    "success",
    t(checkIsUpdate, {
      item: item,
    })
  );
};

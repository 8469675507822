import "./_style.less";

import { FC } from "react";
import OtpInput, { OTPInputProps } from "react-otp-input";
import { Typography, Spin } from "antd";
import clsx from "clsx";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

interface VuiOtpInputProps {
  error?: string;
  timeLeft: number;
  isOnRequestOtp?: boolean;
  onResendCode: () => void;
}

const VuiOtpInput: FC<VuiOtpInputProps & OTPInputProps> = (props) => {
  const { t } = useTranslation();

  const { error, timeLeft, isOnRequestOtp, onResendCode, ...otherProps } =
    props;

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `0${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <div className="vui-otp-input-wrapper">
        <OtpInput
          numInputs={4}
          containerStyle="vui-otp-input-container"
          inputStyle={clsx("vui-otp-input", {
            error: !!error,
          })}
          {...otherProps}
        />
        {error && (
          <div style={{ marginTop: 10 }}>
            <Typography.Text type="danger">{error}</Typography.Text>
          </div>
        )}
      </div>
      <Text>
        Didn't receive the code?{" "}
        {timeLeft > 0 ? (
          <b>Wait {formatTime(timeLeft)}</b>
        ) : (
          <span>
            {isOnRequestOtp ? (
              <Spin
                spinning={true}
                indicator={
                  <LoadingOutlined
                    spin
                    style={{ marginLeft: 8, fontSize: 18 }}
                  />
                }
              ></Spin>
            ) : (
              <Text
                className="link-text font-bold underline"
                onClick={onResendCode}
              >
                {t("common.button.resendCode")}
              </Text>
            )}
          </span>
        )}
      </Text>
    </>
  );
};

export default VuiOtpInput;

import { TFunction } from "react-i18next";
import { IDefaultTab } from "../interfaces";
import { AccountBillingKeyType } from "../types";

export const generateBillingTabs = (
  t: TFunction
): IDefaultTab<AccountBillingKeyType>[] => [
  {
    tabName: t("common.text.all"),
    key: "all",
  },
  {
    tabName: t("common.text.pending"),
    key: "pending",
  },
  {
    tabName: t("common.text.paid"),
    key: "paid",
  },
  {
    tabName: t("common.text.canceled"),
    key: "canceled",
  },
];

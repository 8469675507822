import "./_style.less";
import { FC } from "react";
import { Merchandise } from "../../../../../models/Merchandise";
import Paragraph from "antd/lib/typography/Paragraph";
import { useTranslation } from "react-i18next";

interface ICard {
  data: Merchandise;
  buyLink: string;
}

const VuiMerchandiseCard: FC<ICard> = ({ data, buyLink }) => {
  const { t } = useTranslation();
  return (
    <div id="vui-merchandise-card">
      <img
        className="merchandise-card-image"
        src={data.image}
        alt={data.name}
      />
      <Paragraph className="merchandise-card-title">{data.name}</Paragraph>
      <div className="ant-typography merchandise-card-description" dangerouslySetInnerHTML={{ __html: data.description }}></div>
      <a href={buyLink} target={'_blank'} className="merchandise-card-link">
        {t("common.text.buy")}
      </a>
    </div>
  );
};

export default VuiMerchandiseCard;

import "./_style.less";
import Paragraph from "antd/lib/typography/Paragraph";
import { FileSearchingIllustration } from "../../../illustrations";
import { FC } from "react";
import { VuiFormCard } from "../../../../@vendor/components";

interface ICard {
  title: string;
}

const NoContentCard: FC<ICard> = ({ title }) => {
  return (
    <VuiFormCard bodyStyle={{ padding: "5px 0" }} style={{ marginBottom: 24 }}>
      <div className="no-content-wrapper">
        <FileSearchingIllustration />
        <Paragraph className="no-content-text">{title}</Paragraph>
      </div>
    </VuiFormCard>
  );
};

export default NoContentCard;

import { LayoutApp } from "../layouts";
import {
  Home,
  About,
  Service,
  ServiceDetail,
  Psychologists,
  PsychologistsDetail,
  Account,
  Events,
  Cart,
  EventsDetail,
  Insight,
  InsightArticles,
  InsightResearch,
  InsightDetail,
  Contact,
  AccountReschedule,
  CounselingForm,
  CounselingFormSuccessPage,
  PrivacyPolicy,
  TermsCondition,
  FrequentlyAskedQuestion,
  AccountRescheduleSuccess,
  BookCounseling,
  Book,
  BookAssessment,
} from "../pages";

const appRoutes = [
  {
    path: "/",
    element: <LayoutApp />,
    children: [
      // Home Route
      { path: "/", element: <Home /> },

      // About Route
      { path: "/about", element: <About /> },

      // Events Route
      {
        path: "/events",
        children: [
          { path: "", element: <Events /> },
          { path: ":id", element: <EventsDetail /> },
        ],
      },

      // Events Route
      {
        path: "/insight",
        children: [
          { path: "", element: <Insight /> },
          { path: "articles", element: <InsightArticles /> },
          { path: "research", element: <InsightResearch /> },
          { path: ":id", element: <InsightDetail /> },
        ],
      },

      // Service Route
      { path: "/services", element: <Service /> },
      { path: "/services/:id", element: <ServiceDetail /> },

      // Psychologists Route
      { path: "/psychologists", element: <Psychologists /> },
      { path: "/psychologists/:id", element: <PsychologistsDetail /> },

      // Privacy Policy Route
      { path: "/privacy-policy", element: <PrivacyPolicy /> },

      // Frequently Asked Question Route
      {
        path: "/frequently-asked-question",
        element: <FrequentlyAskedQuestion />,
      },

      // Terms Condition Route
      { path: "/terms-and-condition", element: <TermsCondition /> },

      // Account Route
      { path: "/account", element: <Account /> },
      { path: "/account/reschedule/:id", element: <AccountReschedule /> },
      {
        path: "/account/reschedule-success",
        element: <AccountRescheduleSuccess />,
      },

      // Cart Route
      { path: "/cart", element: <Cart /> },

      // Contact
      { path: "/contact", element: <Contact /> },

      // Book
      { path: "/book", element: <Book /> },

      // Book Counseling
      { path: "/book-counseling", element: <BookCounseling /> },

      // Book Asssessment
      { path: "/book-assessment", element: <BookAssessment /> },
    ],
  },
  { path: "/counseling-form/:id", element: <CounselingForm /> },
  { path: "/counseling-form-success", element: <CounselingFormSuccessPage /> },
];

export default appRoutes;

import "./_style.less";
import { ExclamationIcon } from "../../../../components";
import { CSSProperties, FC, ReactNode } from "react";
import clsx from "clsx";
import Paragraph from "antd/lib/typography/Paragraph";

type AlertType = "danger" | "success";

interface IVuiAlert {
  type: AlertType;
  text: ReactNode;
  icon?: ReactNode;
  style?: CSSProperties;
}

const VuiAlert: FC<IVuiAlert> = ({
  type,
  text = "",
  icon = <ExclamationIcon />,
  style,
}) => {
  return (
    <div
      className={clsx({
        "vui-alert": true,
        danger: type === "danger",
        success: type === "success",
      })}
      style={style}
    >
      {icon}
      <Paragraph>{text}</Paragraph>
    </div>
  );
};

export default VuiAlert;

import "./_style.less";
import { forwardRef, useMemo } from "react";
import { Billing } from "../../../../models/Billing";
import Paragraph from "antd/lib/typography/Paragraph";
import { useTranslation } from "react-i18next";
import { VuiNumberFormat } from "../../../../@vendor/components";
import { TickPrintIcon } from "../../../icons";
import clsx from "clsx";
import moment from "moment/moment";
import {
  getConsultationTypeLabel,
  getTimeZone,
} from "../../../../@vendor/utils";
import _ from "lodash";

type PrintBillingInvoiceProps = {
  data: Billing | null;
};

const PrintBillingInvoice = (
  props: PrintBillingInvoiceProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { t } = useTranslation();
  const { data } = props;

  const hasCounseling = useMemo(() => {
    return !!data?.bookings.find((item) => item.session_type === "COUNSELING");
  }, [data?.bookings]);
  const hasAssessment = useMemo(() => {
    return !!data?.bookings.find((item) => item.session_type === "ASSESSMENT");
  }, [data?.bookings]);

  return (
    <div
      className={clsx({
        "print-billing-invoice": true,
        free: data?.bookings && data.bookings.length > 2,
      })}
      ref={ref}
    >
      <div style={{ paddingBottom: "60px" }}>
        <section className="billing-invoice-header">
          <img
            src={require("../../../../assets/images/pg-plain-logo.png")}
            alt="personal-growth-logo"
            style={{ width: "auto", height: "40px" }}
          />
          <div className="billing-invoice-header-title">
            Ruko Aries Niaga
            <br />
            Jalan Taman Aries Blok A1 No. 1B
            <br />
            Meruya Utara - Kembangan, Jakarta Barat 11620
          </div>
        </section>

        <section className="billing-invoice-subheader">
          <div className="billing-invoice-subheader-left">
            <Paragraph className="billing-invoice-subheader-title">
              {t("common.text.invoice")}
            </Paragraph>
            <p>Date: {moment(data?.date).format("DD MMMM YYYY")}</p>
            <p>Booking Number: {data?.number}</p>
            <Paragraph className="billing-invoice-subheader-title mt-1">
              {t("common.text.invoiceTo")}:
            </Paragraph>
            <p>{data?.customer_name}</p>
          </div>
          <div className="billing-invoice-subheader-right">
            <div className="billing-invoice-status">
              <TickPrintIcon />
              <span>{data?.status_name}</span>
            </div>
          </div>
        </section>

        <section className="billing-invoice-body">
          <div className="billing-invoice-order-detail">
            <div className="billing-invoice-body-title">
              {t("common.text.detailYourPayment", {
                type:
                  hasCounseling && hasAssessment
                    ? `assessment & counseling`
                    : hasAssessment
                    ? "assessment"
                    : "counseling",
              })}
            </div>
            <table className="table-billing">
              <thead>
                <tr>
                  <th className="text-align-center">No</th>
                  <th className="text-align-left">Description</th>

                  <th className="text-align-center">Duration</th>

                  <th className="text-align-center">Price per Hour</th>

                  <th className="text-align-right" style={{ paddingRight: 20 }}>
                    Total Price
                  </th>
                </tr>
              </thead>

              <tbody>
                {data?.bookings.map((item, rowIndex) => {
                  const isNoSchedule = !item.date;
                  return (
                    <tr key={rowIndex}>
                      <td className="text-align-center">{rowIndex + 1}</td>
                      <td>
                        <div className="table-billing-description-wrapper">
                          <div className="table-billing-description-left">
                            <p>Patient's Name</p>
                          </div>
                          <div className="table-billing-description-right">
                            <p>: {item.patient_name}</p>
                          </div>
                        </div>
                        {!!item.psychologists_name && (
                          <div className="table-billing-description-wrapper">
                            <div className="table-billing-description-left">
                              <p>Psychologist</p>
                            </div>
                            <div className="table-billing-description-right">
                              <p>: {item.psychologists_name}</p>
                            </div>
                          </div>
                        )}
                        {item.session_type === "ASSESSMENT" && (
                          <div className="table-billing-description-wrapper">
                            <div className="table-billing-description-left">
                              <p>Package</p>
                            </div>
                            <div className="table-billing-description-right">
                              <p>: {item.assessment_name}</p>
                            </div>
                          </div>
                        )}
                        {!!item.date && !item.is_package && (
                          <div className="table-billing-description-wrapper">
                            <div className="table-billing-description-left">
                              <p>Day/Date</p>
                            </div>
                            <div className="table-billing-description-right">
                              <p>
                                :{" "}
                                {moment(item.date).format("dddd, DD MMMM YYYY")}
                              </p>
                            </div>
                          </div>
                        )}
                        {!!item.consultation_type && (
                          <div className="table-billing-description-wrapper">
                            <div className="table-billing-description-left">
                              <p>Method</p>
                            </div>
                            <div className="table-billing-description-right">
                              <p>
                                :{" "}
                                {getConsultationTypeLabel(
                                  item.consultation_type,
                                  t
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                        {!!item.is_package && (
                          <div className="schedule-section">
                            <div className="schedule-title">Schedule</div>
                            {item.schedules?.map((item, i) => {
                              return (
                                <div key={i} className="session-wrapper">
                                  <div className="session-title">
                                    Session {i + 1}
                                  </div>
                                  <div>
                                    {`${moment(item.date).format(
                                      "dddd, DD MMMM YYYY"
                                    )}, ${moment(item.time_from).format(
                                      "HH:mm"
                                    )} (${getTimeZone()})`}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </td>

                      <td className="text-align-center">
                        {!!item.booking_duration ? (
                          <>
                            {item.session_type === "COUNSELING" &&
                              !item.is_package && (
                                <p className="text-no-wrap">
                                  {moment(item.time_from).format("HH:mm")} -{" "}
                                  {moment(item.time_from)
                                    .add(item.booking_duration, "minute")
                                    .format("HH:mm")}
                                </p>
                              )}
                            <p>{item.booking_duration} minutes</p>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td className="text-align-center">
                        {!!item.price_per_hour &&
                        !isNoSchedule &&
                        item.session_type !== "ASSESSMENT" ? (
                          <VuiNumberFormat
                            className="text-no-wrap"
                            value={item.price_per_hour}
                          />
                        ) : (
                          "Rp -"
                        )}
                      </td>

                      <td
                        className="text-align-right"
                        style={{ paddingRight: 20 }}
                      >
                        <VuiNumberFormat
                          className="text-no-wrap"
                          value={
                            item.is_package &&
                            item.session_type === "COUNSELING"
                              ? item.price_per_hour *
                                _.get(item, "session_amount", 0)
                              : item.total_price
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={5}>
                    <div className="table-billing-footer">
                      <div className="table-billing-left">
                        <h4 className="table-billing-title">Total Payment</h4>
                      </div>
                      <div className="table-billing-right">
                        <div className="table-billing-inner-left">
                          <p>Subtotal</p>
                          <p>Payment gateway fee</p>
                          <p>Tax</p>
                          <p className="text-bold">Total</p>
                        </div>
                        <div className="table-billing-inner-right">
                          <p>
                            <VuiNumberFormat value={data?.subtotal} />
                          </p>
                          <p>
                            <VuiNumberFormat value={data?.admin_fee} />
                          </p>
                          <p>
                            <VuiNumberFormat value={data?.tax_amount} />
                          </p>
                          <p className="text-bold">
                            <VuiNumberFormat value={data?.grand_total} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section className="billing-invoice-subbody">
          <p className="paragraph">
            *This invoice is automatically generated by the system
            <br />
            <br />
            For further inquiries:
            <br />
            <span className="billing-email">finance@personalgrowth.co.id</span>
            <br />
            <br />
            Thank you for using Personal Growth’s services.
          </p>
        </section>
      </div>

      <img
        className="billing-invoice-footer-wave"
        src={require("../../../../assets/images/footer-wave.png")}
        alt="footer-wave"
      />
    </div>
  );
};

export default forwardRef(PrintBillingInvoice);

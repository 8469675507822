import "./_style.less";
import React from "react";
import CalendarFullIcon from "../../../icons/CalendarFull";
import { useNavigate } from "react-router-dom";
import PencilIcon from "../../../icons/Pencil";

interface IInsightCard {
  id: number;
  link: string;
  image: string;
  label: string;
  name: string;
  summary: string;
  date: string;
  author?: string;
  isResearch?: boolean;
}

const InsightCard: React.FC<IInsightCard> = ({
  id,
  link,
  image,
  label,
  name,
  summary,
  date,
  author,
  isResearch = false,
}) => {
  let navigate = useNavigate();

  const handleLink = () => {
    if (isResearch) {
      return window.open(link);
    }
    return navigate(link);
  };

  return (
    <>
      <div className="insight-card" onClick={handleLink}>
        <div className="image-wrapper">
          <img className="image" src={image} alt="event" />
        </div>
        <div className="info-wrapper">
          <label className="label">{label}</label>
          <h3 className="name">{name}</h3>
          <p className="summary">{summary}</p>
          <ul className="vertical-list">
            <li className="item">
              <CalendarFullIcon className="icon" />
              {date}
            </li>
            {author && (
              <li className="item">
                <PencilIcon className="icon" />
                <div className="item-text">{author}</div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default InsightCard;

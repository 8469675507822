import { api, IResource } from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { PrivacyPolicy } from "../models/PrivacyPolicy";
import { TermsCondition } from "../models/TermsCondition";
import {Faq} from "../models/Faq";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IPageRepository {
  page(type: string): AxiosPromise;
  frequentlyAskQuestion(): AxiosPromise<IResource<Faq>>;
  privacyPolicy(): AxiosPromise<IResource<PrivacyPolicy>>;
  termsCondition(): AxiosPromise<IResource<TermsCondition>>;
}

const PageRepository: IPageRepository = {
  page(type: string) {
    return api.get(`${endPoint()}/api/page/${type}`, {
      cancelToken:
        cancelTokenHandlerObject[this.page.name].handleRequestCancellation()
          .token,
    });
  },
  privacyPolicy() {
    return api.get(`${endPoint()}/api/page/privacy-policy`, {
      cancelToken:
        cancelTokenHandlerObject[
          this.privacyPolicy.name
        ].handleRequestCancellation().token,
    });
  },
  frequentlyAskQuestion() {
    return api.get(`${endPoint()}/api/page/frequently-asked-question`, {
      cancelToken:
        cancelTokenHandlerObject[
          this.frequentlyAskQuestion.name
        ].handleRequestCancellation().token,
    });
  },
  termsCondition() {
    return api.get(`${endPoint()}/api/page/terms-condition`, {
      cancelToken:
        cancelTokenHandlerObject[
          this.termsCondition.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(PageRepository);

export default PageRepository;

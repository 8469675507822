import "./_style.less";
import {
  VuiButton,
  VuiContentLoading,
  VuiDatePicker,
  VuiFormCard,
  VuiFormItem,
  VuiFormLayout,
  VuiNumberFormat,
  VuiSecondaryTitleWrapper,
} from "../../../../../@vendor/components";
import {
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Space,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { OrderSummary } from "../../../../../pages/app/Cart";
import { Cart } from "../../../../../models/Cart";
import { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { PaymentMethod } from "../../../../../models/PaymentMethod";
import PaymentMethodRepository from "../../../../../repositories/PaymentMethodRepository";
import {
  groupPaymentMethodHelper,
  handleErrorSaveDataResponse,
  showNotification,
  useIsMounted,
} from "../../../../../@vendor/utils";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { ListTextModal } from "../../../../atoms";
import moment from "moment";
import {
  IXenditCCResponse,
  IXenditError,
  IXenditTokenData,
} from "../../../../../@vendor/utils/interfaces/xendit.interface";
import BookingRepository from "../../../../../repositories/BookingRepository";
import { CreateBooking } from "../../../../../models/Booking";
import { AxiosError } from "axios";
import {
  ConfirmationModal,
  CreditCardAuthenticationModal,
} from "../../../modals";

const Xendit = require("xendit-js-node");

const xenditApiKey = window._env_.REACT_APP_XENDIT_PUBLIC_KEY || "";

const { Text } = Typography;

interface ICartPaymentStep {
  orderSummary: OrderSummary;
  checkedCart: Cart[];
  onCheckout: (data: any) => void;
}

export interface IPaymentGroup {
  type_name: string;
  type_id: number;
  lists: PaymentMethod[];
}

export interface ICreditCard {
  card_number: string;
  payment_method_id: string;
  expired_date: string;
  cvv: string;
}

const CREDIT_CARD_TYPE_ID = 8;

const CartPaymentStep: FC<ICartPaymentStep> = ({
  orderSummary,
  checkedCart,
  onCheckout,
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [form] = Form.useForm();
  const [xenditResponse, setXenditResponse] =
    useState<IXenditCCResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PaymentMethod[]>([]);
  const [groupedData, setGroupedData] = useState<IPaymentGroup[]>([]);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<PaymentMethod | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<ICreditCard | null>(null);
  const [payerAuthUrl, setPayerAuthUrl] = useState<string | null>(null);

  const cardNumber = Form.useWatch("card_number", form);
  const cardExpired = Form.useWatch("expired_date", form);
  const cardCvv = Form.useWatch("cvv", form);

  const disabledCheckoutButton = useMemo(() => {
    if (selectedData?.type_id === CREDIT_CARD_TYPE_ID) {
      return !cardNumber || !cardExpired || !cardCvv;
    }

    return !selectedData?.id;
  }, [
    cardCvv,
    cardExpired,
    cardNumber,
    selectedData?.id,
    selectedData?.type_id,
  ]);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await PaymentMethodRepository.findAll({
      with: ["paymentMethodInstructions", "logo", "type"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setGroupedData(groupPaymentMethodHelper(responseData));
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
        }
      });
  }, [isMounted]);

  const handleChangeRadio = useCallback(
    (event: RadioChangeEvent) => {
      const findItem = data.find((item) => item.id === event.target.value);
      if (findItem) {
        setSelectedData(findItem);
      }
    },
    [data]
  );

  const createBooking = useCallback(
    async (isXendit: boolean = false) => {
      if (selectedData) {
        const payload: CreateBooking = {
          payment_method_id: selectedData.id,
          bookings: checkedCart.map((cart) => {
            const isNoSchedule = !cart.is_package && !cart?.schedule_id;

            const data: any = {
              cart_id: cart.id,
              duration: isNoSchedule ? undefined : cart.duration,
              category_id: cart?.category_id ?? undefined,
              assessment_id: cart?.assessment_id ?? undefined,
              customer_id: cart.customer_id,
              price: cart.subtotal,
              is_package: isNoSchedule ? undefined : !!cart.is_package,
            };

            if (!cart.is_package && !isNoSchedule) {
              data.schedule_id = cart.schedule_id;
            }

            return data;
          }),
        };

        if (isXendit) {
          Object.assign(payload, {
            xendit_token_id: xenditResponse?.id,
            xendit_authentication_id: xenditResponse?.authentication_id,
            xendit_card_cvn: String(form.getFieldValue("cvv")),
          });
        }

        await BookingRepository.create(payload)
          .then((response) => {
            const { data: responseData } = response.data;
            if (isMounted) {
              setLoading(false);
              onCheckout(responseData);
            }
          })
          .catch((error: AxiosError) => {
            if (isMounted) {
              handleErrorSaveDataResponse(t, error);
              setLoading(false);
            }
          });
      }
    },
    [
      selectedData,
      checkedCart,
      xenditResponse?.id,
      xenditResponse?.authentication_id,
      form,
      isMounted,
      onCheckout,
      t,
    ]
  );

  const onFinish = useCallback(
    async (values: ICreditCard) => {
      setFormData(values);
      handleOpenModal();
    },
    [handleOpenModal]
  );

  const xenditTokenResponseHandler = (
    error: IXenditError | null,
    data: IXenditCCResponse
  ) => {
    setLoading(false);
    if (error) {
      showNotification("error", error.message);
    }

    if (data) {
      if (data.status === "VERIFIED") {
        setXenditResponse(data);
        setLoading(true);
      } else if (data.status === "IN_REVIEW") {
        setPayerAuthUrl(data.payer_authentication_url);
      } else if (data.status === "FAILED") {
        showNotification("error", data.failure_reason);
      }
    }
  };

  const handleCheckout = useCallback(() => {
    handleCloseModal();

    setLoading(true);

    if (selectedData?.type_id === 7) {
      createBooking();
    } else {
      const tax = orderSummary.subTotal * 0.11;
      const adminFee = orderSummary.subTotal * 0.029 + 2000;
      const tokenData: IXenditTokenData = {
        amount: Math.round(orderSummary.subTotal + adminFee + tax),
        card_number: String(formData?.card_number),
        card_exp_month: moment(formData?.expired_date).format("MM"),
        card_exp_year: moment(formData?.expired_date).format("YYYY"),
        card_cvn: String(formData?.cvv),
        should_authenticate: true,
        currency: "IDR",
      };

      Xendit.setPublishableKey(xenditApiKey);

      Xendit.card.createToken(tokenData, xenditTokenResponseHandler);
    }
  }, [
    handleCloseModal,
    selectedData?.type_id,
    createBooking,
    orderSummary.subTotal,
    formData?.card_number,
    formData?.expired_date,
    formData?.cvv,
  ]);

  const renderTotal = useMemo(() => {
    const tax = orderSummary.subTotal * 0.11;
    const adminFee =
      selectedData?.type_id === CREDIT_CARD_TYPE_ID
        ? 2000 + orderSummary.subTotal * 0.029
        : 4500;
    const total = selectedData?.id
      ? orderSummary.subTotal + adminFee + tax
      : orderSummary.subTotal + tax;

    return (
      <>
        <ListTextModal
          label={`${t("common.text.subTotal")} ${
            checkedCart.length > 0
              ? t("common.text.schedulesItem", {
                  item: checkedCart.length,
                })
              : ""
          }`}
          onlyFlex
          value={<VuiNumberFormat value={orderSummary.subTotal} />}
        />

        {selectedData ? (
          <>
            <ListTextModal
              label={t("common.text.paymentGatewayFee")}
              onlyFlex
              value={<VuiNumberFormat value={adminFee} />}
            />
          </>
        ) : null}

        <ListTextModal
          label={t("common.text.tax")}
          onlyFlex
          value={<VuiNumberFormat value={tax} />}
        />

        <Divider />

        <ListTextModal
          label={t("common.text.total")}
          labelTextWeight="bold"
          valueTextWeight="bold"
          onlyFlex
          value={<VuiNumberFormat value={total} />}
        />
      </>
    );
  }, [checkedCart.length, orderSummary.subTotal, selectedData, t]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (xenditResponse) {
      createBooking(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xenditResponse]);

  return isFetchingData ? (
    <VuiContentLoading loading={isFetchingData} />
  ) : (
    <>
      <ConfirmationModal
        visible={showModal}
        onClose={handleCloseModal}
        onSubmit={handleCheckout}
        description={
          <p className="text-justify">
            Payment should be made within 30 minutes, or your booking will be
            automatically canceled.
            <br /> After your booking is confirmed,{" "}
            <strong>
              you cannot cancel your counseling appointment or get a refund
              payment
            </strong>
            . You can reschedule your appointment once, with the same
            psychologist and same duration at a{" "}
            <strong>maximum of 24 hours before your booked schedule</strong>.
          </p>
        }
        title={t("common.text.reminder")}
        submitBtnLabel={t("common.button.checkout")}
      />

      <div className="card-list" id="cart-payment-step">
        <Text className="card-payment-title">
          {t("common.text.choosePaymentMethod")}
        </Text>

        <VuiFormLayout
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => {
            showNotification(
              "warning",
              t("notification.error.chooseYourItem", {
                item: t("common.text.paymentMethod"),
              })
            );
          }}
        >
          <VuiFormItem
            name={"payment_method_id"}
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.paymentMethod.label"),
                }),
              },
            ]}
          >
            <Radio.Group style={{ width: "100%" }} onChange={handleChangeRadio}>
              {groupedData.map((grouped) => {
                return (
                  <VuiFormCard key={grouped.type_id}>
                    <Text className="cart-payment-step-text">
                      {grouped.type_name}
                    </Text>

                    <Divider style={{ marginBottom: 16, marginTop: 16 }} />

                    <Space direction="vertical" className="bank-payment-body">
                      {grouped.lists.map((list) => (
                        <Radio key={list.id} value={list.id}>
                          <div className="bank-payment-wrapper">
                            <div className="bank-payment-image">
                              <img src={list.logo?.url} alt={list.logo?.name} />
                            </div>
                            <Text className="bank-payment-name">
                              {list.name}
                            </Text>
                            <Text>{list.description}</Text>
                          </div>
                        </Radio>
                      ))}

                      {selectedData?.type_id === CREDIT_CARD_TYPE_ID &&
                      grouped.type_id === CREDIT_CARD_TYPE_ID ? (
                        <div className="bank-payment-credit-card">
                          <Text className="bank-payment-credit-title">
                            {t("common.text.fillUsingCreditCard")}
                          </Text>
                          <VuiFormItem
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.cardNumber.label"),
                                }),
                              },
                            ]}
                            name={"card_number"}
                            label={t("common.form.cardNumber.label")}
                          >
                            <InputNumber
                              placeholder={t(
                                "common.form.cardNumber.placeholder"
                              )}
                            />
                          </VuiFormItem>

                          <VuiFormItem
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.expirationDate.label"),
                                }),
                              },
                            ]}
                            name={"expired_date"}
                            label={t("common.form.expirationDate.label")}
                          >
                            <VuiDatePicker format="MMMM YYYY" picker="month" />
                          </VuiFormItem>

                          <VuiFormItem
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.cvv.label"),
                                }),
                              },
                            ]}
                            name={"cvv"}
                            label={t("common.form.cvv.label")}
                          >
                            <Input
                              onInput={(e: FormEvent<HTMLInputElement>) => {
                                return (e.currentTarget.value =
                                  e.currentTarget.value.replace(/\D/g, ""));
                              }}
                              placeholder={t("common.form.cvv.placeholder")}
                            />
                          </VuiFormItem>
                        </div>
                      ) : null}
                    </Space>
                  </VuiFormCard>
                );
              })}
            </Radio.Group>
          </VuiFormItem>
        </VuiFormLayout>
      </div>

      <div className="cart-summary">
        <VuiSecondaryTitleWrapper title={t("common.text.orderSummary")} />

        <div style={{ marginBottom: 28, marginTop: 24 }}>{renderTotal}</div>

        <VuiButton
          buttonProps={{
            type: "primary",
            onClick: () => form.submit(),
            disabled: disabledCheckoutButton,
          }}
          loading={loading}
          label={t("common.button.checkout")}
        />
      </div>
      {Boolean(payerAuthUrl) && (
        <CreditCardAuthenticationModal
          visible={Boolean(payerAuthUrl)}
          onClose={() => setPayerAuthUrl(null)}
          onAuthenticated={(token) => xenditTokenResponseHandler(null, token)}
          authUrl={payerAuthUrl || ""}
        />
      )}
    </>
  );
};

export default CartPaymentStep;

import "./style.less";
import { Navigate, Outlet } from "react-router";
import { useAppSelector } from "../../stores/hooks";
import { Spin } from "antd";
import { usePassportService } from "../../@vendor/utils";
import { PGPlainLogo } from "../../components";
import { useLocation } from "react-router-dom";

const LayoutAuth = () => {
  const { isOnFetchingUser } = usePassportService();
  const system = useAppSelector((state) => state.system);
  const { pathname } = useLocation();

  if (isOnFetchingUser) {
    return <Spin />;
  }

  if (system.isLoggedIn && pathname !== "/auth/verification") {
    return <Navigate to={"/"} replace />;
  }

  return (
    <section id="auth-layout">
      <div className="container">
        <div className="auth-container">
          <PGPlainLogo />
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default LayoutAuth;

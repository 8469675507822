import "./_style.less";
import { Badge, Calendar, Col, Radio, Row } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  CalendarProps,
  HeaderRender,
  CalendarMode,
} from "antd/lib/calendar/generateCalendar";
import { VuiSelectSingle } from "../index";
import { useTranslation } from "react-i18next";
import { Schedule } from "../../../../models/Schedule";
import { ScheduleLog } from "../../../../models/ScheduleLog";

interface IVuiCalendar {
  onSelectDate: (date: Moment) => void;
  data?: null | Schedule[];
  monthData?: null | ScheduleLog[];
}

const VuiCalendar: FC<IVuiCalendar & CalendarProps<any>> = ({
  onSelectDate = null,
  data,
  monthData,
  ...props
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<CalendarMode>("month");

  const getMonthData = useCallback(
    (value: Moment) => {
      return monthData?.find((item) => {
        return value.format("YYYY-MM") === moment(item.month).format("YYYY-MM");
      })?.counter;
    },
    [monthData]
  );

  const getListData = useCallback(
    (value: Moment) => {
      const listData: Schedule[] = [];
      data?.forEach((item) => {
        if (item.date === value.format("YYYY-MM-DD")) {
          listData.push(item);
        }
      });
      return listData;
    },
    [data]
  );

  const monthCellRender = useCallback(
    (value: Moment) => {
      const totalData = getMonthData(value);
      return totalData ? (
        <div className="notes-month">
          <section>{totalData}</section>
        </div>
      ) : null;
    },
    [getMonthData]
  );

  const dateCellRender = useCallback(
    (value: Moment) => {
      const listData: Schedule[] = getListData(value);

      return (
        <ul className="events">
          {listData.map((item) => (
            <li key={item.id}>
              <Badge
                color={
                  item.session_type === "ASSESSMENT"
                    ? "#67eae5"
                    : item.is_package
                    ? "#f9df64"
                    : "#ff00ae"
                }
                text={item.patient_name}
              />
            </li>
          ))}
        </ul>
      );
    },
    [getListData]
  );

  const handleOnSelect = (date: Moment) => {
    if (onSelectDate) {
      if (props.value) {
        const prevSelectedYear = props?.value?.year();
        const selectedYear = date.year();

        if (prevSelectedYear === selectedYear && mode === "year") {
          setMode("month");
        }
      }
      onSelectDate(date);
    }
  };

  const renderHeader: HeaderRender<Moment> = ({
    value,
    type,
    onChange,
    onTypeChange,
  }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let index = start; index < end; index++) {
      monthOptions.push({
        value: index,
        label: months[index],
      });
    }
    const month = value.month();

    const year = value.year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push({
        value: i,
        label: i,
      });
    }

    return (
      <div className="ant-picker-calendar-header">
        <Row gutter={8}>
          <Col>
            <VuiSelectSingle
              placeholder={t("common.text.year")}
              defaultOptions={options}
              value={options.find(
                (option) => String(option.value) === String(year)
              )}
              valueKey="value"
              labelKey="label"
              type="secondary"
              onChange={(newYear) => {
                const now = value.clone().year(Number(newYear.value));
                onChange(now);
              }}
            />
          </Col>
          {mode === "month" && (
            <Col>
              <VuiSelectSingle
                placeholder={t("common.text.month")}
                defaultOptions={monthOptions}
                value={monthOptions.find(
                  (option) => String(option.value) === String(month)
                )}
                type="secondary"
                valueKey="value"
                labelKey="label"
                onChange={(selectedMonth) => {
                  const newValue = value.clone();
                  newValue.month(parseInt(String(selectedMonth?.value), 10));
                  onChange(newValue);
                }}
              />
            </Col>
          )}
          <Col>
            <Radio.Group
              size="middle"
              onChange={(e) => {
                onTypeChange(e.target.value);
                setMode(e.target.value);
              }}
              value={type}
            >
              <Radio.Button value="year">Month</Radio.Button>
              <Radio.Button value="month">Days</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Calendar
      {...props}
      mode={mode}
      className="vui-calendar"
      headerRender={renderHeader}
      dateCellRender={dateCellRender}
      monthCellRender={monthCellRender}
      onSelect={handleOnSelect}
    />
  );
};

export default VuiCalendar;

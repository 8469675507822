import { Steps, StepProps } from "antd";
import { FC } from "react";
import { TickIllustration } from "../../../../../components";

interface IVuiStep {}

const { Step } = Steps;

const VuiStep: FC<IVuiStep & StepProps> = (props) => {
  return props.status === "finish" ? (
    <Step {...props} className="vui-step" icon={<TickIllustration />} />
  ) : (
    <Step {...props} className="vui-step" />
  );
};

export default VuiStep;

import { LayoutAuth } from "../layouts";
import {
  AuthForgotPasswordPage,
  AuthLoginPage,
  AuthResetPasswordPage,
  AuthResetPasswordSuccessPage,
  AuthForgotPasswordSuccessPage,
  AuthRegisterPage,
  AuthRegisterSuccessPage,
  AuthVerificationPage,
  AuthSocialCallbackPage,
  AuthOTPVerification,
} from "../pages";
import AuthRegisterPhonePage from "../pages/auth/RegisterPhone";
import AuthVerifyPhoneSuccessPage from "../pages/auth/VerifyPhoneSuccess";

const authRoutes = [
  {
    path: "auth",
    element: <LayoutAuth />,
    children: [
      { path: "login", element: <AuthLoginPage /> },
      { path: "register", element: <AuthRegisterPage /> },
      { path: "social-callback", element: <AuthSocialCallbackPage /> },
      { path: "verification", element: <AuthVerificationPage /> },
      { path: "register/success", element: <AuthRegisterSuccessPage /> },
      { path: "forgot-password", element: <AuthForgotPasswordPage /> },
      {
        path: "forgot-password/success",
        element: <AuthForgotPasswordSuccessPage />,
      },
      { path: "reset-password", element: <AuthResetPasswordPage /> },
      {
        path: "reset-password/success",
        element: <AuthResetPasswordSuccessPage />,
      },
      {
        path: "verification-code",
        element: <AuthOTPVerification />,
      },
      {
        path: "register-phone",
        element: <AuthRegisterPhonePage />,
      },
      { path: "verify-phone-success", element: <AuthVerifyPhoneSuccessPage /> },
    ],
  },
];

export default authRoutes;

const TickIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.8405 0.863948C18.2254 0.0950568 21.01 0.658069 21 1.4994C19.8511 5.82049 15.5983 13.5343 13.4604 17.5762C11.7708 20.7708 10.7562 21.7384 8.93669 21.4524C8.93669 21.4524 5.38522 20.4693 1.32534 12.6833C0.775309 11.6284 0.872976 10.7248 2.00319 10.1313C3.18367 9.51113 4.71025 9.87079 5.38522 10.9294L8.56044 15.905C8.87782 16.4032 9.48673 17.1949 9.96352 15.8453L17.8405 0.863948Z" />
  </svg>
);

export default TickIcon;

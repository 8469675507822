import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingData, IPackageSchedule, StepType } from "./interface";
import { Assessment, AssessmentSchedule } from "../../../models/Assessment";
import AssessmentRepository from "../../../repositories/AssessmentRepository";
import { useIsMounted, useWindowSize } from "usehooks-ts";
import {
  handleErrorLoadDataResponse,
  handleErrorSaveDataResponse,
  usePassportService,
} from "../../../@vendor/utils";
import { Psychologist } from "../../../models/Psychologist";
import AssessmentPsychologistRepository from "../../../repositories/AssessmentPsychologist";
import { Schedule, ScheduleTime } from "../../../models/Schedule";
import ScheduleRepository from "../../../repositories/ScheduleRepository";
import _ from "lodash";
import moment, { Moment } from "moment";
import { Patient } from "../../../models/Patient";
import AccountRepository from "../../../repositories/AccountRepository";
import { AxiosError } from "axios";
import CartRepository from "../../../repositories/CartRepository";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useAppSelector } from "../../../stores/hooks";
import ScheduleTimeRepository from "../../../repositories/ScheduleTImeRepository";

const stepsIndexWithSchedule: Partial<Record<StepType, number>> = {
  package: 0,
  phsychologists: 1,
  schedule: 2,
  patient: 3,
  preview: 4,
};

const stepsIndex: Partial<Record<StepType, number>> = {
  package: 0,
  patient: 1,
  preview: 2,
};

export const useBookAssessment = () => {
  const { t } = useTranslation();
  const previewBookingActionRef = useRef<HTMLDivElement>(null);
  const scheduleCalendarSectionRef = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { refetchUser } = usePassportService(true);
  const { account } = useAppSelector((state) => state.system);
  const isMobile = width < 768;
  const [currentStep, setCurrentStep] = useState<StepType>("package");
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [selectedAssessmentDetail, setSelectedAssessmentDetail] =
    useState<Assessment>();
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [psychologists, setPsychologists] = useState<Psychologist[]>([]);
  const [booking, setBooking] = useState<BookingData>();
  const [fetchingSessionScheduleTimeIdx, setFetchingSessionScheduleTimeIdx] =
    useState<number>();
  const [sessionScheduleTimes, setSessionScheduleTimes] = useState<
    Record<number, ScheduleTime[]>
  >({} as Record<number, ScheduleTime[]>);
  const [isFethcingSchedule, setIsFetchingSchedule] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [assessmentSchedules, setAssessmentSchedules] = useState<
    AssessmentSchedule[]
  >([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [showPatientForm, setShowPatientForm] = useState<Patient>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const [showSchedules, setShowSchedules] = useState<boolean>(false);
  const [cartLoading, setCartLoading] = useState<boolean>(false);
  const isVerifiedUser = !!account?.phone_number_verified_at;

  const {
    selectedAsessment,
    selectedPsychologist,
    selectedConsultationType,
    selectedSchedules,
    selectedPatient,
  } = booking || {};

  const isMultiSession = (selectedAsessment?.number_of_sessions ?? 0) > 1;

  const stepIdx = useMemo(() => {
    if (selectedAsessment?.has_schedule) {
      return stepsIndexWithSchedule;
    }

    return stepsIndex;
  }, [selectedAsessment?.has_schedule]);

  const currentStepIdx = useMemo(() => {
    return stepIdx[currentStep] ?? 0;
  }, [currentStep, stepIdx]);

  const handleChangeStep = (idx: number) => {
    const step = Object.keys(stepIdx)[idx] as StepType;

    if (step === "preview") {
      if (selectedPatient) {
        if (
          !selectedPatient?.name ||
          !selectedPatient?.address ||
          !selectedPatient?.date_of_birth ||
          !selectedPatient?.gender
        ) {
          message.warning(
            t("notification.error.pleaseCompleteYourPatientData")
          );
          return;
        }
      } else {
        message.warning(
          t("notification.error.chooseItem", {
            item: t("common.text.patient"),
          })
        );

        return;
      }
    }

    if (selectedAsessment?.has_schedule) {
      if (step === "phsychologists" && !selectedAsessment) {
        message.warning(
          t("notification.error.chooseItem", {
            item: t("common.text.package"),
          })
        );

        return;
      }
      if (step === "schedule" && !selectedPsychologist) {
        message.warning(
          t("notification.error.chooseYourItem", {
            item: t("common.text.psychologist"),
          })
        );

        return;
      }
      if (
        step === "patient" &&
        !!_.find(
          selectedSchedules,
          (item) => item.schedule === undefined || item.time === undefined
        )
      ) {
        message.warning(t("notification.error.pleaseCompleteSchedule"));

        return;
      }
    } else {
      if (step === "patient" && !selectedAsessment) {
        message.warning(
          t("notification.error.chooseItem", {
            item: t("common.text.package"),
          })
        );

        return;
      }
    }

    setCurrentStep(step);
  };

  const handleShowPackageDetail = (assessment: Assessment) => {
    setSelectedAssessmentDetail(assessment);
  };

  const handleClosePackageDetail = () => {
    setSelectedAssessmentDetail(undefined);
  };

  const generateDefaultPackageSelect = (
    numberOfSession: number = 0
  ): IPackageSchedule[] => {
    return new Array(numberOfSession).fill({
      schedule: undefined,
      time: undefined,
    });
  };

  const resetScheduleData = () => {
    setSchedules([]);
    setAssessmentSchedules([]);
  };

  const handlePackageSelect = (assessment: Assessment) => {
    setBooking((s) => ({
      selectedAsessment: assessment,
      selectedSchedules: generateDefaultPackageSelect(
        assessment.number_of_sessions
      ),
    }));
  };

  const handlePsychologistSelect = (psychologist: Psychologist) => {
    setBooking((s) => ({
      ...s,
      selectedPsychologist: psychologist,
      selectedSchedules: generateDefaultPackageSelect(
        selectedAsessment?.number_of_sessions
      ),
    }));
  };

  const handleConsultationTypeSelect = (val: string) => {
    setBooking((s) => ({
      ...s,
      selectedConsultationType: val,
      selectedSchedules: generateDefaultPackageSelect(
        selectedAsessment?.number_of_sessions
      ),
    }));
  };

  const handleTimeSelect = (schedule: Schedule, selectedTime: ScheduleTime) => {
    if (selectedSchedules) {
      const newSchedule = [...selectedSchedules].map((item) => {
        if (item?.schedule?.id === schedule.id) {
          return {
            ...item,
            time: selectedTime,
          };
        }

        return item;
      });

      setBooking((s) => ({
        ...s,
        selectedSchedules: newSchedule,
      }));
    }
  };

  const handleDateSelect = (val: Moment) => {
    const selectedDate = moment(val).format("YYYY-MM-DD");
    const selectedSchedule = schedules.find(
      (item) => item.date === selectedDate
    );
    const oldSelectedSchedules = _.clone(selectedSchedules);

    if (oldSelectedSchedules?.length) {
      if (isMultiSession) {
        const idx = oldSelectedSchedules.findIndex((item) => !item.schedule);
        if (idx !== -1) {
          const newData = _.clone(oldSelectedSchedules[idx]);
          newData.schedule = selectedSchedule;
          oldSelectedSchedules[idx] = newData;

          loadSessionScheduleTime(
            idx,
            selectedDate,
            _.get(assessmentSchedules, `[${idx}].session_duration`)
          );
        }

        setBooking((prevState) => ({
          ...prevState,
          selectedSchedules: oldSelectedSchedules,
        }));
      } else {
        loadSessionScheduleTime(
          0,
          selectedDate,
          _.get(assessmentSchedules, `[${0}].session_duration`)
        );
        setBooking((prevState) => ({
          ...prevState,
          selectedSchedules: [
            {
              schedule: selectedSchedule,
            },
          ],
        }));
      }
    }
  };

  const handlePatientSelect = (patient: Patient) => {
    setBooking((prevState) => ({
      ...prevState,
      selectedPatient: patient,
    }));
  };

  const handleResetSelectedSchedules = () => {
    setBooking((s) => ({
      ...s,
      selectedSchedules: generateDefaultPackageSelect(
        selectedAsessment?.number_of_sessions
      ),
    }));
  };

  const handleResetSelectedSchedule = (scheduleId: string | number) => {
    const oldSchedules = _.clone(selectedSchedules);
    if (oldSchedules?.length) {
      const scheduleIdx = oldSchedules.findIndex(
        (item) => item.schedule?.id === scheduleId
      );
      const newPackageSchedules = oldSchedules.map((item, i) => {
        if (i >= scheduleIdx) {
          return {
            schedule: undefined,
            time: undefined,
          };
        }

        return item;
      });

      setBooking((prevState) => ({
        ...prevState,
        selectedSchedules: newPackageSchedules,
      }));
    }
  };

  const selectedScheduleDates: string[] = useMemo(() => {
    return selectedSchedules?.map((item) => item.schedule?.date ?? "") ?? [];
  }, [selectedSchedules]);

  const getDisabledCalendarData = useCallback(
    (currentDate: Moment) => {
      const availableDate = schedules
        .map((item) => item.date)
        .includes(currentDate.format("YYYY-MM-DD"));

      if (isMultiSession) {
        const currentSelectedSchedules =
          selectedSchedules?.filter((item) => !!item.schedule) ?? [];
        const lastSelectedScheduleIdx = currentSelectedSchedules.length - 1;
        const lastSelectedSchedule =
          currentSelectedSchedules[lastSelectedScheduleIdx];

        const sessionMinDays =
          assessmentSchedules[lastSelectedScheduleIdx]?.session_min_days ?? 0;

        if (lastSelectedSchedule) {
          const lastSelectedScheduleDate = lastSelectedSchedule.schedule?.date;
          const date = moment(new Date(lastSelectedScheduleDate ?? ""))
            .add(sessionMinDays, "days")
            .format("YYYY-MM-DD");

          return (
            !availableDate ||
            currentDate.startOf("day") <= moment(date).startOf("day")
          );
        }

        return !availableDate;
      }

      return !availableDate;
    },
    [assessmentSchedules, isMultiSession, schedules, selectedSchedules]
  );

  const handleShowPatientForm = (patient: Patient) => {
    setShowPatientForm(patient);
  };

  const handleClosePatientForm = () => {
    setShowPatientForm(undefined);
  };

  const handleBookingCart = useCallback(
    async (type: string) => {
      setCartLoading(true);

      const payload: any = {
        customer_id: selectedPatient?.id,
        assessment_id: selectedAsessment?.id,
      };

      if (selectedAsessment?.has_schedule) {
        if (isMultiSession) {
          payload.schedules = selectedSchedules?.map((item, i) => ({
            id: item.time?.id,
            duration: _.get(assessmentSchedules, `${[i]}.session_duration`),
          }));
          payload.is_package = true;
        } else {
          payload.schedule_id = _.get(selectedSchedules, "[0].time.id");
          payload.duration = _.get(assessmentSchedules, "[0].session_duration");
        }
      }

      await CartRepository.create(payload)
        .then(async (response) => {
          const { data: responseData } = response.data;
          setCartLoading(false);

          await refetchUser();

          navigate(`/cart?carts=${responseData.id}`);
        })
        .catch((error: AxiosError) => {
          setCartLoading(false);
          handleErrorSaveDataResponse(t, error);
        });
    },
    [
      assessmentSchedules,
      isMultiSession,
      navigate,
      refetchUser,
      selectedAsessment?.has_schedule,
      selectedAsessment?.id,
      selectedPatient?.id,
      selectedSchedules,
      t,
    ]
  );

  const loadAssessment = useCallback(async () => {
    setContentLoading(true);

    await AssessmentRepository.findAll({
      is_active: 1,
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted()) {
          const { data: responseData } = response.data;
          setAssessments(responseData);
          setContentLoading(false);
        }
      })
      .catch(() => {
        if (isMounted()) {
          setContentLoading(false);
        }
      });
  }, [isMounted]);

  const loadPsychologists = useCallback(async () => {
    if (selectedAsessment?.id) {
      setContentLoading(true);

      await AssessmentPsychologistRepository.findAll(selectedAsessment?.id)
        .then((response) => {
          if (isMounted()) {
            const { data: responseData } = response.data;
            setPsychologists(responseData);
            setContentLoading(false);
          }
        })
        .catch(() => {
          if (isMounted()) {
            handleErrorLoadDataResponse(t, t("notification.error.default"));
            setContentLoading(false);
          }
        });
    }
  }, [selectedAsessment?.id, isMounted, t]);

  const loadSessionScheduleTime = useCallback(
    async (idx: number, date: string, duration: number) => {
      setFetchingSessionScheduleTimeIdx(idx);

      await ScheduleTimeRepository.findAll({
        psychologist_id: selectedPsychologist?.id,
        session_type: "ASSESSMENT",
        type: selectedConsultationType,
        date: date,
        duration: duration,
      })
        .then((response) => {
          if (isMounted()) {
            const { data: responseData } = response.data;
            setSessionScheduleTimes((s) => ({
              ...s,
              [idx]: responseData,
            }));

            setFetchingSessionScheduleTimeIdx(undefined);
          }
        })
        .catch(() => {
          if (isMounted()) {
            setFetchingSessionScheduleTimeIdx(undefined);
          }
        });
    },
    [isMounted, selectedConsultationType, selectedPsychologist?.id]
  );

  const loadSchedule = useCallback(async () => {
    setIsFetchingSchedule(true);
    resetScheduleData();

    try {
      const [scheduleRes, assessmentScheduleRes] = await Promise.all([
        ScheduleRepository.findAll({
          psychologist: selectedPsychologist?.id,
          type: selectedConsultationType,
          session_type: "ASSESSMENT",
        }),
        AssessmentRepository.getSchedule(selectedAsessment?.id as number, {
          psychologist: selectedPsychologist?.id,
          type: selectedConsultationType,
        }),
      ]);
      setSchedules(scheduleRes.data.data);
      setAssessmentSchedules(assessmentScheduleRes.data.data);
      setIsFetchingSchedule(false);
    } catch (error: any) {
      if (error?.code !== "ERR_CANCELED") {
        if (isMounted()) {
          handleErrorLoadDataResponse(t, t("common.text.availableSchedule"));
          setIsFetchingSchedule(false);
        }
      }
    }
  }, [
    selectedPsychologist?.id,
    selectedConsultationType,
    selectedAsessment?.id,
    isMounted,
    t,
  ]);

  const loadPatientData = useCallback(async () => {
    setContentLoading(true);

    await AccountRepository.patient({
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted()) {
          const { data: responseData } = response.data;

          if (selectedPatient) {
            const patient = responseData.find(
              (item) => item.id === selectedPatient?.id
            );

            if (patient) {
              handlePatientSelect(patient);
            }
          } else {
            if (responseData?.length > 1) {
              handlePatientSelect(responseData[0]);
            }
          }
          setPatients(responseData);
        }
      })
      .catch((err) => {
        if (err.code !== "ERR_CANCELED") {
          if (isMounted()) {
            handleErrorLoadDataResponse(t, t("notification.error.default"));
          }
        }
      })
      .finally(() => {
        setContentLoading(false);
      });
  }, [isMounted, selectedPatient, t]);

  useEffect(() => {
    if (currentStep === "package") {
      loadAssessment();
    }

    if (currentStep === "phsychologists") {
      loadPsychologists();
    }

    if (currentStep === "patient") {
      loadPatientData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (
      currentStep === "schedule" &&
      selectedPsychologist &&
      selectedConsultationType
    ) {
      loadSchedule();
    }
  }, [
    currentStep,
    selectedPsychologist,
    selectedConsultationType,
    loadSchedule,
  ]);

  useEffect(() => {
    const handleBoforeUnload = (e: any) => {
      if (booking !== undefined) {
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
    window.addEventListener("beforeunload", handleBoforeUnload);
    return () => window.removeEventListener("beforeunload", handleBoforeUnload);
  }, [booking]);

  useEffect(() => {
    if (currentStep === "preview") {
      previewBookingActionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentStep]);

  useEffect(() => {
    if (selectedAsessment) {
      handleChangeStep(currentStepIdx + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsessment]);

  useEffect(() => {
    if (currentStep === "phsychologists") {
      if (selectedPsychologist) {
        handleChangeStep(currentStepIdx + 1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPsychologist]);

  useEffect(() => {
    if (currentStep === "schedule") {
      if (selectedConsultationType) {
        scheduleCalendarSectionRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConsultationType]);

  return {
    t,
    isMobile,
    stepIdx,
    currentStep,
    currentStepIdx,
    contentLoading,
    handleChangeStep,
    assessments,
    isMultiSession,
    selectedAssessmentDetail,
    handleShowPackageDetail,
    handleClosePackageDetail,
    booking,
    handlePackageSelect,
    psychologists,
    handlePsychologistSelect,
    isFethcingSchedule,
    schedules,
    handleConsultationTypeSelect,
    handleTimeSelect,
    handleDateSelect,
    handleResetSelectedSchedules,
    handleResetSelectedSchedule,
    assessmentSchedules,
    getDisabledCalendarData,
    selectedScheduleDates,
    showPatientForm,
    handleShowPatientForm,
    handleClosePatientForm,
    patients,
    loadPatientData,
    handlePatientSelect,
    alertMessage,
    setAlertMessage,
    showSchedules,
    setShowSchedules,
    handleBookingCart,
    cartLoading,
    previewBookingActionRef,
    scheduleCalendarSectionRef,
    isVerifiedUser,
    fetchingSessionScheduleTimeIdx,
    sessionScheduleTimes,
  };
};

const PdfIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1686_11541)">
      <g filter="url(#filter0_d_1686_11541)">
        <path
          d="M29.748 21.9745C28.6803 20.0464 24.0043 18.8184 19.8754 18.5855C19.5022 18.186 19.1234 17.7658 18.7351 17.324C15.1866 13.2668 13.7497 7.37083 13.213 4.24739C13.1492 3.58554 13.0791 3.03533 13.0201 2.62547C12.9722 2.29933 12.8717 1.61914 12.1645 1.61914C11.938 1.61914 11.7195 1.71642 11.564 1.88946C11.312 2.17732 11.3495 2.48672 11.3942 2.84396C11.4444 3.25382 11.5201 3.80244 11.6278 4.4284C11.93 7.59968 12.0728 13.6376 9.63189 18.434C9.37752 18.934 9.12394 19.414 8.87196 19.8718C4.30442 21.2002 0.618814 23.5693 0.07897 25.5341C-0.116394 26.2454 0.053453 26.9272 0.558211 27.4551C1.1459 28.0691 1.83964 28.3801 2.6203 28.3801C4.78127 28.3801 7.24446 25.9735 9.94128 21.2297C11.1422 20.914 12.3343 20.6939 13.4866 20.5775C13.9355 20.532 14.6619 20.4371 15.1085 20.3685C16.3389 20.1788 17.7311 20.1022 19.1617 20.1493C22.4725 23.5909 25.1797 25.3356 27.2083 25.3356C28.2976 25.3348 29.1994 24.8037 29.7465 23.8404C30.083 23.244 30.0846 22.5805 29.748 21.9745ZM2.6195 26.7853C2.2814 26.7853 1.99274 26.6481 1.70966 26.3523C1.59404 26.2319 1.56852 26.1282 1.61637 25.9576C1.89466 24.9457 4.23266 23.2113 7.64954 21.9522C5.71902 24.9983 3.87143 26.7853 2.6195 26.7853ZM14.8637 18.7945C14.4394 18.8598 13.7505 18.9492 13.3247 18.9938C12.5504 19.0704 11.7546 19.1924 10.9516 19.3566C10.9851 19.2912 11.0186 19.2251 11.0521 19.1589C12.249 16.8073 12.9906 13.9877 13.2649 10.7558C14.3629 13.829 15.795 16.3855 17.5342 18.3742C17.5852 18.4332 17.6386 18.493 17.6897 18.5536C16.6857 18.5776 15.7384 18.6597 14.8637 18.7945ZM28.3558 23.0574C28.091 23.5263 27.7147 23.744 27.1748 23.744C25.9069 23.744 23.9565 22.5439 21.6225 20.343C25.2347 20.7967 27.8797 21.89 28.3542 22.7472C28.4228 22.8716 28.4228 22.9378 28.3558 23.0574Z"
          fill="#FF290B"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1686_11541"
        x="-2"
        y="0.619141"
        width="33.9995"
        height="30.7617"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1686_11541"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1686_11541"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1686_11541">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PdfIcon;

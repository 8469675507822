import { api, IFindAllRepository, IFindOneRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Booking } from "../models/Booking";
import { Schedule } from "../models/Schedule";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IScheduleRepository<T> extends IFindAllRepository<T> {}

const ScheduleRepository: IScheduleRepository<Schedule> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/schedule`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ScheduleRepository);

export default ScheduleRepository;

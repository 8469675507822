import { VuiFormItem } from "../../../../@vendor/components";
import { ICounselingForm } from "../interface";
import { useTranslation } from "react-i18next";
import { Input, Radio, Space } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { ChangeEvent } from "react";
import CounselingFormLabel from "../Label";
import { OtherData } from "../../../../pages/app/CounselingForm";

interface MultipleChoice {
  handleChangeRadio: (e: RadioChangeEvent, id: string) => void;
  otherRadioIds: string[];
  otherData: OtherData[];
  handleChangeInputRadio: (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
}

const CounselingFormMultipleChoice = ({
  question,
  handleChangeRadio,
  otherRadioIds,
  otherData,
  handleChangeInputRadio,
}: ICounselingForm & MultipleChoice) => {
  const { t } = useTranslation();

  return (
    <VuiFormItem
      rules={[
        {
          required: question.is_required,
          message: t("validation.required", {
            item: question.question,
          }),
        },
      ]}
      name={String(question.id)}
      label={
        <CounselingFormLabel
          label={question.question}
          isRequired={question.is_required}
        />
      }
    >
      <Radio.Group onChange={(e) => handleChangeRadio(e, String(question.id))}>
        <Space direction="vertical">
          {question.answers.map((answer, index) => (
            <Radio
              value={answer}
              key={`${question.id}_${index}_multiple_choice`}
            >
              {typeof answer === "string" ? answer : ""}
            </Radio>
          ))}
          {question.is_others ? (
            <Radio value={"other"}>
              Other
              <br />
              {otherRadioIds.find((id) => id === String(question.id)) && (
                <Input
                  defaultValue={
                    otherData.find((other) => other.id === String(question.id))
                      ?.answer || ""
                  }
                  style={{ marginTop: 6 }}
                  onChange={(e) =>
                    handleChangeInputRadio(e, String(question.id))
                  }
                />
              )}
            </Radio>
          ) : null}
        </Space>
      </Radio.Group>
    </VuiFormItem>
  );
};

export default CounselingFormMultipleChoice;

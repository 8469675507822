import React from "react";
import ReactDOM from "react-dom/client";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles/styles.less";
import "antd-country-phone-input/dist/index.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ID from "antd/lib/locale/id_ID";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { store } from "./stores";
import ScrollToTop from "./components/molecules/ScrollToTop";
import { ConfigProvider as PhoneInputConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Helmet>
          <title>Personal Growth</title>
        </Helmet>

        <ConfigProvider locale={ID}>
          <PhoneInputConfigProvider locale={en}>
            <App />
          </PhoneInputConfigProvider>
        </ConfigProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

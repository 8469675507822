import { api } from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IDownloadRepository {
  historyNote(id: string): AxiosPromise<Blob>;
}

const DownloadRepository: IDownloadRepository = {
  historyNote(id) {
    return api.get(`${endPoint()}/api/account/history/${id}/download-note`, {
      responseType: "blob",
      cancelToken:
        cancelTokenHandlerObject[
          this.historyNote.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(DownloadRepository);

export default DownloadRepository;

import { api, ICreateRepository, IUpdateRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { UpdateUser, User } from "../models/User";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T>
  extends ICreateRepository<UpdateUser>,
    IUpdateRepository<UpdateUser> {}

const Repository: IRepository<User> = {
  create(payload) {
    return api.post(`${endPoint()}/api/customer`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update(id, payload) {
    return api.put(`${endPoint()}/api/customer/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;

import "./_style.less";
import { VuiCheckbox, VuiNumberFormat } from "../../../../@vendor/components";
import { BookingTag } from "../../../atoms";
import Paragraph from "antd/lib/typography/Paragraph";
import TrashIcon from "../../../icons/Trash";
import {
  formatDateCartItem,
  getConsultationTypeLabel,
} from "../../../../@vendor/utils";
import { Cart } from "../../../../models/Cart";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Divider, Space } from "antd";
import clsx from "clsx";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { ArrowDownSmallIcon, ArrowUpIcon } from "../../../icons";
import _ from "lodash";

const { Panel } = Collapse;

interface ICartItem {
  data: Cart;
  onCheck: (event: CheckboxChangeEvent, data: Cart) => void;
  onClickDelete: () => void;
  disabled?: boolean;
}

const CartItem: FC<ICartItem> = ({
  data,
  onCheck,
  onClickDelete,
  disabled,
}) => {
  const { t } = useTranslation();

  const [showCompleteDescription, setShowCompleteDescription] = useState(false);

  const isNoSchedule = useMemo(() => {
    return !data.is_package && !data?.schedule_id;
  }, [data]);

  const renderDescription = () => {
    const description: string = _.get(data, "assessment.description", "");

    if (description.length > 80) {
      return (
        <div className="description-wrapper">
          <p
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{
              __html: showCompleteDescription
                ? description
                : `${description.slice(0, 80)}...`,
            }}
          />
          <div
            className="link-text"
            onClick={() => setShowCompleteDescription((s) => !s)}
          >
            <b>
              {showCompleteDescription
                ? t("common.button.viewLess")
                : t("common.button.viewMore")}
            </b>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{ textAlign: "justify" }}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    );
  };

  return (
    <>
      <Divider />
      <div
        className={clsx({
          "cart-list-patient-wrapper": true,
          "is-unavailable": !data.is_available || disabled,
        })}
      >
        <VuiCheckbox
          checked={data.is_active}
          disabled={!data.is_available || disabled}
          onChange={(event) => {
            onCheck(event, data);
          }}
        />

        <div className="cart-list-patient-info">
          <div className="cart-list-info-left">
            {isNoSchedule ? (
              <Space style={{ marginBottom: "1em" }} align="center">
                <img
                  className="assessment-img"
                  src={_.get(data, "assessment.photo.url")}
                  alt="assessment-img"
                />
                <Paragraph className="text-bold" style={{ margin: 0 }}>
                  {_.get(data, "assessment_name")}
                </Paragraph>
              </Space>
            ) : (
              <Paragraph className="text-bold">
                {_.get(data, "psychologist_name", "-")}
              </Paragraph>
            )}

            <div style={{ marginBottom: 6 }}>
              <BookingTag
                title={
                  data?.assessment_id
                    ? t("common.text.assessmentAndPrograms")
                    : undefined
                }
                type={data?.assessment_id ? "ASSESSMENT" : "COUNSELING"}
                isPackage={data.is_package}
              />
            </div>

            {!!data.is_package && (
              <Paragraph className="cart-list-item-info">
                {t("common.text.session")}: {data.session_amount} sessions
              </Paragraph>
            )}
            {!isNoSchedule && (
              <Paragraph className="cart-list-item-info">
                {t("common.text.category")}:{" "}
                {data?.assessment_id
                  ? data?.assessment_name
                  : data.category_name}
              </Paragraph>
            )}

            {!isNoSchedule && (
              <>
                <Paragraph className="cart-list-item-info">
                  {t("common.text.consultationType")}:{" "}
                  {getConsultationTypeLabel(data.consultation_type, t)}
                </Paragraph>

                {!data.is_package && (
                  <Paragraph className="cart-list-item-info">
                    {formatDateCartItem(t, data)}
                  </Paragraph>
                )}

                {!!data.is_package && !data?.assessment_id && (
                  <Paragraph className="cart-list-item-info">
                    {t("common.text.duration")}:{" "}
                    {t("common.text.minutesItem", { item: data.duration })}
                  </Paragraph>
                )}

                {!!data.is_package && (
                  <Collapse
                    defaultActiveKey={["0"]}
                    bordered={false}
                    ghost
                    expandIcon={({ isActive }) => (
                      <div className="expand-icon-wrapper">
                        {!isActive ? <ArrowDownSmallIcon /> : <ArrowUpIcon />}
                      </div>
                    )}
                  >
                    <Panel header="Schedule" key={"1"}>
                      <Space
                        direction="vertical"
                        size={12}
                        style={{ width: "100%" }}
                      >
                        {data.cart_schedules.map((item, i) => {
                          return (
                            <Space key={i} direction="vertical" size={8}>
                              <b className="cart-list-item-info">
                                Session {i + 1}
                              </b>
                              <label className="cart-list-item-info">
                                {formatDateCartItem(t, item as unknown as Cart)}
                              </label>
                            </Space>
                          );
                        })}
                      </Space>
                    </Panel>
                  </Collapse>
                )}
              </>
            )}

            {isNoSchedule && (
              <>
                <Paragraph style={{ margin: 0 }}>
                  {t("common.text.description")}:{" "}
                </Paragraph>

                {renderDescription()}
              </>
            )}
          </div>
          <div className="cart-list-info-right">
            <Paragraph className="cart-list-info-right-price">
              <VuiNumberFormat value={data.subtotal} />
            </Paragraph>
            <div className="cart-list-info-right-icon cursor-pointer">
              <TrashIcon onClick={onClickDelete} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItem;

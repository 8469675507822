import "./_style.less";
import {
  VuiCalendar,
  VuiFormCard,
  VuiSecondaryTitle,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { ScheduleDetailModal } from "../../modals";
import AccountRepository from "../../../../repositories/AccountRepository";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import { Schedule } from "../../../../models/Schedule";
import { ScheduleLog } from "../../../../models/ScheduleLog";
import { ScheduleCard } from "../../../atoms";
import { Spin } from "antd";

export interface ScheduleParams {
  year?: string;
  month?: string;
  psychologist?: number;
}

const scheduleDefaultValue: Schedule = {
  can_fill_form: false,
  can_reschedule: false,
  category_name: "",
  consultation_type: "Online",
  date: "",
  form_id: 0,
  id: "",
  patient_name: "",
  place: "",
  psychologists_name: "",
  time_from: "",
  time_to: "",
  psychologist: null,
  times: [],
  booking_id: "",
  time: null,
  is_filled_form: false,
  is_active: false,
  is_package: false,
  session_type: "COUNSELING",
};

interface IScheduleParams extends ScheduleParams {
  isYearChange: boolean;
}

const defaultScheduleParams: IScheduleParams = {
  year: String(moment().year()),
  month: String(moment().format("M")),
  isYearChange: true,
};

const AccountSchedule = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [data, setData] = useState<Schedule[]>([]);
  const [monthData, setMonthData] = useState<ScheduleLog[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [detailData, setDetailData] = useState<Schedule | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scheduleParams, setScheduleParams] = useState<IScheduleParams>(
    defaultScheduleParams
  );
  const selectedData: Schedule[] = useMemo(() => {
    const filterData = data.filter(
      (item) => item.date === moment(selectedDate).format("YYYY-MM-DD")
    );

    return filterData;
  }, [selectedDate, data]);

  const handleSelectDate = useCallback(
    (date: Moment) => {
      const isYearChange = selectedDate.year() !== date.year();
      const isMonthChange = selectedDate.month() !== date.month();
      const year = String(date.year());
      const month = date.format("M");
      const params: IScheduleParams = {
        year,
        month,
        isYearChange: false,
      };

      if (isYearChange || isMonthChange) {
        if (isYearChange) {
          params.isYearChange = true;
        }
        setScheduleParams(params);
      }
      setSelectedDate(date);
    },
    [selectedDate]
  );

  const loadData = useCallback(async () => {
    setIsLoading(true);

    try {
      if (isMounted) {
        const schedule = await AccountRepository.schedule({
          year: scheduleParams.year,
          month: scheduleParams.month,
        });
        const scheduleData = schedule.data.data as Schedule[];

        if (scheduleParams.isYearChange) {
          const monthSchedule = await AccountRepository.schedule({
            year: scheduleParams.year,
          });
          const monthScheduleData = monthSchedule.data.data as ScheduleLog[];
          setMonthData(monthScheduleData);
        }

        setData(scheduleData);

        setIsLoading(false);
      }
    } catch (err: any) {
      if (isMounted) {
        console.log(err, "err");
        handleErrorLoadDataResponse(t, t("common.text.schedule"), err);
        setIsLoading(false);
      }
    }
  }, [scheduleParams]);

  const handleOpenDetail = (data: Schedule) => {
    setDetailData(data);
  };

  const handleCloseModal = () => setDetailData(null);

  const renderSelectedData = useMemo(() => {
    if (!selectedData.length) {
      return;
    }

    return selectedData.map((data) => (
      <ScheduleCard key={data.id} onClick={handleOpenDetail} data={data} />
    ));
  }, [selectedData]);

  useEffect(() => {
    loadData();
  }, [scheduleParams.month, scheduleParams.year]);

  return (
    <>
      <div id="account-schedule">
        <VuiSecondaryTitle text={t("common.text.schedule")} />

        <VuiFormCard
          bodyStyle={{ padding: "5px 0" }}
          style={{ marginBottom: 24 }}
        >
          <Spin spinning={isLoading}>
            <VuiCalendar
              value={selectedDate}
              data={data}
              monthData={monthData}
              onSelectDate={handleSelectDate}
            />
          </Spin>
        </VuiFormCard>

        {renderSelectedData}

        <div className="account-schedule-helpers">
          <div className="account-schedule-helper">
            <span className="bullet" />
            <span>{t("common.text.counselingSchedule")}</span>
          </div>
          <div className="account-schedule-helper">
            <span className="bullet yellow" />
            <span>{t("common.text.counselingPackageSchedule")}</span>
          </div>
          <div className="account-schedule-helper">
            <span className="bullet green" />
            <span>{t("common.text.assessmentSchedule")}</span>
          </div>
          <div className="account-schedule-helper">
            <span className="line" />
            <span>{t("common.text.today")}</span>
          </div>
        </div>
      </div>

      <ScheduleDetailModal
        visible={Boolean(detailData)}
        title={t("common.text.details")}
        onClose={handleCloseModal}
        data={detailData || scheduleDefaultValue}
      />
    </>
  );
};

export default AccountSchedule;

import { api, IFindAllRepository, IFindOneRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { PaymentMethod } from "../models/PaymentMethod";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IPaymentMethodRepository<T>
  extends IFindAllRepository<T>,
    IFindOneRepository<T> {}

const PaymentMethodRepository: IPaymentMethodRepository<PaymentMethod> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/payment-method`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`${endPoint}/api/payment-method/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  PaymentMethodRepository
);

export default PaymentMethodRepository;

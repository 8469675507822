import "./style.less";
import { Col, Divider, Modal, Radio, Row, Space, Typography } from "antd";
import { FC, useState } from "react";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { CloseIcon } from "../../../icons";
import { OtpProvider } from "../../../../@vendor/utils";
import { otpProviders } from "../../../../constants/auth.constant";
import React from "react";

interface OtpProviderSelectModalProps {
  visible: boolean;
  onClose: () => void;
  provider: OtpProvider;
  onSubmitting: boolean;
  onSubmit: (provider: OtpProvider) => void;
}

const OtpProviderSelectModal: FC<OtpProviderSelectModalProps> = ({
  onClose,
  visible,
  provider,
  onSubmit,
  onSubmitting,
}) => {
  const { t } = useTranslation();

  const [selectedProvider, setSelectedProvider] = useState(provider);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx("default-modal resend-otp-modal")}
      title={null}
      width={437}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={t("common.text.resendCode")} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <Space className="flex" direction="vertical" size={20}>
        <Typography.Paragraph className="text-center" style={{ margin: 0 }}>
          {t("auth.verificationCode.chooseProvider")}
        </Typography.Paragraph>

        <div>
          {otpProviders.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <Row
                  className="cursor-pointer"
                  justify="space-between"
                  onClick={() => setSelectedProvider(item)}
                >
                  <Col>
                    <Typography.Text>
                      {t(`common.text.${item.toLowerCase()}`)}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Radio
                      checked={selectedProvider === item}
                      type="radio"
                      key={item}
                    />
                  </Col>
                </Row>
                {i !== otpProviders.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </div>
        <VuiButton
          label={t("common.button.resend")}
          loading={onSubmitting}
          buttonProps={{
            type: "primary",
            onClick: () => onSubmit(selectedProvider),
          }}
        />
      </Space>
    </Modal>
  );
};

export default OtpProviderSelectModal;

import "./_style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";

import {
  VuiButton,
  VuiFormCard,
  VuiHelmet,
} from "../../../../../@vendor/components";
import { TickIllustration } from "../../../../../components";
import { useCallback } from "react";

const AccountRescheduleSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToSchedule = useCallback(() => {
    navigate("/account?tab=schedule");
  }, []);

  return (
    <div id="account-reschedule-success-page">
      <VuiHelmet title={t("common.text.resetPasswordSuccess")} />

      <div className="reschedule-success-wrapper">
        <TickIllustration style={{ marginBottom: 16 }} />
        <Paragraph className="reschedule-success-title">
          {t("common.text.setNewSchedule")}
        </Paragraph>
        <VuiButton
          buttonProps={{
            type: "primary",
            block: true,
            onClick: goToSchedule,
          }}
          label={t("common.button.seeSchedule")}
        />
      </div>
    </div>
  );
};

export default AccountRescheduleSuccessPage;

const TickIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6309 0.000525761C14.2853 0.0101221 13.9572 0.147482 13.716 0.383526L5.78559 7.93954L2.29079 4.60974C2.16818 4.48807 2.02131 4.39092 1.8588 4.324C1.69628 4.25708 1.52139 4.22172 1.34435 4.22001C1.16732 4.21829 0.991704 4.25025 0.827795 4.31401C0.663886 4.37777 0.514977 4.47205 0.389789 4.59133C0.264601 4.71061 0.165649 4.85249 0.0987299 5.00866C0.0318107 5.16483 -0.00173098 5.33215 6.87712e-05 5.50083C0.00186852 5.6695 0.0389739 5.83614 0.109212 5.99098C0.179449 6.14582 0.281407 6.28575 0.409115 6.40258L4.84475 10.6288C5.09431 10.8665 5.43273 11 5.78559 11C6.13845 11 6.47687 10.8665 6.72643 10.6288L15.5977 2.17637C15.7897 1.99823 15.9207 1.76902 15.9737 1.51874C16.0267 1.26846 15.9992 1.00876 15.8947 0.773642C15.7902 0.538522 15.6137 0.33893 15.3882 0.200987C15.1627 0.0630431 14.8987 -0.00682469 14.6309 0.000525761Z"
      fill="#232D3B"
    />
  </svg>
);

export default TickIcon;

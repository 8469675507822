import { FC } from "react";
import "./_style.less";
import { Space } from "antd";

export interface VuiSelectlabelWithImageProps {
  image?: string;
  label?: string;
}

const SelectlabelWithImageProps: FC<VuiSelectlabelWithImageProps> = ({
  image = "",
  label = "",
}) => {
  return (
    <Space>
      <img
        className="select-label-with-image"
        width={20}
        height={20}
        src={image}
        alt="select-label-img"
        style={{ width: 20, height: 20 }}
      />
      <span>{label}</span>
    </Space>
  );
};

export default SelectlabelWithImageProps;

import "./_style.less";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../@vendor/utils";
import {
  VuiContentLoading,
  VuiHelmet,
  VuiTitle,
} from "../../../@vendor/components";
import PageRepository from "../../../repositories/PageRepository";
import {Collapse, Empty} from "antd";
import { Faq } from "../../../models/Faq";
import {CaretRightOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import * as React from "react";

const { Panel } = Collapse;

const AppFrequentlyAskedQuestion = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [data, setData] = useState<Faq>({
    meta_description: "",
    meta_keyword: "",
    meta_title: "",
    questions: []
  });

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await PageRepository.frequentlyAskQuestion()
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.frequentlyAskedQuestion"));
        }
      });
  }, [isMounted]);

  const renderData = useMemo(() => {
    if (!data) {
      return <Empty />;
    }

    return (
      <div className="faq-wrapper">
        <Collapse
            expandIconPosition="end"
            bordered={false}
            className="faq-collapse"
            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
        >
          {data.questions.map((item, index) => (
              <Panel header={item.question} key={`faq_${index}`} className="faq-panel">
                <span dangerouslySetInnerHTML={{__html: item.answer || ''}} />
              </Panel>
          ))}
        </Collapse>
      </div>
    );
  }, [data]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <div id="app-faq-page">
      <VuiHelmet title={data.meta_title} />

      <div className="container">
        <VuiTitle text={t("common.text.frequentlyAskedQuestion")} />

        {isFetchingData ? (
          <VuiContentLoading loading={isFetchingData} />
        ) : (
          renderData
        )}
      </div>
    </div>
  );
};

export default AppFrequentlyAskedQuestion;

import "./_style.less";
import Paragraph from "antd/lib/typography/Paragraph";
import { VuiNumberFormat, VuiSecondaryButton } from "../../index";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Psychologist } from "../../../../../models/Psychologist";
import { useNavigate } from "react-router-dom";
import { Radio, RadioProps } from "antd";
import clsx from "clsx";
import moment from "moment";

interface ICard {
  data: Psychologist;
  type?: String;
  nearestScedule?: string;
  showPrice?: boolean;
}

const VuiPsychologistCard: FC<ICard & RadioProps> = (props) => {
  const { type = "card", nearestScedule, showPrice = true, ...other } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={clsx("psychologist-card", {
        "psychologist-card-choice": type === "choice",
      })}
      onClick={type === "choice" ? other.onClick : undefined}
    >
      {type === "choice" && (
        <div className="psychologist-card-radio">
          <Radio {...other}></Radio>
        </div>
      )}

      <div className={"psychologist-card-content-wrapper"}>
        <img
          className="psychologist-card-image first-image"
          src={props?.data?.photo?.url}
          alt={props?.data.title}
        />
        <div>
          <Paragraph className="psychologist-card-name">
            {props?.data.name}
          </Paragraph>
          <div
            className="psychologist-card-title"
            dangerouslySetInnerHTML={{ __html: props?.data.title || "" }}
          ></div>

          {type === "choice" && (
            <>
              {nearestScedule ? (
                <Paragraph className="psychologist-card-nearest-schedule">
                  {t("common.text.nearestAvailableSchedule")},{" "}
                  <b>{moment(nearestScedule).format("dddd, DD MMMM YYYY")}</b>
                </Paragraph>
              ) : (
                <div style={{ marginTop: 6, marginBottom: 13 }} />
              )}
              {showPrice && (
                <Paragraph className="psychologist-card-rate">
                  <VuiNumberFormat
                    className="highlight-yellow"
                    prefix="IDR "
                    suffix=" / hour"
                    value={props?.data.sixty_minutes_price}
                  />
                </Paragraph>
              )}
            </>
          )}
        </div>
      </div>

      <div
        className={clsx("psychologist-card-hover", {
          choice: type === "choice",
        })}
      >
        {type === "card" && (
          <img
            className="psychologist-card-image"
            src={props?.data?.photo?.url}
            alt={props?.data.title}
          />
        )}

        <Paragraph className="psychologist-card-name">
          {props?.data.name}
        </Paragraph>

        {type === "choice" && (
          <div
            className="psychologist-card-title"
            dangerouslySetInnerHTML={{ __html: props?.data.title || "" }}
          ></div>
        )}

        <Paragraph className="psychologist-card-summary">
          {props?.data.summary}
        </Paragraph>

        {type === "card" && (
          <>
            <Paragraph className="psychologist-card-rate">
              <VuiNumberFormat
                className="highlight-yellow"
                prefix="IDR "
                suffix=" per hour"
                value={props?.data.sixty_minutes_price}
              />
            </Paragraph>
            <VuiSecondaryButton
              onClick={() => navigate(`/psychologists/${props?.data.id}`)}
              label={t("common.button.viewDetails")}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default VuiPsychologistCard;

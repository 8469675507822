import notification, { IconType } from "antd/lib/notification";

export const showNotification = (
  type: IconType,
  message: string,
  description?: string
) => {
  notification[type]({
    message: message,
    description: description,
  });
};

import "./style.less";
import { Divider, Modal, Space, Typography } from "antd";
import { FC, useMemo } from "react";
import {
  VuiNumberFormat,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { CloseIcon } from "../../../icons";
import { Assessment } from "../../../../models/Assessment";
import { getConsultationTypeLabel } from "../../../../@vendor/utils";

interface IModal {
  assessment?: Assessment;
  visible: boolean;
  onClose: () => void;
}

const AssessmentPackageDetailModal: FC<IModal> = ({
  onClose,
  visible,
  assessment = {} as Assessment,
}) => {
  const { t } = useTranslation();

  const assessmentMethod = useMemo(() => {
    if (assessment.is_online && assessment.is_offline) {
      return `${getConsultationTypeLabel(
        "Online",
        t
      )} & ${getConsultationTypeLabel("Offline", t)}`;
    }

    return getConsultationTypeLabel(
      assessment.is_online ? "Online" : "Offline",
      t
    );
  }, [assessment.is_offline, assessment.is_online, t]);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx("default-modal assessment-package-detail-modal")}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={assessment.name} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <Space direction="vertical" size={12}>
        <Typography.Text strong>{t("common.text.description")}</Typography.Text>
        <div dangerouslySetInnerHTML={{ __html: assessment.description }} />
      </Space>
      <Divider />
      {assessment.has_schedule && (
        <>
          <Space direction="vertical" size={12}>
            <Typography.Text strong>
              {t("common.text.assessmentMethod")}
            </Typography.Text>
            <Typography.Text>{assessmentMethod}</Typography.Text>
          </Space>
          <Divider />
        </>
      )}
      {assessment?.has_schedule && (
        <>
          <Space direction="vertical" size={12}>
            <Typography.Text strong>
              {t("common.text.numberOfSession")}
            </Typography.Text>
            <Typography.Text>{assessment?.number_of_sessions}</Typography.Text>
          </Space>
          <Divider />
        </>
      )}
      <Space direction="vertical" size={12}>
        <Typography.Text strong>{t("common.text.price")}</Typography.Text>
        <Typography.Text>
          <VuiNumberFormat value={assessment.price} prefix="Rp " />
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default AssessmentPackageDetailModal;

import "./_style.less";
import React from "react";
import EventCard from "../Card";

interface IEventList {
  data: Array<any>;
}

const EventList: React.FC<IEventList> = ({ data }) => {
  return (
    <>
      <div className="event-list">
        {data.map((item, key) => {
          return (
            <EventCard
              key={key}
              id={item?.id}
              name={item?.title}
              image={item?.photo?.url}
              date={item?.date}
            />
          );
        })}
      </div>
    </>
  );
};

export default EventList;

import "./_style.less";
import { useTranslation } from "react-i18next";
import {
  VuiContentLoading,
  VuiSecondaryButton,
  VuiTitle,
} from "../../../../@vendor/components";
import { useCallback, useMemo, useState } from "react";
import { Psychologist } from "../../../../models/Psychologist";
import { useNavigate, useParams } from "react-router-dom";
import { VuiPsychologistCard } from "../../../../@vendor/components/atoms/Card";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import {
  cleanHtml,
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";

const AppPsychologistsDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState<Psychologist | null>(null);
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [relatedPsychologist, setRelatedPsychologist] = useState<
    Psychologist[]
  >([]);
  const navigate = useNavigate();

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await PsychologistRepository.findOne(Number(id), {
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setRelatedPsychologist(responseData.related);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) setIsFetchingData(false);
        handleErrorLoadDataResponse(t, t("common.text.psychologist"));
      });
  }, [id]);

  const renderPsychologist = useMemo(() => {
    return (
      <>
        <VuiSecondaryButton
          onClick={() => navigate("/psychologists")}
          type="left"
          label={t("common.button.back")}
        />
        <section className="psychologists-info-section">
          <div className="psychologists-info-left">
            <div className="psychologists-image">
              <img src={data?.photo?.url} alt={data?.name} />
            </div>
          </div>
          <div className="psychologists-info-right">
            <div className="psychologists-name">{data?.name}</div>
            <div className="psychologists-title">{cleanHtml(data?.title)}</div>
            <div className="psychologists-description" dangerouslySetInnerHTML={{ __html: data?.description || '' }}/>
            <div className="text-align-right">
              <VuiSecondaryButton
                onClick={() => navigate(`/book-counseling?psychologist=${data?.id}`)}
                label={t("common.button.bookCounselingSessionNow")}
              />
            </div>
          </div>
        </section>
      </>
    );
  }, [data]);

  const renderRelatedPsychologist = useMemo(() => {
    if (!relatedPsychologist.length) {
      return;
    }

    return (
      <section className="section related-psychologists">
        <VuiTitle
          className="text-align-center-md"
          text={t("common.text.relatedPsychologists")}
        />
        <div className="related-psychologists-wrapper">
          {relatedPsychologist.map((psychologist) => {
            return <VuiPsychologistCard data={psychologist} />;
          })}
        </div>
      </section>
    );
  }, [relatedPsychologist]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  return (
    <div id="app-psychologists-detail-page">
      <div className="container">
        {isFetchingData ? (
          <VuiContentLoading loading={isFetchingData} />
        ) : (
          renderPsychologist
        )}

        {renderRelatedPsychologist}
      </div>
    </div>
  );
};

export default AppPsychologistsDetail;

const ExclamationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.00016 1.33203C4.32418 1.33203 1.3335 4.32272 1.3335 7.9987C1.3335 11.6747 4.32418 14.6654 8.00016 14.6654C11.6761 14.6654 14.6668 11.6747 14.6668 7.9987C14.6668 4.32272 11.6761 1.33203 8.00016 1.33203ZM8.00016 2.33203C11.1357 2.33203 13.6668 4.86316 13.6668 7.9987C13.6668 11.1342 11.1357 13.6654 8.00016 13.6654C4.86462 13.6654 2.3335 11.1342 2.3335 7.9987C2.3335 4.86316 4.86462 2.33203 8.00016 2.33203ZM7.99235 4.32487C7.85986 4.32694 7.7336 4.38151 7.64131 4.47661C7.54903 4.5717 7.49826 4.69953 7.50016 4.83203V8.83203C7.49923 8.89829 7.51147 8.96407 7.53617 9.02555C7.56088 9.08704 7.59756 9.143 7.64408 9.19018C7.69061 9.23737 7.74604 9.27484 7.80717 9.30041C7.8683 9.32598 7.9339 9.33915 8.00016 9.33915C8.06643 9.33915 8.13203 9.32598 8.19316 9.30041C8.25428 9.27484 8.30972 9.23737 8.35624 9.19018C8.40276 9.143 8.43945 9.08704 8.46415 9.02555C8.48886 8.96407 8.5011 8.89829 8.50016 8.83203V4.83203C8.50112 4.76511 8.48864 4.69868 8.46345 4.63667C8.43826 4.57466 8.40088 4.51834 8.35353 4.47104C8.30617 4.42375 8.2498 4.38644 8.18776 4.36133C8.12572 4.33622 8.05927 4.32382 7.99235 4.32487ZM8.00016 10.332C7.82335 10.332 7.65378 10.4023 7.52876 10.5273C7.40373 10.6523 7.3335 10.8219 7.3335 10.9987C7.3335 11.1755 7.40373 11.3451 7.52876 11.4701C7.65378 11.5951 7.82335 11.6654 8.00016 11.6654C8.17697 11.6654 8.34654 11.5951 8.47157 11.4701C8.59659 11.3451 8.66683 11.1755 8.66683 10.9987C8.66683 10.8219 8.59659 10.6523 8.47157 10.5273C8.34654 10.4023 8.17697 10.332 8.00016 10.332Z" />
  </svg>
);

export default ExclamationIcon;

import "./style.less";
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Tabs,
  Typography,
  Alert,
  Spin,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import { useNavigate } from "react-router-dom";

import {
  checkIsValidPhone,
  getCompletePhoneNumber,
  getErrorPhoneLogin,
  IAuthLogin,
  IAuthPhoneLogin,
  IAuthVerifyPhoneForm,
  OtpType,
  usePassportService,
} from "../../../@vendor/utils";
import {
  VuiButton,
  VuiFormCard,
  VuiFormItem,
  VuiFormLayout,
  VuiHelmet,
} from "../../../@vendor/components";
import { FacebookCircleIcon, GoogleIcon } from "../../../components";
import PhoneInput from "../../../@vendor/components/atoms/PhoneInput";
import {
  DEFAULT_PHONE_COUNTRY_CODE,
  errorFaultCode,
  localStorageKey,
} from "../../../constants";
import { CountryPhoneInputValue } from "antd-country-phone-input";
import _ from "lodash";
import { useLocalStorage } from "usehooks-ts";
import { otpProviders } from "../../../constants/auth.constant";
import clsx from "clsx";
import { InfoCircleOutlined } from "@ant-design/icons";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const AuthLoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, isOnSignIn, requestOtp, isOnRequestOtp } =
    usePassportService(true);
  const [isLoginWithPassword, setIsLoginWithPassword] = useState(true);
  const [emailLoginError, setEmailLoginError] = useState<string>();
  const [phoneLoginError, setPhoneLoginError] = useState<string>();
  const [authOtpRequest, setAuthOtpRequest] = useLocalStorage<
    CountryPhoneInputValue | undefined
  >(localStorageKey.authOtpRequest, undefined);
  const [verifyPhoneData, setVerifyPhoneData] = useLocalStorage<
    IAuthVerifyPhoneForm | undefined
  >(localStorageKey.authVerifyPhone, undefined);
  const [isOnVerifyPhone, setIsOnVerifyPhone] = useState(false);

  const [emailForm] = Form.useForm<IAuthLogin>();
  const [phoneForm] = Form.useForm<IAuthPhoneLogin>();

  const phone = Form.useWatch("phone", phoneForm) as CountryPhoneInputValue;

  const onEmailLogin = useCallback(
    async (values: IAuthLogin) => {
      setEmailLoginError(undefined);
      await login(values.username, values.password).catch((err) => {
        const errorCode = _.get(err, "response.data.hint.code");
        if (errorCode === errorFaultCode.loginEmailPhoneUnverified) {
          const errorData = _.get(err, "response.data.hint.data");
          setVerifyPhoneData({
            token: errorData.token,
            phone_number: {
              code: errorData?.phone_country,
              phone: errorData?.phone_number,
            },
            email: values?.username,
          });
        } else {
          setEmailLoginError(t("notification.error.invalidEmailOrPassword"));
        }
      });
    },
    [login, setVerifyPhoneData, t]
  );

  const onVerifyPhone = useCallback(async () => {
    setIsOnVerifyPhone(true);
    await requestOtp(
      getCompletePhoneNumber(
        String(verifyPhoneData?.phone_number?.code),
        verifyPhoneData?.phone_number?.phone
      ),
      otpProviders[0],
      OtpType.VERIFY_PHONE_OTP
    )
      .then(() => {
        navigate(`/auth/verification-code?type=${OtpType.VERIFY_PHONE_OTP}`);
      })
      .catch((err) => {
        setEmailLoginError(
          _.get(err, "response.data.fault.message") ??
            _.get(err, "response.data.message") ??
            t("notification.error.default")
        );
      })
      .finally(() => setIsOnVerifyPhone(false));
  }, [
    navigate,
    requestOtp,
    t,
    verifyPhoneData?.phone_number?.code,
    verifyPhoneData?.phone_number?.phone,
  ]);

  const onPhoneLogin = useCallback(
    async (values: IAuthPhoneLogin) => {
      setPhoneLoginError(undefined);
      const { phone } = values;

      if (isLoginWithPassword) {
        await login(
          getCompletePhoneNumber(String(phone?.code), phone?.phone),
          values.password,
          "*",
          "phone_password"
        ).catch((err) => {
          const errMsg = getErrorPhoneLogin(t, err);
          setPhoneLoginError(errMsg);
        });
      } else {
        await requestOtp(
          getCompletePhoneNumber(String(phone?.code), phone?.phone),
          otpProviders[0],
          OtpType.LOGIN_OTP
        )
          .then(() => {
            setAuthOtpRequest(values.phone);
            navigate(`/auth/verification-code?type=${OtpType.LOGIN_OTP}`);
          })
          .catch((err) => {
            setPhoneLoginError(
              _.get(err, "response.data.fault.message") ??
                _.get(err, "response.data.message") ??
                t("notification.error.default")
            );
          });
      }
    },
    [isLoginWithPassword, login, navigate, requestOtp, setAuthOtpRequest, t]
  );

  const handleGoogleLogin = useCallback(async () => {
    window.location.href = `${endPoint()}/api/auth/google`;
  }, []);

  const handleFacebookLogin = useCallback(() => {
    window.location.href = `${endPoint()}/api/auth/facebook`;
  }, []);

  useEffect(() => {
    if (authOtpRequest) {
      localStorage.removeItem(localStorageKey.authOtpRequest);
    }

    if (verifyPhoneData) {
      setVerifyPhoneData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoginWithPhone = () => {
    return (
      <Tabs.TabPane tab={t("common.text.phone")} key="phone">
        <VuiFormLayout
          form={phoneForm}
          onFinish={onPhoneLogin}
          onValuesChange={() => {
            setPhoneLoginError(undefined);
          }}
        >
          <VuiFormItem
            initialValue={{
              code: DEFAULT_PHONE_COUNTRY_CODE,
            }}
            rules={[
              {
                validator: (_, value) => checkIsValidPhone(t, value),
              },
            ]}
            name={"phone"}
            style={{
              marginBottom: isLoginWithPassword ? 24 : 10,
            }}
            label={t("common.form.phone.label")}
          >
            <PhoneInput placeholder={t("common.form.phone.placeholder")} />
          </VuiFormItem>

          {isLoginWithPassword && (
            <VuiFormItem
              className={clsx("phone-password-phone", {
                show: isLoginWithPassword,
              })}
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.password.label"),
                  }),
                },
              ]}
              style={{ marginBottom: 10 }}
              name="password"
              label={t("common.form.password.label")}
            >
              <Input.Password
                placeholder={t("common.form.password.placeholder")}
              />
            </VuiFormItem>
          )}

          {phoneLoginError && (
            <div style={{ marginBottom: 24 }}>
              <Typography.Text type="danger">{phoneLoginError}</Typography.Text>
            </div>
          )}
          <Row
            justify="space-between"
            style={{ marginBottom: isLoginWithPassword ? 9 : 24 }}
          >
            <Col>
              <Text
                className="link-text font-bold"
                onClick={() => {
                  setIsLoginWithPassword((s) => !s);
                  setPhoneLoginError(undefined);
                }}
              >
                {isLoginWithPassword
                  ? t("common.text.loginWithOTP")
                  : t("common.text.loginWithPassword")}
              </Text>
            </Col>
            <Col>
              {isLoginWithPassword && (
                <Paragraph
                  className="cursor-pointer forgot-password-action"
                  onClick={() => navigate("/auth/forgot-password")}
                >
                  {t("common.text.forgotPasswordMark")}
                </Paragraph>
              )}
            </Col>
          </Row>

          <VuiButton
            loading={isOnSignIn || isOnRequestOtp}
            label={
              isLoginWithPassword
                ? t("common.button.logIn")
                : t("common.button.loginWithOTP")
            }
            buttonProps={{
              htmlType: "submit",
              type: "primary",
              disabled: !(_.get(phone, "phone.length") > 0),
            }}
          />
          <Paragraph className="login-with-text">
            {t("common.text.orLoginWith")}
          </Paragraph>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <VuiButton
                label={t("common.button.facebook")}
                buttonProps={{
                  icon: <FacebookCircleIcon />,
                  onClick: handleFacebookLogin,
                }}
              />
            </Col>
            <Col md={12} xs={24}>
              <VuiButton
                label={t("common.button.google")}
                buttonProps={{
                  icon: <GoogleIcon />,
                  onClick: handleGoogleLogin,
                }}
              />
            </Col>
          </Row>
          <Divider />
          <Paragraph className="login-with-text large">
            {t("common.text.dontHaveAnAccountMark")}
          </Paragraph>
          <VuiButton
            buttonProps={{
              onClick: () => navigate("/auth/register"),
            }}
            label={t("common.button.registerNow")}
          />
        </VuiFormLayout>
      </Tabs.TabPane>
    );
  };

  const renderLoginWithEmail = () => {
    return (
      <Tabs.TabPane tab={t("common.text.email")} key="email">
        <Space className="flex" direction="vertical" size={20}>
          {verifyPhoneData && (
            <Alert
              className="alert-warning"
              type="warning"
              message={<b>{t("common.text.pleaseVerifyYourPhoneNumber")}</b>}
              showIcon
              icon={<InfoCircleOutlined />}
              action={
                !isOnVerifyPhone ? (
                  <Typography.Text
                    strong
                    className="link-text"
                    onClick={onVerifyPhone}
                  >
                    {t("common.button.verifyNow")}
                  </Typography.Text>
                ) : (
                  <Spin spinning={isOnVerifyPhone} />
                )
              }
            />
          )}
          <VuiFormLayout
            form={emailForm}
            onFinish={onEmailLogin}
            onValuesChange={() => {
              setEmailLoginError(undefined);
            }}
          >
            <VuiFormItem
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.email.label"),
                  }),
                },
                {
                  type: "email",
                  message: t("validation.type.email", {
                    item: t("common.form.email.label"),
                  }),
                },
              ]}
              name={"username"}
              label={t("common.form.email.label")}
            >
              <Input placeholder={t("common.form.email.placeholder")} />
            </VuiFormItem>
            <VuiFormItem
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.password.label"),
                  }),
                },
              ]}
              style={{ marginBottom: 10 }}
              name="password"
              label={t("common.form.password.label")}
            >
              <Input.Password
                placeholder={t("common.form.password.placeholder")}
              />
            </VuiFormItem>
            {emailLoginError && (
              <Typography.Text type="danger" style={{ marginBottom: 24 }}>
                {emailLoginError}
              </Typography.Text>
            )}
            <Paragraph
              className="cursor-pointer forgot-password-action"
              style={{ textAlign: "right", marginBottom: 24 }}
              onClick={() => navigate("/auth/forgot-password")}
            >
              {t("common.text.forgotPasswordMark")}
            </Paragraph>
            <VuiButton
              loading={isOnSignIn}
              label={t("common.button.logIn")}
              buttonProps={{
                htmlType: "submit",
                type: "primary",
              }}
            />
            <Paragraph className="login-with-text">
              {t("common.text.orLoginWith")}
            </Paragraph>
            <Row gutter={[16, 16]}>
              <Col md={12} xs={24}>
                <VuiButton
                  label={t("common.button.facebook")}
                  buttonProps={{
                    icon: <FacebookCircleIcon />,
                    onClick: handleFacebookLogin,
                  }}
                />
              </Col>
              <Col md={12} xs={24}>
                <VuiButton
                  label={t("common.button.google")}
                  buttonProps={{
                    icon: <GoogleIcon />,
                    onClick: handleGoogleLogin,
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Paragraph className="login-with-text large">
              {t("common.text.dontHaveAnAccountMark")}
            </Paragraph>
            <VuiButton
              buttonProps={{
                onClick: () => navigate("/auth/register"),
              }}
              label={t("common.button.registerNow")}
            />
          </VuiFormLayout>
        </Space>
      </Tabs.TabPane>
    );
  };

  return (
    <div id="auth-login-page">
      <VuiHelmet title={t("common.text.login")} />

      <VuiFormCard className="auth-login-wrapper">
        <h4 className="auth-login-title">{t("common.text.login")}</h4>
        <Tabs className="login-tab">
          {renderLoginWithPhone()}
          {renderLoginWithEmail()}
        </Tabs>
      </VuiFormCard>
    </div>
  );
};

export default AuthLoginPage;

import "./_style.less";
import { BookingType } from "../../../../types/booking";
import { AssessmentIcon, CounselingIcon } from "../../../icons";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

interface BookingTagProps {
  type?: BookingType;
  isPackage?: boolean;
  title?: string;
  showPackage?: boolean;
}

const BookingTag = ({
  type = "COUNSELING",
  isPackage,
  title,
  showPackage = true,
}: BookingTagProps) => {
  const { t } = useTranslation();
  return (
    <div className="booking-tag">
      {type === "ASSESSMENT" ? <AssessmentIcon /> : <CounselingIcon />}
      <Typography.Text className="booking-tag-title">
        {title ?? t(`common.text.${type.toLowerCase()}`)}
      </Typography.Text>

      {type === "COUNSELING" && showPackage && (
        <div
          className={clsx("counseling-tag", {
            package: isPackage,
          })}
        >
          {t(`common.text.${isPackage ? "package" : "single"}`)}
        </div>
      )}
    </div>
  );
};

export default BookingTag;

import "./style.less";
import { Avatar, Card, Divider, Form, List, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";
import {
  checkIsValidPhone,
  getCompletePhoneNumber,
  IAuthRegisterForm,
  OtpType,
  useDebounce,
  usePassportService,
} from "../../../@vendor/utils";
import {
  VuiButton,
  VuiFormCard,
  VuiFormItem,
  VuiFormLayout,
  VuiHelmet,
} from "../../../@vendor/components";
import {
  FacebookCircleIcon,
  GoogleIcon,
  TermsConditionRegisterModal,
} from "../../../components";
import PhoneInput from "../../../@vendor/components/atoms/PhoneInput";
import {
  DEFAULT_PHONE_COUNTRY_CODE, errorFaultCode,
  localStorageKey,
} from "../../../constants";
import { IFormCustomValidation } from "../../../@vendor/utils/interfaces/form.interface";
import _ from "lodash";
import { CountryPhoneInputValue } from "antd-country-phone-input";
import { useLocalStorage } from "usehooks-ts";
import CheckIcon from "../../../components/icons/Check";
import { ValidateStatus } from "antd/lib/form/FormItem";
import { LoadingOutlined } from "@ant-design/icons";
import DangerIcon from "../../../components/icons/Danger";
import AuthRepository from "../../../repositories/AuthRepository";
import { otpProviders } from "../../../constants/auth.constant";

const AuthRegisterPhonePage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { requestOtp, isOnRequestOtp } = usePassportService(true);
  const [form] = Form.useForm<IAuthRegisterForm>();
  const [showTermsConditionModal, setShowTermsConditionModal] =
    useState<boolean>(false);
  const [requestOtpError, setReqeustOtpError] = useState<string>();

  const [acceptAgreement, setAcceptAgreement] = useState<boolean>(false);
  const phone = Form.useWatch("phone_number", form) as CountryPhoneInputValue;

  const [phoneValidation, setPhoneValidation] = useState<IFormCustomValidation>(
    {
      validateStatus: undefined,
      errorMessage: undefined,
    }
  );
  const debouncePhone = useDebounce<CountryPhoneInputValue>(phone, 500);
  const [registerFormData, setRegisterFormData] = useLocalStorage<
    IAuthRegisterForm | undefined
  >(localStorageKey.authRegisterForm, undefined);

  const handleAgreement = useCallback(() => {
    setShowTermsConditionModal(false);
    setAcceptAgreement(true);
    form.submit();
  }, [form]);

  const isValidPhone = useCallback((phoneValue: CountryPhoneInputValue) => {
    const phoneLength = _.get(phoneValue, "phone.length", 0);

    return phoneLength >= 9 && phoneLength <= 15;
  }, []);

  const onFinish = useCallback(
    async (values: IAuthRegisterForm) => {
      if (!acceptAgreement) {
        setShowTermsConditionModal(true);
        return;
      }

      await requestOtp(
        getCompletePhoneNumber(
          String(values?.phone_number?.code),
          values?.phone_number?.phone
        ),
        otpProviders[0],
        OtpType.REGISTER_OTP
      )
        .then(() => {
          if (registerFormData) {
            setRegisterFormData({
              ...registerFormData,
              phone_number: values.phone_number,
            });
            navigate(`/auth/verification-code?type=${OtpType.REGISTER_OTP}`);
          }
        })
        .catch((err) => {
          const faultCode = _.get(err, "response.data.fault.code");
          if (faultCode === errorFaultCode.otpWaThrottled) {
            setReqeustOtpError(t("notification.error.otpThrottled"));
          } else {
            setReqeustOtpError(t("notification.error.default"));
          }
        });
    },
    [
      acceptAgreement,
      requestOtp,
      registerFormData,
      setRegisterFormData,
      navigate,
      t,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setShowTermsConditionModal(false);
  }, []);

  const handleValidatePhone = useCallback(async () => {
    setPhoneValidation((s) => ({
      ...s,
      validateStatus: "validating",
      errorMessage: undefined,
    }));

    await AuthRepository.checkPhone({
      phone_number: `${String(phone.code)} ${phone.phone}`,
    })
      .then(() => {
        if (isValidPhone(phone)) {
          setPhoneValidation((s) => ({
            ...s,
            validateStatus: "success",
          }));
        } else {
          setPhoneValidation((s) => ({
            ...s,
            validateStatus: undefined,
            errorMessage: undefined,
          }));
        }
      })
      .catch((error) => {
        if (error.name !== "CanceledError") {
          setPhoneValidation((s) => ({
            ...s,
            validateStatus: "error",
            errorMessage: _.get(error, "response.data.fault.message"),
          }));
        }
      });
  }, [isValidPhone, phone]);

  const navigateToLogin = () => {
    localStorage.removeItem(localStorageKey.authRegisterForm);
    navigate("/auth/login");
  };

  useEffect(() => {
    if (isValidPhone(debouncePhone)) {
      handleValidatePhone();
    } else {
      setPhoneValidation((s) => ({
        ...s,
        validateStatus: undefined,
        errorMessage: undefined,
      }));
    }
  }, [debouncePhone, handleValidatePhone, isValidPhone]);

  useEffect(() => {
    if (!registerFormData?.provider_id) {
      navigate("/auth/register");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderValidationStatusIcon = (validationStatus?: ValidateStatus) => {
    if (validationStatus === "validating") {
      return <LoadingOutlined />;
    }

    if (validationStatus === "success") {
      return <CheckIcon />;
    }

    if (validationStatus === "error") {
      return <DangerIcon />;
    }

    return <div />;
  };

  return (
    <div id="auth-register-phone-page">
      <VuiHelmet title={t("common.text.register")} />

      <TermsConditionRegisterModal
        title={t("common.text.terms&condition")}
        visible={showTermsConditionModal}
        onSubmit={handleAgreement}
        onClose={handleCloseModal}
      />

      <VuiFormLayout form={form} onFinish={onFinish}>
        <VuiFormCard className="auth-register-wrapper">
          <h4 className="auth-register-title">{t("common.text.register")}</h4>
          <Card className="auth-register-user-info-card">
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar size={28}>
                    {_.get(registerFormData, "name", "")
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                }
                title={_.get(registerFormData, "name", "")}
                description={_.get(registerFormData, "email", "")}
              />

              {registerFormData?.provider === "google" && (
                <GoogleIcon width={30} height={30} />
              )}
              {registerFormData?.provider === "facebook" && (
                <FacebookCircleIcon width={40} height={40} />
              )}
            </List.Item>
          </Card>
          <VuiFormItem
            initialValue={{
              code: DEFAULT_PHONE_COUNTRY_CODE,
            }}
            rules={[
              {
                validator: (_, value) => checkIsValidPhone(t, value),
              },
            ]}
            name={"phone_number"}
            label={t("common.form.phone.label")}
            validateStatus={phoneValidation.validateStatus}
            help={phoneValidation.errorMessage}
          >
            <PhoneInput
              placeholder={t("common.form.phone.placeholder")}
              suffix={renderValidationStatusIcon(
                phoneValidation.validateStatus
              )}
            />
          </VuiFormItem>

          {!!requestOtpError && (
            <div style={{ marginBottom: 24 }}>
              <Typography.Text type="danger">{requestOtpError}</Typography.Text>
            </div>
          )}

          <VuiButton
            label={t("common.button.register")}
            loading={isOnRequestOtp}
            buttonProps={{
              htmlType: "submit",
              type: "primary",
              disabled: phoneValidation.validateStatus !== "success",
            }}
          />

          <Divider />

          <Paragraph className="register-with-text large">
            {t("common.text.alreadyHaveAnAccountMark")}
          </Paragraph>

          <VuiButton
            buttonProps={{
              onClick: navigateToLogin,
            }}
            label={t("common.button.login")}
          />
        </VuiFormCard>
      </VuiFormLayout>
    </div>
  );
};

export default AuthRegisterPhonePage;

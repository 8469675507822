import { VuiFormItem } from "../../../../@vendor/components";
import { Input } from "antd";
import { ICounselingForm } from "../interface";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";

const CounselingFormMultipleShortAnswer = ({ question }: ICounselingForm) => {
  const { t } = useTranslation();

  return (
    <>
      <Paragraph style={{ fontWeight: "bold", fontSize: 15 }}>
        {question.question}{" "}
        <span style={{ color: "#888E95", fontWeight: 400 }}>
          {question.is_required ? "" : `(${t("common.text.optional")})`}
        </span>
      </Paragraph>

      {question.answers.map((answer, index) => (
        <VuiFormItem
          rules={[
            {
              required: question.is_required,
              message: t("validation.required", {
                item: answer,
              }),
            },
          ]}
          name={[String(question.id), index]}
          label={answer as string}
          key={`${question.id}_${index}_answer`}
          style={{
            marginBottom: index + 1 === question.answers.length ? 0 : 12,
          }}
        >
          <Input />
        </VuiFormItem>
      ))}
    </>
  );
};

export default CounselingFormMultipleShortAnswer;

import "./_style.less";
import Paragraph from "antd/lib/typography/Paragraph";
import { CSSProperties, FC, ReactNode } from "react";
import clsx from "clsx";

type TextWeight = "bold" | "normal";

interface IFormText {
  label: string;
  value: ReactNode;
  valueWeightType?: TextWeight;
  labelWeightType?: TextWeight;
  style?: CSSProperties;
}

const VuiFormText: FC<IFormText> = ({
  label,
  value = null,
  valueWeightType = "normal",
  labelWeightType = "normal",
  style,
}) => {
  return (
    <div className="vui-form-text-wrapper" style={style}>
      <Paragraph
        className={clsx({
          "vui-form-text-label": true,
          "text-bold": labelWeightType === "bold",
        })}
      >
        {label}
      </Paragraph>
      <Paragraph
        className={clsx({
          "vui-form-text-value": true,
          "text-bold": valueWeightType === "bold",
        })}
      >
        {value || "-"}
      </Paragraph>
    </div>
  );
};

export default VuiFormText;

import "./_style.less";

import {
  VuiFormCard,
  VuiSecondaryTitle,
  VuiSpin,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import NoHistory from "./NoHistory";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HistoryCard } from "../../../atoms";
import { HistoryDetailModal, SessionDetailModal } from "../../modals";
import AccountRepository from "../../../../repositories/AccountRepository";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import { History } from "../../../../models/History";
import { BookingType } from "../../../../types/booking";
import moment from "moment";
import { Session } from "../../modals/SessionDetailModal";
import { Dropdown, Space, Menu, Checkbox } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ConstantRepository, {
  IConstantRepository,
} from "../../../../repositories/ConstantRepository";
import _ from "lodash";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

interface AccountHistoryProps {
  type: BookingType;
}

const AccountHistory = ({ type = "COUNSELING" }: AccountHistoryProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [data, setData] = useState<any[]>([]);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<History | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSessionDetail, setShowSessionDetail] = useState<History>();
  const [statusOptions, setStatusOptions] = useState<IConstantRepository[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<CheckboxValueType[]>([]);
  const [openStatusSelect, setOpenStatusSelect] = useState(false);
  const [showReport, setShowReport] = useState<History>();
  const [showInstruction, setShowInstruction] = useState<History>();

  const statusFilterTitle = useMemo(() => {
    if (selectedStatus.length === 1) {
      const status = statusOptions.find(
        (item) => item.id === selectedStatus[0]
      );

      return status?.label;
    }

    return "All Status";
  }, [selectedStatus, statusOptions]);

  const loadStatusOptions = useCallback(async () => {
    try {
      const constantRes = await ConstantRepository.select({
        for: "history_status",
      });

      if (isMounted) {
        setStatusOptions(constantRes.data.data);
      }
    } catch (err: any) {
      if (isMounted) {
        handleErrorLoadDataResponse(t, t("common.text.status"), err);
      }
    }
  }, [isMounted, t]);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    try {
      const historyRes = await AccountRepository.history({
        with: ["customer", "children.schedule", "reports.file"],
        session_type: type,
        history_status_id:
          selectedStatus.length === 1
            ? (selectedStatus[0] as number)
            : undefined,
      });

      if (isMounted) {
        setData(historyRes.data.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      if (isMounted) {
        handleErrorLoadDataResponse(t, t("common.text.counseling"), err);
        setIsLoading(false);
      }
    }
  }, [isMounted, selectedStatus, t, type]);

  const handleCloseDetail = useCallback(() => {
    setHistoryData(null);
    setShowDetail(false);
  }, []);

  const handleClickDetail = useCallback((item: History) => {
    setHistoryData(item);
    setShowDetail(true);
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  useEffect(() => {
    loadStatusOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderData = useMemo(() => {
    if (!data.length) {
      return (
        <VuiFormCard
          bodyStyle={{ padding: "5px 0" }}
          style={{ marginBottom: 24 }}
        >
          {isLoading ? (
            <div style={{ height: 250 }} />
          ) : (
            <NoHistory
              title={
                type === "COUNSELING"
                  ? t("common.text.dontHaveCounseling")
                  : t("common.text.dontHaveAssessment")
              }
              bookHref={
                type === "ASSESSMENT" ? "/book-assessment" : "/book-counseling"
              }
            />
          )}
        </VuiFormCard>
      );
    }

    return data.map((item) => (
      <HistoryCard
        key={item.id}
        onClick={() => handleClickDetail(item)}
        data={item}
        onShowAllSession={(val) => setShowSessionDetail(val)}
        onShowInstruction={(val) => setShowInstruction(val)}
        onShowReport={(val) => setShowReport(val)}
      />
    ));
  }, [data, handleClickDetail, t, type, isLoading]);

  return (
    <div id="account-history-body">
      <HistoryDetailModal
        visible={showDetail}
        onClose={handleCloseDetail}
        data={historyData}
      />
      <HistoryDetailModal
        visible={!!showReport}
        onClose={() => setShowReport(undefined)}
        data={showReport ?? null}
        reportOnly
      />
      <HistoryDetailModal
        visible={!!showInstruction}
        onClose={() => setShowInstruction(undefined)}
        data={showInstruction ?? null}
        instructionOnly
      />
      <SessionDetailModal
        title={t("common.text.otherSessions")}
        visible={!!showSessionDetail}
        onClose={() => setShowSessionDetail(undefined)}
        data={
          (showSessionDetail?.children.map((item) => ({
            date: item.schedule?.date,
            time: moment(item.schedule?.time_from, "HH:mm:ss").toISOString(),
            duration: item.duration,
          })) as Session[]) ?? []
        }
      />

      <div className="account-history-title-section">
        <VuiSecondaryTitle
          text={
            type === "COUNSELING"
              ? t("common.text.myCounseling")
              : t("common.text.myAssessmentAndPrograms")
          }
        />

        {statusOptions.length > 0 && (
          <Dropdown
            trigger={["click"]}
            onVisibleChange={(visible) => setOpenStatusSelect(visible)}
            placement="bottomRight"
            overlay={
              <Menu
                className="account-history-status-select-dropdown-menu"
                onClick={(e) => e.domEvent.stopPropagation()}
              >
                <Checkbox.Group onChange={(e) => setSelectedStatus(e)}>
                  {statusOptions.map((item) => {
                    return (
                      <Menu.Item key={item.id}>
                        <Checkbox value={item.id}>
                          {_.capitalize(item.label)}
                        </Checkbox>
                      </Menu.Item>
                    );
                  })}
                </Checkbox.Group>
              </Menu>
            }
          >
            <div
              className="history-filter cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                <b>{statusFilterTitle}</b>
                {openStatusSelect ? <UpOutlined /> : <DownOutlined />}
              </Space>
            </div>
          </Dropdown>
        )}
      </div>

      <VuiSpin spinning={isLoading} size="large">
        {renderData}
      </VuiSpin>
    </div>
  );
};

export default AccountHistory;

import { useCallback, useEffect, useState } from "react";
import { IFormCustomValidation } from "../interfaces/form.interface";
import { CountryPhoneInputValue } from "antd-country-phone-input";
import { useDebounce } from "usehooks-ts";
import _ from "lodash";
import AuthRepository from "../../../repositories/AuthRepository";
import { useAppSelector } from "../../../stores/hooks";
import CheckPhoneRegisteredRepository from "../../../repositories/CheckPhoneRegisteredRepository";
import { useTranslation } from "react-i18next";

const useRegisterPhoneValidation = (phone: CountryPhoneInputValue) => {
  const { isLoggedIn } = useAppSelector((state) => state.system);
  const { t } = useTranslation();

  const [phoneValidation, setPhoneValidation] = useState<IFormCustomValidation>(
    {
      validateStatus: undefined,
      errorMessage: undefined,
    }
  );

  const debouncePhone = useDebounce<CountryPhoneInputValue>(phone, 500);

  const isValidPhone = useCallback((phoneValue: CountryPhoneInputValue) => {
    const phoneLength = _.get(phoneValue, "phone.length", 0);

    return phoneLength >= 9 && phoneLength <= 15;
  }, []);

  const handleValidatePhone = useCallback(async () => {
    setPhoneValidation((s) => ({
      ...s,
      validateStatus: "validating",
      errorMessage: undefined,
    }));

    await (isLoggedIn
      ? CheckPhoneRegisteredRepository.checkPhone({
          phone_number: `${String(phone.code)} ${phone.phone}`,
        })
      : AuthRepository.checkPhone({
          phone_number: `${String(phone.code)} ${phone.phone}`,
        })
    )
      .then(() => {
        if (isValidPhone(phone)) {
          setPhoneValidation((s) => ({
            ...s,
            validateStatus: "success",
          }));
        } else {
          setPhoneValidation((s) => ({
            ...s,
            validateStatus: undefined,
            errorMessage: undefined,
          }));
        }
      })
      .catch((error) => {
        if (error.name !== "CanceledError") {
          setPhoneValidation((s) => ({
            ...s,
            validateStatus: "error",
            errorMessage:
              _.get(error, "response.data.fault.message") ??
              t("notification.error.default"),
          }));
        }
      });
  }, [isLoggedIn, isValidPhone, phone, t]);

  useEffect(() => {
    if (isValidPhone(debouncePhone)) {
      handleValidatePhone();
    } else {
      setPhoneValidation((s) => ({
        ...s,
        validateStatus: undefined,
        errorMessage: undefined,
      }));
    }
  }, [debouncePhone, handleValidatePhone, isValidPhone]);

  return { phoneValidation };
};

export default useRegisterPhoneValidation;

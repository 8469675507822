const FacebookCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4997 2.0835C8.40004 2.0835 6.38641 2.91757 4.90175 4.40223C3.41708 5.8869 2.58301 7.90053 2.58301 10.0002C2.58301 12.0998 3.41708 14.1134 4.90175 15.5981C6.38641 17.0828 8.40004 17.9168 10.4997 17.9168C12.5993 17.9168 14.6129 17.0828 16.0976 15.5981C17.5823 14.1134 18.4163 12.0998 18.4163 10.0002C18.4163 7.90053 17.5823 5.8869 16.0976 4.40223C14.6129 2.91757 12.5993 2.0835 10.4997 2.0835Z"
      fill="#039BE5"
    />
    <path
      d="M11.572 12.0986H13.6208L13.9424 10.0173H11.5716V8.87982C11.5716 8.01523 11.8541 7.24857 12.6628 7.24857H13.9624V5.43232C13.7341 5.40148 13.2512 5.33398 12.3387 5.33398C10.4333 5.33398 9.31617 6.34023 9.31617 8.63273V10.0173H7.35742V12.0986H9.31617V17.819C9.70409 17.8773 10.097 17.9169 10.5003 17.9169C10.8649 17.9169 11.2208 17.8836 11.572 17.8361V12.0986Z"
      fill="white"
    />
  </svg>
);

export default FacebookCircleIcon;

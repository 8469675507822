import "./_style.less";
import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Radio, Skeleton, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  formatDayDate,
  getTimePeriod,
  sortScheduleTimeOptions,
} from "../../../@vendor/utils";
import { Schedule, ScheduleTime } from "../../../models/Schedule";
import { DownOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

export interface BookingSessionItemProps {
  timeOptions?: Schedule["times"];
  value?: any;
  onTimeSelect: (val: ScheduleTime) => void;
  onReset: () => void;
  selectedDate?: string;
  session: number;
  isAssessment?: boolean;
  sessionMinDays?: number;
  duration?: number;
  isLoading?: boolean;
}

const BookingSessionItem: React.FC<BookingSessionItemProps> = ({
  timeOptions,
  value,
  onTimeSelect,
  onReset,
  selectedDate,
  session,
  isAssessment,
  sessionMinDays = 0,
  duration,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<string[]>([]);

  useEffect(() => {
    if (selectedDate) {
      setOpen(["1"]);
    } else {
      setOpen([]);
    }
  }, [selectedDate]);

  const showSelectTimeWarning = useMemo(() => {
    return selectedDate && !value?.value;
  }, [selectedDate, value?.value]);

  return (
    <Collapse
      activeKey={open}
      className="booking-session-item"
      expandIconPosition="right"
      ghost
      collapsible={selectedDate ? "header" : "disabled"}
      expandIcon={({ isActive }) => (
        <DownOutlined className="cursor-pointer" rotate={isActive ? 180 : 0} />
      )}
      onChange={() => setOpen(() => (open.length > 0 ? [] : ["1"]))}
    >
      <Panel
        header={
          <Space direction="vertical">
            <div>
              <Typography.Text>
                <Typography.Text
                  type={showSelectTimeWarning ? "danger" : undefined}
                >
                  {" "}
                  <b>Session {session}</b>{" "}
                </Typography.Text>

                {isAssessment && sessionMinDays > 0 && (
                  <i className="session-interval-time" style={{ fontSize: 11 }}>
                    The time interval for the next session is{" "}
                    {getTimePeriod(sessionMinDays)}
                  </i>
                )}
              </Typography.Text>
            </div>
            {selectedDate ? (
              <>
                <Typography.Text>
                  {formatDayDate(selectedDate || "", "dddd, DD MMMM YYYY")}
                  {value?.value && `, ${value?.label}`}{" "}
                  {duration
                    ? `| ${t("common.text.minutesItem", { item: duration })} `
                    : ""}
                  {showSelectTimeWarning && (
                    <Typography.Text type="danger">
                      {"  "}
                      {t("common.text.pleaseChooseTheTime")}
                    </Typography.Text>
                  )}
                </Typography.Text>
              </>
            ) : (
              <Typography.Text>
                {t("common.text.notSelectedYet")}
              </Typography.Text>
            )}
          </Space>
        }
        key="1"
        extra={
          selectedDate ? (
            <button
              className="plain-btn text-secondary-color"
              style={{ fontSize: 13 }}
              onClick={onReset}
            >
              <b>{t("common.button.reset")}</b>
            </button>
          ) : null
        }
      >
        <Space direction="vertical" size={20} style={{ width: "100%" }}>
          {isLoading ? (
            <div>
              <Skeleton.Button active block />
            </div>
          ) : (
            <div>
              <Radio.Group value={value?.value} className="booking-time-select">
                {sortScheduleTimeOptions(timeOptions as ScheduleTime[])?.map(
                  (item) => {
                    const time = item as ScheduleTime;
                    return (
                      <Radio.Button
                        key={time.id}
                        value={time?.id}
                        disabled={
                          isAssessment
                            ? !time.is_available
                            : !time.is_available_in_sixty_minutes
                        }
                        onClick={() => onTimeSelect(time as ScheduleTime)}
                      >
                        {time.label}
                      </Radio.Button>
                    );
                  }
                )}
              </Radio.Group>
            </div>
          )}
        </Space>
      </Panel>
    </Collapse>
  );
};

export default BookingSessionItem;

import "./style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import { VuiButton, VuiFormCard, VuiHelmet } from "../../../@vendor/components";
import AuthRepository from "../../../repositories/AuthRepository";

const AuthVerificationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const isSuccess = url.searchParams.get("is_success");
  const token = url.searchParams.get("token");

  useMemo(() => {
    if (isSuccess && token) {
      AuthRepository.registerVerify({
        token: token,
      }).then(() => {});
    }
  }, [isSuccess, token]);

  return (
    <div id="auth-verification-page">
      <VuiHelmet title={t("common.text.verification")} />

      <VuiFormCard className="auth-verification-wrapper">
        <h4 className="auth-verification-title">
          {t("common.text.thanksForVerification")}
        </h4>

        <Paragraph style={{ marginBottom: 12 }}>
          {t("auth.verification.description")}
        </Paragraph>

        <VuiButton
          label={t("common.button.login")}
          buttonProps={{
            type: "primary",
            onClick: () => navigate("/auth/login"),
          }}
        />
      </VuiFormCard>
    </div>
  );
};

export default AuthVerificationPage;

import "./_style.less";
import { Calendar, Divider, Space, Typography } from "antd";
import type { Moment } from "moment";
import { FC, useCallback, useState } from "react";
import { CalendarProps } from "antd/lib/calendar/generateCalendar";
import { Schedule } from "../../../../models/Schedule";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

interface IVuiCalendarReschedule {
  onSelectDate?: (date: Moment) => void;
  data?: Schedule[];
  defaultValue?: any;
  selectedDates?: string[];
}

const VuiCalendarReschedule: FC<
  IVuiCalendarReschedule & CalendarProps<any>
> = ({
  onSelectDate = null,
  data = [],
  defaultValue,
  selectedDates,
  ...props
}) => {
  const [calendarValue, setCalendarValue] = useState<Moment>();

  const getListData = useCallback(
    (value: Moment, schedules: Schedule[]) => {
      const listData: Schedule[] = [];
      schedules?.forEach((schedule) => {
        if (
          schedule.date === value.format("YYYY-MM-DD") &&
          !selectedDates?.includes(schedule.date)
        ) {
          listData.push(schedule);
        }
      });
      return listData;
    },
    [selectedDates]
  );

  const dateCellRender = useCallback(
    (value: Moment) => {
      const listData: Schedule[] = getListData(value, data);

      return (
        <div className={"event-date"}>
          {listData.map((item) => (
            <div key={item.id} className="available-schedule" />
          ))}

          {selectedDates?.includes(value.format("YYYY-MM-DD")) && (
            <div className="selected-date">{value.format("DD")}</div>
          )}
        </div>
      );
    },
    [data, getListData, selectedDates]
  );

  return (
    <Calendar
      {...props}
      value={calendarValue}
      fullscreen={false}
      className="vui-calendar-reschedule"
      headerRender={({ value, type, onChange }) => {
        const localeData = value.localeData();

        const month = value.month();

        const year = value.year();

        return (
          <>
            <div className="ant-picker-calendar-header">
              <Space direction="vertical" style={{ width: "100%" }}>
                <div className="ant-picker-calendar-header-content">
                  <button
                    className="plain-btn"
                    onClick={() => {
                      const newValue = value.clone();

                      newValue.subtract(1, "month");

                      setCalendarValue(newValue);
                    }}
                  >
                    <LeftOutlined />
                  </button>
                  <Typography.Text>
                    <b>
                      {localeData.months()[month]} {year}
                    </b>
                  </Typography.Text>
                  <button
                    className="plain-btn"
                    onClick={() => {
                      const newValue = value.clone();

                      newValue.add(1, "month");

                      setCalendarValue(newValue);
                    }}
                  >
                    <RightOutlined />
                  </button>
                </div>
                <Divider />
              </Space>
            </div>
          </>
        );
      }}
      dateCellRender={dateCellRender}
      onSelect={(date) => {
        if (onSelectDate) {
          setCalendarValue(date);
          onSelectDate(date);
        }
      }}
    />
  );
};

export default VuiCalendarReschedule;

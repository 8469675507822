import { api, ConfigService, ISelectRepository } from "../@vendor/utils";
import { Expertise } from "../models/Expertise";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends ISelectRepository<T> {}

const Repository: IRepository<Expertise> = {
  select: function (params) {
    return api.get(`${endPoint()}/api/select/expertise`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;

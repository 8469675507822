const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7433 3.48849C19.5452 3.49317 19.357 3.57602 19.2198 3.71896L12.0001 10.9387L4.78039 3.71896C4.71048 3.64697 4.62684 3.58973 4.53441 3.55065C4.44198 3.51156 4.34265 3.49142 4.2423 3.49142C4.09304 3.49146 3.94719 3.53602 3.8234 3.61942C3.69961 3.70282 3.60352 3.82125 3.54741 3.95957C3.49131 4.09788 3.47775 4.24979 3.50847 4.39586C3.53918 4.54192 3.61278 4.6755 3.71984 4.77951L10.9396 11.9992L3.71984 19.219C3.64786 19.2881 3.5904 19.3708 3.55081 19.4624C3.51122 19.554 3.49031 19.6526 3.4893 19.7524C3.48828 19.8522 3.50719 19.9511 3.5449 20.0435C3.58262 20.1359 3.63839 20.2198 3.70895 20.2904C3.77951 20.361 3.86344 20.4167 3.95582 20.4544C4.0482 20.4922 4.14718 20.5111 4.24696 20.51C4.34674 20.509 4.44531 20.4881 4.53691 20.4485C4.6285 20.4089 4.71128 20.3515 4.78039 20.2795L12.0001 13.0598L19.2198 20.2795C19.2889 20.3515 19.3717 20.4089 19.4633 20.4485C19.5549 20.4881 19.6535 20.509 19.7533 20.51C19.8531 20.5111 19.952 20.4922 20.0444 20.4544C20.1368 20.4167 20.2207 20.361 20.2913 20.2904C20.3618 20.2198 20.4176 20.1359 20.4553 20.0435C20.493 19.9511 20.5119 19.8522 20.5109 19.7524C20.5099 19.6526 20.489 19.554 20.4494 19.4624C20.4098 19.3708 20.3524 19.2881 20.2804 19.219L13.0607 11.9992L20.2804 4.77951C20.3895 4.67483 20.4643 4.53964 20.4952 4.39165C20.5261 4.24367 20.5116 4.08981 20.4535 3.95024C20.3955 3.81066 20.2966 3.69189 20.1698 3.6095C20.0431 3.5271 19.8944 3.48492 19.7433 3.48849Z"
      fill="#232D3B"
    />
  </svg>
);

export default CloseIcon;

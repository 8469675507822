const GoogleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5982 2.00912C7.10367 2.21395 3.65978 5.95028 3.65978 10.2743V14.1841L2.18611 17.0527C2.18352 17.0579 2.18099 17.0632 2.17851 17.0684C1.58566 18.2883 2.54536 19.7531 3.93887 19.7531H8.66393C8.66393 21.5262 10.1675 22.981 12 22.981C13.8326 22.981 15.3361 21.5262 15.3361 19.7531H20.0601C21.4536 19.7531 22.4146 18.2886 21.8215 17.0684C21.8191 17.0632 21.8165 17.0579 21.8139 17.0527L20.3403 14.1841V10.0694C20.3403 5.49322 16.3722 1.79166 11.5982 2.00912ZM11.6764 3.62096C15.5241 3.4457 18.6722 6.39079 18.6722 10.0694V14.3733C18.6721 14.4979 18.7019 14.621 18.7591 14.7326L20.312 17.7567C20.4119 17.9638 20.2964 18.1392 20.0601 18.1392H3.93887C3.7026 18.1392 3.588 17.9641 3.68801 17.7567V17.7556L5.24095 14.7326C5.29821 14.621 5.32794 14.4979 5.32783 14.3733V10.2743C5.32783 6.77713 8.09648 3.78411 11.6764 3.62096ZM10.332 19.7531H13.6681C13.6681 20.6538 12.9309 21.3671 12 21.3671C11.0692 21.3671 10.332 20.6538 10.332 19.7531Z"
      fill="#232D3B"
    />
  </svg>
);

export default GoogleIcon;

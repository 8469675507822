import "./style.less";
import React, { CSSProperties } from "react";
import clsx from "clsx";
import { Spin } from "antd";

type ArrowType = "right" | "left";

interface IVuiSecondaryButton {
  label: string;
  loading?: boolean;
  type?: ArrowType;
  style?: CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
  htmlType?: "submit" | "reset" | "button" | undefined;
}

const VuiSecondaryButton = ({
  label = "",
  loading = false,
  type = "right",
  style,
  disabled = false,
  onClick,
  htmlType = "button",
}: IVuiSecondaryButton) => {
  return (
    <Spin spinning={loading}>
      <button
        className={clsx({
          "btn-secondary-shape": true,
          "left-type": type === "left",
          disabled: disabled,
        })}
        style={style}
        onClick={onClick}
        disabled={disabled}
        type={htmlType}
      >
        <span className="shape" />
        <span className="text">{label}</span>
      </button>
    </Spin>
  );
};

export default VuiSecondaryButton;

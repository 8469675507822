import "./_style.less";
import { Tabs, TabsProps } from "antd";
import { FC, ReactNode } from "react";

interface IVuiTabs {
  children: ReactNode;
}

const VuiTabs: FC<IVuiTabs & TabsProps> = (props) => {
  const { children, ...other } = props;

  return (
    <Tabs className="vui-tabs" {...other}>
      {children}
    </Tabs>
  );
};

export default VuiTabs;

import { TFunction } from "react-i18next";
import { IAccountTabPane } from "../../../pages/app/Account";
import {
  AccountIcon,
  AssessmentIcon,
  BillIcon,
  CalendarIcon,
  CounselingIcon,
  PeopleIcon,
} from "../../../components";

export const generateMenu = (t: TFunction) => [
  { name: t("common.text.about"), link: "/about" },
  { name: t("common.text.services"), link: "/services" },
  { name: t("common.text.psychologists"), link: "/psychologists" },
  { name: t("common.text.events"), link: "/events" },
  { name: t("common.text.insight"), link: "/insight" },
  { name: t("common.text.contact"), link: "/contact" },
  { name: t("common.text.book"), link: "/book", hasSquare: true },
];

export const generateAccountMenu = (t: TFunction): IAccountTabPane[] => [
  {
    tabName: t("common.text.myAccount"),
    key: "account",
    icon: <AccountIcon />,
  },
  {
    tabName: t("common.text.schedule"),
    key: "schedule",
    icon: <CalendarIcon />,
  },
  {
    tabName: t("common.text.patientInformation"),
    key: "patient-information",
    icon: <PeopleIcon />,
  },
  {
    tabName: t("common.text.myCounseling"),
    key: "my-counseling",
    icon: <CounselingIcon width={24} height={24} color="black" />,
  },
  {
    tabName: t("common.text.myAssessmentAndPrograms"),
    key: "my-assessment",
    icon: <AssessmentIcon width={24} height={24} color="black" />,
  },
  {
    tabName: t("common.text.billingInformation"),
    key: "billing-information",
    icon: <BillIcon />,
  },
];

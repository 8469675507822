import "./_style.less";
import { VuiContentLoading, VuiSecondaryButton } from "../../../../@vendor/components";
import {
  CalendarIcon,
  FacebookIcon,
  TwitterIcon,
} from "../../../../components";
import React, { useCallback, useMemo } from "react";
import { formatDate, handleErrorLoadDataResponse, useIsMounted } from "../../../../@vendor/utils";
import EventRepository from "../../../../repositories/EventRepository";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Event } from "../../../../models/Event";

const EventsDetail = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Event>({
    description: '',
    date: 'string',
    is_active: 1,
    title: ''
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const loadData = useCallback(async () => {
    setIsFetchingData(true);
    if(!id) return
    EventRepository.findOne(id, {with: ['photo']})
      .then((response: any) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch((err) => {
        console.log(err)
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.events"));
        }
      });

  }, [isMounted]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  if(isFetchingData){
    return <VuiContentLoading loading={isFetchingData} />
  }

  return (
    <>
      <div id="event-detail" className="section">
        <div className="container">
          <VuiSecondaryButton label={"Back"} type={"left"} onClick={() => navigate(-1)} />

          <div className="image-wrapper">
            <img
              className="image"
              src={data.photo?.url}
              alt="event detail"
            />
          </div>

          <h1 className="title">{data.title}</h1>
          <p className="date">
            <CalendarIcon className="icon" />
            {formatDate(data.date)}
          </p>

          <div className="description" dangerouslySetInnerHTML={{__html: data.description || ''}}>
            
          </div>

          <div className="share-wrapper">
            <h3 className="share-title">Share</h3>
            <ul className="socmed-list">
              <li>
                <a className="item" href={`https://twitter.com/intent/tweet?text=${data.title}url=${window.location.href}`} target="_blank" >
                  <TwitterIcon className="icon" />
                </a>
              </li>
              <li >
                <a className="item" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank">
                  <FacebookIcon className="icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsDetail;

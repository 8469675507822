import {
  api,
  ConfigService,
  IFindAllRepository,
  IFindOneRepository,
} from "../@vendor/utils";
import { Psychologist } from "../models/Psychologist";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends IFindAllRepository<T>, IFindOneRepository<T> {}

const Repository: IRepository<Psychologist> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/psychologist`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`${endPoint()}/api/psychologist/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;

import "./_style.less";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Psychologist } from "../../../models/Psychologist";
import {
  VuiContentLoading,
  VuiPagination,
  VuiTitle,
  VuiPsychologistCard,
  VuiSelectSingle,
  VuiSearch,
} from "../../../@vendor/components";
import {
  ConstantService,
  handleErrorLoadDataResponse,
  UrlParamsType,
  useIsMounted,
} from "../../../@vendor/utils";
import { NoContentCard } from "../../../components";
import PsychologistRepository from "../../../repositories/PsychologistRepository";
import ExpertiseRepository from "../../../repositories/ExpertiseRepository";
import _ from "lodash";

const qs = require("qs");

interface IParams extends UrlParamsType {
  expertises?: string[] | string;
}

const AppPsychologists = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMounted = useIsMounted();
  const location = useLocation();
  const [data, setData] = useState<Psychologist[]>([]);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(0);
  const [hasInitialize, setHasInitialize] = useState<boolean>(false);
  const [userId, setUserId] = useState<number[]>([]);
  const [expertise, setExpertise] = useState<string>();
  const [queryParams, setQueryParams] = useState<IParams>({
    page: Number(
      searchParams.get("page") || ConstantService.getConstant("DEFAULT_PAGE")
    ),
    per_page: Number(
      searchParams.get("per_page") ||
        ConstantService.getConstant("DEFAULT_PER_PAGE")
    ),
    search: searchParams.get("search") || "",
  });
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );

  const loadData = useCallback(async () => {
    setUserId(_.get(location, "state.user_id") || []);
    if (!hasInitialize) {
      return;
    }

    setIsFetchingData(true);

    const params: IParams = {
      page: Number(searchParams.get("page")),
      per_page: Number(searchParams.get("per_page")),
      search: searchParams.get("search"),
      expertises: searchParams.get("expertises") || undefined,
    };

    await PsychologistRepository.findAll({
      ...params,
      psychologists: userId,
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData, meta } = response.data;
          setData(responseData);
          setTotalData(meta.total);
          setIsFetchingData(false);
          setUserId([]);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          setUserId([]);
          handleErrorLoadDataResponse(t, t("common.text.psychologist"));
        }
      });
  }, [searchParams, expertise, hasInitialize, location, userId]);

  const setQueryString = useCallback(() => {
    const params: Partial<IParams> = {};
    Object.assign(params, {
      ...(queryParams.page ? { page: queryParams.page } : {}),
      ...(queryParams.per_page ? { per_page: queryParams.per_page } : {}),
      ...(queryParams.search ? { search: queryParams.search } : {}),
      ...(queryParams.expertises ? { expertises: queryParams.expertises } : {}),
    });
    const queryString = qs.stringify(params, { indices: false });
    setSearchParams(`?${queryString}`);
  }, [queryParams, setSearchParams]);

  const onPageChange = useCallback((page: number, pageSize: number) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: page,
      per_page: pageSize,
    }));
  }, []);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.type === "click") {
      setQueryParams((prevState) => ({
        ...prevState,
        page: 1,
        search: null,
      }));
    }
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      search: search,
    }));
  };

  const renderList = useMemo(
    () => (
      <div className="psychologists-list">
        {data.length ? (
          data.map((item) => {
            return <VuiPsychologistCard key={item.id} data={item} />;
          })
        ) : (
          <NoContentCard title={t("common.text.psychologist")} />
        )}
      </div>
    ),
    [data]
  );

  const handleChangeExpertise = useCallback(
    (expertise: any) => {
      setExpertise(expertise);
      setQueryParams((prevState) => ({
        ...prevState,
        page: 1,
        expertises: expertise,
      }));
    },
    [expertise, setExpertise]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [searchParams, hasInitialize]);

  useEffect(() => {
    isMounted ? setQueryString() : setSearchParams("");
    setHasInitialize(true);
  }, [isMounted, queryParams]);

  return (
    <div id="app-psychologists-page">
      <div className="container">
        <div className="psychologists-top">
          <VuiTitle text={t("common.text.psychologists")} />
          <div className="psychologists-filter-wrapper">
            <VuiSelectSingle
              dropdownMatchSelectWidth={false}
              allowClear={true}
              repository={ExpertiseRepository}
              value={expertise}
              onChange={(expertise) => handleChangeExpertise(expertise)}
              onClear={() => handleChangeExpertise(undefined)}
              placeholder={t("common.text.expertise")}
              labelInValue={false}
            />
          </div>
        </div>
        <div className="search-wrapper">
          <VuiSearch
            value={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
        </div>

        <section className="section psychologists-list-wrapper">
          {isFetchingData ? (
            <VuiContentLoading loading={isFetchingData} />
          ) : (
            renderList
          )}

          {!!data.length && (
            <VuiPagination
              onChange={onPageChange}
              total={totalData}
              current={Number(queryParams.page)}
              pageSize={Number(queryParams.per_page)}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default AppPsychologists;

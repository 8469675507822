import "./_style.less";
import React, { useCallback, useMemo } from "react";
import {
  VuiContentLoading,
  VuiHelmet,
  VuiSecondaryButton,
  VuiTitle,
} from "../../../@vendor/components";
import Space from "antd/lib/space";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import {
  handleErrorLoadDataResponse,
  showNotification,
  useIsMounted,
} from "../../../@vendor/utils";
import SettingRepository from "../../../repositories/SettingRepository";
import FormRepository from "../../../repositories/FormRepository";
import { useTranslation } from "react-i18next";

const Contact: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({
    description: "",
    whys: [],
    clients: [],
  });

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await SettingRepository.show()
      .then((response: any) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.contact"));
        }
      });
  }, [isMounted]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const onFinish = (data: any) => {
    FormRepository.contact(data)
      .then((res: any) => {
        if (res.data?.success) {
          showNotification("success", "Message Sent");
          form.resetFields();
        }
      })
      .catch((err) => {
        let msg = err.response?.data?.message;
        showNotification("error", msg || t("notification.error.default"));
      });
  };

  if (isFetchingData) {
    return <VuiContentLoading loading={isFetchingData} />;
  }

  return (
    <>
      <VuiHelmet title={"Contact"} />

      <div id="contact" className="section">
        <div className="container">
          <Space direction={"vertical"} size={100}>
            <VuiTitle text={"Get In Touch"} />

            <div className="info-list">
              <div className="item">
                <img
                  className="icon"
                  src="/images/icon/pin-map-ico.png"
                  alt="pin map icon"
                />
                <h3 className="title">Visit us</h3>
                <p className="desc">{data.address}</p>
              </div>

              <div className="item">
                <img
                  className="icon"
                  src="/images/icon/mail-ico.png"
                  alt="mail icon"
                />
                <h3 className="title">Email us</h3>
                <a className="mail" href={`mailto:${data.email}`}>
                  {data.email}
                </a>
              </div>

              <div className="item">
                <img
                  className="icon"
                  src="/images/icon/phone-ico.png"
                  alt="phone icon"
                />
                <h3 className="title">Contact Us</h3>
                <p className="desc">
                  {data.office_phone_number}
                  <br />
                  {data.whatsapp_number}
                  &nbsp;/&nbsp;
                  {data.phone_number}
                </p>
              </div>
            </div>
          </Space>

          <div className="form-section">
            <div
              className="maps-wrapper"
              dangerouslySetInnerHTML={{ __html: data.iframe_address }}
            ></div>

            <div className="form-wrapper">
              <h4 className="title">Leave A Message</h4>

              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="Name" name="name">
                  <Input placeholder="Your name" />
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Input placeholder="Your email" />
                </Form.Item>
                <Form.Item label="Subject" name="subject">
                  <Input placeholder="Subject" />
                </Form.Item>
                <Form.Item label="Message" name="message">
                  <Input placeholder="Message" />
                </Form.Item>

                <div className="form-submit-wrapper">
                  <VuiSecondaryButton
                    label={"Send Message"}
                    onClick={() => form.submit()}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

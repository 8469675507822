import "./_style.less";
import Paragraph from "antd/lib/typography/Paragraph";
import { CSSProperties, FC, ReactNode } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

type TextType = "success" | "secondary" | "primary";
type TextWeight = "bold" | "normal";
type linkType = "internal" | "external";

interface IListTextModal {
  label: ReactNode;
  labelTextWeight?: TextWeight;
  value: ReactNode;
  valueTextType?: TextType;
  valueTextWeight?: TextWeight;
  isValueLink?: boolean;
  valueLinkType?: linkType;
  link?: string;
  style?: CSSProperties;
  onClickValue?: () => void | null;
  valueStyle?: CSSProperties;
  onlyFlex?: boolean;
}

const ListTextModal: FC<IListTextModal> = ({
  label,
  value = null,
  labelTextWeight = "normal",
  valueTextType = "",
  valueTextWeight = "normal",
  isValueLink = false,
  valueLinkType = "internal",
  link = "/",
  style = {},
  onClickValue = null,
  valueStyle = {},
  onlyFlex = false,
}) => {
  return (
    <div
      className={clsx({
        "list-text-modal-wrapper": true,
        "only-flex": onlyFlex,
      })}
      style={style}
    >
      <Paragraph
        className={clsx({
          "list-text-label": true,
          "text-bold": labelTextWeight === "bold",
        })}
      >
        {label}
      </Paragraph>

      {isValueLink ? (
        valueLinkType === "internal" ? (
          <Link className="list-text-value" to={link}>
            {value || "-"}
          </Link>
        ) : (
          <a className="list-text-value" href={link} target="_blank">
            {value || "-"}
          </a>
        )
      ) : (
        <Paragraph
          className={clsx({
            "list-text-value": true,
            "text-success-color": valueTextType === "success",
            "text-primary-color": valueTextType === "primary",
            "text-secondary-color": valueTextType === "secondary",
            "text-bold": valueTextWeight === "bold",
          })}
          style={valueStyle}
          onClick={onClickValue ? onClickValue : () => {}}
        >
          {value || "-"}
        </Paragraph>
      )}
    </div>
  );
};

export default ListTextModal;

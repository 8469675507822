import { useTranslation } from "react-i18next";
import "./_style.less";
import VuiTitle from "../../../@vendor/components/atoms/Title";
import VuiSubtitle from "../../../@vendor/components/atoms/Subtitle";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { useCallback, useMemo, useState } from "react";
import {
  breakLine,
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../@vendor/utils";
import PageRepository from "../../../repositories/PageRepository";
import { VuiContentLoading, VuiHelmet } from "../../../@vendor/components";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const AppAbout = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    description: "",
    whys: [],
    clients: [],
  });

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await PageRepository.page("about")
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.about"));
        }
      });
  }, [isMounted]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  if (isFetchingData) {
    return <VuiContentLoading loading={isFetchingData} />;
  }

  return (
    <>
      <VuiHelmet title={data.meta_title} />
      <div className="banner-section">
        <div className="container">
          <div className="banner-inner">
            <div className="info-wrapper main-title">
              <VuiTitle text={breakLine(data.title || "")} />
              <div className="hide-mobile">
                <VuiSubtitle text={data.summary} />
              </div>
            </div>
            <div className="image-wrapper">
              <img
                src="/images/about-img-bg.png"
                alt="background"
                className="bg"
              />
              <img className="banner" src={data.image} alt="Banner About" />
            </div>
            <div className="show-mobile">
              <VuiSubtitle text={data.summary} />
            </div>
          </div>
        </div>
      </div>

      <div className="description-section">
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></div>
      </div>

      <div className="vision-section">
        <div className="container">
          <div className="vision-list">
            <div className="item">
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src={data.icon_vision}
                  alt="vision icon"
                />
              </div>
              <h4 className="title has-stab">{data.title_vision}</h4>
              <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: data.description_vision }}
              ></div>
            </div>

            <div className="item">
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src={data.icon_mission}
                  alt="mission icon"
                />
              </div>
              <h4 className="title has-stab">{data.title_mission}</h4>
              <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: data.description_mission }}
              ></div>
            </div>

            <div className="item">
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src={data.icon_campaign}
                  alt="campaign icon"
                />
              </div>
              <h4 className="title has-stab">{data.title_campaign}</h4>
              <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: data.description_campaign }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-section">
        <div className="container">
          <VuiTitle text={"WHY PERSONAL GROWTH"} />

          <ul className="why-list">
            {data.whys.map((item: any) => (
              <li className="item" key={item.title}>
                <div className="icon-wrapper">
                  <img
                    className="icon"
                    src={item.image}
                    alt="why leading icon"
                  />
                </div>
                <p className="name">{item.title}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="client-section">
        <div className="container">
          <VuiTitle text={"OUR HAPPY CLIENTS"} />

          <div className="client-slider-wrapper">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              autoplay={true}
              onPaginationRender={(swiper, pagination) => {
                const minSlide: number = 1;
                const divideNumber: number = 0;
                if (
                  pagination &&
                  data.clients &&
                  data.clients.length > minSlide
                ) {
                  const totalNumber =
                    divideNumber > 0
                      ? Math.ceil(data.clients.length / divideNumber)
                      : data.clients.length;
                  pagination.setAttribute("data-before", "01");
                  pagination.setAttribute(
                    "data-after",
                    String((totalNumber < 10 ? "0" : "") + totalNumber)
                  );
                }
              }}
              modules={[Pagination, Navigation, Autoplay]}
              navigation
              className="client-slider"
              slidesPerGroup={3}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  slidesPerGroup: 1,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                  slidesPerGroup: 3,
                },
              }}
            >
              <SwiperButton prev>
                <LeftOutlined />
              </SwiperButton>
              {data.clients.map((item: any) => {
                return (
                  <SwiperSlide key={item.name}>
                    <div className="client-card">
                      <img
                        className="icon"
                        src="/images/quote-ico.png"
                        alt="quote icon"
                      />
                      <div
                        className="testi"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>

                      <div className="info-wrapper">
                        <p className="name">{item.name}</p>
                        <p className="desc">
                          {item.age} Years Old, {item.gender}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              <SwiperButton>
                <RightOutlined />
              </SwiperButton>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

const SwiperButton = (props: any) => {
  const swiper = useSwiper();
  return (
    <button
      className={`btn-swiper ${props.prev ? "prev" : ""}`}
      onClick={() => (props.prev ? swiper.slidePrev() : swiper.slideNext())}
    >
      {props.children}
    </button>
  );
};

export default AppAbout;

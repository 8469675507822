import "./_style.less";
import {
  VuiButton,
  VuiContentLoading,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { NoContentCard, PatientInformationCard } from "../../../atoms";
import { useCallback, useMemo, useState } from "react";
import { PatientModal } from "../../modals";
import { User } from "../../../../models/User";
import AccountRepository from "../../../../repositories/AccountRepository";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import { Patient } from "../../../../models/Patient";

const AccountPatientInformation = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<Patient[]>([]);
  const [selectedData, setSelectedData] = useState<Patient | null>(null);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const handleClickCard = useCallback(
    (data: Patient | null = null) => {
      setSelectedData(data);
      setOpenModal(true);
    },
    [selectedData]
  );

  const handleCloseModal = useCallback(() => {
    setSelectedData(null);
    setOpenModal(false);
  }, []);

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await AccountRepository.patient({
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted) {
          setData(response.data.data);
          setIsFetchingData(false);
        }
      })
      .catch((err: any) => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.patient"), err);
        }
      });
  }, [isMounted]);

  const renderData = useMemo(() => {
    if (!data.length) {
      return (
        <div style={{ marginTop: 24 }}>
          <NoContentCard title={t("common.text.dontHavePatientInformation")} />
        </div>
      );
    }

    return (
      <div className="patient-information-cards">
        {data.map((item) => (
          <PatientInformationCard
            key={item.id}
            data={item}
            onClick={handleClickCard}
          />
        ))}
      </div>
    );
  }, [data]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <div id="account-patient-information-body">
      <PatientModal
        data={selectedData}
        visible={openModal}
        onSubmit={loadData}
        onClose={handleCloseModal}
      />

      <VuiSecondaryTitleWrapper
        level={3}
        title={t("common.text.patientInformation")}
      >
        <VuiButton
          label={t("common.button.addItem", {
            item: t("common.text.patient"),
          })}
          buttonProps={{
            type: "primary",
            onClick: () => handleClickCard(null),
          }}
        />
      </VuiSecondaryTitleWrapper>

      {isFetchingData ? (
        <VuiContentLoading loading={isFetchingData} />
      ) : (
        renderData
      )}
    </div>
  );
};

export default AccountPatientInformation;

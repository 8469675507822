import "./_style.less";
import { VuiButton, VuiFormText } from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { FC, useCallback } from "react";
import {
  formatDateTime,
  getConsultationTypeLabel,
  getTimeZone,
} from "../../../../@vendor/utils";
import { History } from "../../../../models/History";
import { Typography } from "antd";
import { BookingTag } from "../../Tag";
import StatusTag from "../../Tag/StatusTag";
import GraphReportIcon from "../../../icons/GraphReport";
import BookIcon from "../../../icons/Book";
import _ from "lodash";

interface ICard {
  onClick: (data: any) => void;
  data: History;
  onShowAllSession: (history: History) => void;
  onShowReport?: (history: History) => void;
  onShowInstruction?: (history: History) => void;
}

const HistoryCard: FC<ICard> = ({
  onClick,
  data,
  onShowAllSession,
  onShowReport,
  onShowInstruction,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  const type = data?.session_type;

  const isNoSchedule = !data?.date;

  const renderData = () => {
    return (
      <div className="history-card-data">
        <VuiFormText
          label={t("common.form.patient.label")}
          value={data.patient_name}
          valueWeightType="bold"
        />

        <VuiFormText
          label={t("common.form.psychologist.label")}
          value={data.psychologists_name}
          valueWeightType="bold"
        />

        <VuiFormText
          label={t("common.form.date&Time.label")}
          value={
            <Typography.Text>
              {`${formatDateTime(data.date)} (${getTimeZone()})`}
              {data.children.length > 0 && (
                <Typography.Text>
                  ,{" "}
                  <span
                    className="link-text underline"
                    onClick={() => onShowAllSession(data)}
                  >
                    <b>+{data.children.length} more</b>
                  </span>
                </Typography.Text>
              )}
            </Typography.Text>
          }
          valueWeightType="bold"
        />

        <VuiFormText
          label={
            type === "ASSESSMENT"
              ? t("common.form.packageAndMethod.label")
              : t("common.form.categoryAndMethod.label")
          }
          value={
            <>
              {_.get(
                data,
                type === "ASSESSMENT"
                  ? "assessment_name"
                  : "booking_category_name"
              )}{" "}
              | {getConsultationTypeLabel(data.consultation_type, t)}
            </>
          }
          valueWeightType="bold"
        />
      </div>
    );
  };

  const renderNoSchdueleData = () => {
    return (
      <div className="history-card-data">
        <VuiFormText
          label={t("common.form.patient.label")}
          value={data.patient_name}
          valueWeightType="bold"
        />

        <VuiFormText
          label={t("common.text.package")}
          value={<>{_.get(data, "assessment_name")}</>}
          valueWeightType="bold"
        />
      </div>
    );
  };

  return (
    <>
      <div className="history-card">
        <div className="history-card-header">
          <div className="history-tag-wrapper">
            <BookingTag type={data.session_type} isPackage={data.is_package} />
            <StatusTag status={data.history_status_name} />
          </div>
          <div>
            <Typography.Paragraph className="booking-code-title">
              {t("common.text.bookingCode")}
            </Typography.Paragraph>
            <Typography.Paragraph className="booking-code">
              <b>{data.booking_number}</b>
            </Typography.Paragraph>
          </div>
        </div>
        <div className="history-card-wrapper">
          {isNoSchedule ? renderNoSchdueleData() : renderData()}
          <div className="history-action-wrapper">
            <VuiButton
              buttonProps={{ size: "small", onClick: handleClick }}
              label={t("common.button.seeDetails")}
            />

            {data.session_type === "ASSESSMENT" && (
              <div className="history-attachment-wrapper">
                <div
                  className="history-attachment-btn cursor-pointer"
                  onClick={() => onShowInstruction?.(data)}
                >
                  <GraphReportIcon />
                  <div>Instruction</div>
                </div>
                <div
                  className="history-attachment-btn cursor-pointer"
                  onClick={() => onShowReport?.(data)}
                >
                  <BookIcon />
                  <div>Report</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryCard;

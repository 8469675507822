import "./style.less";
import { Badge, Dropdown, Menu } from "antd";
import {
  AccountIcon,
  BagIcon,
  MenuIcon,
  NotificationIcon,
  PGPlainLogo,
} from "../../../../components";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useMemo, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import { VuiNotification } from "../../atoms";
import { generateMenu, usePassportService } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import _ from "lodash";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { update } from "../../../../stores/system";

interface IVuiNavbar {
  changeTheme: (value: boolean) => void;
}

const VuiNavbar: FC<IVuiNavbar> = ({ changeTheme }) => {
  const { t } = useTranslation();
  const menus = generateMenu(t);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoggedIn, account } = useAppSelector((state) => state.system);
  const { logout } = usePassportService(true);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);
  const [openNotification, setOpenNotification] = useState<boolean>(false);

  const menuItems: ItemType[] = [
    {
      key: "0",
      label: <Link to="/account">{t("common.text.myAccount")}</Link>,
    },
    {
      key: "1",
      label: (
        <Link to="/account?tab=schedule">{t("common.text.schedule")}</Link>
      ),
    },
    { key: "2", label: <div onClick={logout}>{t("common.text.logOut")}</div> },
  ];

  const handleClickMobileMenu = useCallback(() => {
    if (width <= 768) {
      setIsOpenMobileMenu(!isOpenMobileMenu);
    }
  }, [isOpenMobileMenu, width]);

  const handleCloseMobileMenu = useCallback(() => {
    if (width <= 768) {
      setIsOpenMobileMenu(false);
    }
  }, [isOpenMobileMenu, width]);

  const handleClickNotification = useCallback(() => {
    changeTheme(!openNotification);
    setOpenNotification(!openNotification);
  }, [openNotification, changeTheme]);

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
    if (window.innerWidth > 768) {
      setIsOpenMobileMenu(false);
    }
  }, []);

  const handleCloseNotification = useCallback(() => {
    setOpenNotification(false);
    changeTheme(false);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const loadMenus = useMemo(() => {
    return menus.map((menu) => (
      <Link
        className={clsx({
          "vui-navbar-menu-link": true,
          "has-square": menu.hasSquare,
          active: _.get(location, "pathname", "").includes(menu.link),
        })}
        key={menu.link}
        to={menu.link}
        onClick={() => handleCloseMobileMenu()}
      >
        {menu.name}
      </Link>
    ));
  }, [menus]);

  const handleReadAll = useCallback(() => {
    dispatch(
      update({
        isLoggedIn: isLoggedIn,
        account: {
          ...account,
          total_unread_notification: 0,
        },
      })
    );
  }, [isLoggedIn, account, dispatch]);

  return (
    <div id="vui-navbar">
      <div className="container">
        <div className="vui-navbar-space">
          <PGPlainLogo />

          <div className="vui-navbar-menu-space desktop">{loadMenus}</div>

          <div className="vui-navbar-menu-space">
            {isLoggedIn ? (
              <>
                <div className="vui-navbar-menu-space-notification">
                  <OutsideClickHandler
                    display="flex"
                    onOutsideClick={() => {
                      handleCloseNotification();
                      handleCloseMobileMenu();
                    }}
                  >
                    <Badge
                      count={account?.total_unread_notification}
                      overflowCount={8}
                      size="small"
                      className="vui-navbar-icon-badge"
                    >
                      <NotificationIcon
                        onClick={() => {
                          handleClickNotification();
                          handleCloseMobileMenu();
                        }}
                      />
                    </Badge>

                    <VuiNotification
                      onReadAll={handleReadAll}
                      show={openNotification}
                    />
                  </OutsideClickHandler>
                </div>

                <Badge
                  count={account?.total_cart}
                  overflowCount={9}
                  size="small"
                  className="vui-navbar-icon-badge"
                >
                  <BagIcon
                    onClick={() => {
                      handleCloseMobileMenu();
                      navigate("/cart?selectAll=true");
                    }}
                  />
                </Badge>
                <Dropdown
                  className="vui-navbar-dropdown-account"
                  placement="bottomRight"
                  overlay={
                    <Menu
                      items={menuItems}
                      className="vui-navbar-dropdown-account-menu"
                    />
                  }
                  trigger={["click"]}
                >
                  <div
                    className="vui-dropdown-account-action"
                    onClick={(event) => event.preventDefault()}
                  >
                    <AccountIcon />
                    <span className="vui-navbar-menu-link hide-mobile">
                      {account?.name}
                    </span>
                  </div>
                </Dropdown>
              </>
            ) : (
              <Link to={"/auth/login"} className="vui-dropdown-account-action">
                <AccountIcon />
                <span className="vui-navbar-menu-link hide-mobile">
                  {t("common.text.login")}
                </span>
              </Link>
            )}

            <MenuIcon
              onClick={handleClickMobileMenu}
              className="vui-navbar-hamburger-icon"
            />
          </div>
        </div>

        <div
          className={clsx({
            "vui-navbar-mobile": true,
            open: isOpenMobileMenu,
          })}
        >
          <div className="vui-navbar-mobile-menu-wrapper">{loadMenus}</div>
        </div>
      </div>
    </div>
  );
};

export default VuiNavbar;

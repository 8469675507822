const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.8661 6.03292C21.7256 5.88889 21.5095 5.84781 21.3252 5.93186L21.2471 5.96728C21.1809 5.9975 21.1147 6.02772 21.048 6.05747C21.2418 5.751 21.3956 5.42328 21.5018 5.08422C21.5609 4.89722 21.4966 4.69275 21.3404 4.57186C21.1842 4.45097 20.969 4.43822 20.7999 4.54069C20.2566 4.86794 19.7347 5.10358 19.1952 5.26792C18.3809 4.4845 17.2719 4 16.0476 4C13.5491 4 11.5239 6.00836 11.5239 8.48611C11.5239 8.48847 11.5239 8.58197 11.5239 8.72222L11.0482 8.68444C6.41829 8.14139 5.10021 5.05306 5.04545 4.91989C4.95688 4.69794 4.76165 4.53597 4.52594 4.48733C4.2907 4.43775 4.04642 4.51094 3.87595 4.67953C3.78167 4.7735 2.95263 5.64333 2.95263 7.30556C2.95263 8.48989 3.485 9.45039 4.17404 10.1974C3.85309 10.0034 3.66595 9.84611 3.66119 9.84186C3.44167 9.65203 3.12739 9.61236 2.86644 9.74364C2.60645 9.87539 2.45312 10.1507 2.47931 10.4392C2.48835 10.5389 2.69121 12.4287 4.8926 13.8558L4.49118 13.928C4.24785 13.9719 4.04452 14.1377 3.95404 14.3662C3.86405 14.5952 3.89928 14.8536 4.04785 15.05C4.09785 15.1166 5.02783 16.3156 7.04732 17.005C5.96877 17.3615 4.48689 17.6944 2.71454 17.6944C2.43455 17.6944 2.17979 17.8569 2.06408 18.11C1.94789 18.3631 1.99217 18.6601 2.17693 18.8689C2.2536 18.9562 4.11214 21 8.90491 21C16.8633 21 20.5714 13.6735 20.5714 8.72222V8.48611C20.5714 8.41622 20.5638 8.34775 20.5609 8.27881C21.5571 7.32397 21.9318 6.60761 21.9499 6.57219C22.0399 6.39275 22.0066 6.17647 21.8661 6.03292Z"
      fill="#232D3B"
    />
  </svg>
);

export default TwitterIcon;

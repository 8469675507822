const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6987 12.7524H15.9555L16.4672 9.44399H12.6987V7.63695C12.6987 6.26276 13.1483 5.04406 14.4332 5.04406H16.4987V2.15805C15.6436 2.04252 14.7812 1.9902 13.9184 2.00151C10.8895 2.00151 9.11399 3.60053 9.11399 7.24508V9.4482H6V12.7566H9.11294V21.8453C10.2994 22.0423 11.5095 22.0512 12.6987 21.8716V12.7524Z"
      fill="#232D3B"
    />
  </svg>
);

export default FacebookIcon;

import "./_style.less";
import { FileSearchingIllustration } from "../../../../illustrations";
import Paragraph from "antd/lib/typography/Paragraph";
import { useTranslation } from "react-i18next";

const NoBilling = () => {
  const { t } = useTranslation();

  return (
    <div className="no-billing-wrapper">
      <FileSearchingIllustration />
      <Paragraph className="no-billing-text">
        {t("common.text.dontHaveBilling")}
      </Paragraph>
    </div>
  );
};

export default NoBilling;

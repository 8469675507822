import { VuiCheckbox, VuiFormItem } from "../../../../@vendor/components";
import { ICounselingForm } from "../interface";
import { useTranslation } from "react-i18next";
import { Input, Space } from "antd";
import Checkbox from "antd/es/checkbox";
import CounselingFormLabel from "../Label";
import { ChangeEvent } from "react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { OtherData } from "../../../../pages/app/CounselingForm";

interface Checkbox {
  handleChangeCheckbox: (values: Array<CheckboxValueType>, id: string) => void;
  otherDataIds: string[];
  otherData: OtherData[];
  handleChangeInputCheckbox: (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
}

const CounselingFormCheckbox = ({
  question,
  otherDataIds = [],
  otherData = [],
  handleChangeInputCheckbox,
  handleChangeCheckbox,
}: ICounselingForm & Checkbox) => {
  const { t } = useTranslation();

  return (
    <VuiFormItem
      rules={[
        {
          required: question.is_required,
          message: t("validation.required", {
            item: question.question,
          }),
        },
      ]}
      name={String(question.id)}
      label={
        <CounselingFormLabel
          label={question.question}
          isRequired={question.is_required}
        />
      }
    >
      <Checkbox.Group
        onChange={(value) => handleChangeCheckbox(value, String(question.id))}
      >
        <Space direction="vertical">
          {question.answers.map((answer, index) => (
            <VuiCheckbox
              value={answer}
              key={`${question.id}_${index}_checkbox`}
              title={typeof answer === "string" ? answer : ""}
            />
          ))}
          {question.is_others ? (
            <div>
              <VuiCheckbox value={"other"} title="Other" />
              {otherDataIds.find((id) => id === String(question.id)) && (
                <>
                  <br />
                  <Input
                    defaultValue={
                      otherData.find(
                        (other) => other.id === String(question.id)
                      )?.answer || ""
                    }
                    style={{ marginTop: -16, marginLeft: 32 }}
                    onChange={(e) =>
                      handleChangeInputCheckbox(e, String(question.id))
                    }
                  />
                </>
              )}
            </div>
          ) : null}
        </Space>
      </Checkbox.Group>
    </VuiFormItem>
  );
};

export default CounselingFormCheckbox;

import "./_style.less";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import {
  VuiContentLoading,
  VuiPagination,
  VuiSearch,
  VuiSecondaryButton,
} from "../../../../@vendor/components";
import Space from "antd/lib/space";
import InsightCard from "../../../../components/molecules/Insight/Card";
import {
  formatDate,
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import ArticleRepository from "../../../../repositories/ArticleRepository";
var qs = require("qs");

const InsightArticles: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(
    parseInt(searchParams.get("page") || "1")
  );
  const [tags] = React.useState(searchParams.get("tags"));
  const [search, setSearch] = React.useState<string>(
    searchParams.get("search") || ""
  );

  const loadData = useCallback(
    async (page = 0, keyword: string | null = null) => {
      setIsFetchingData(true);
      let params: any = {
        per_page: 9,
        page: page || currentPage,
        with: ["photo"],
        sorted_by: "desc",
        order_by: "date",
        search: typeof keyword === "string" ? keyword : search,
      };
      if (tags) {
        params["tags"] = [tags];
      }
      ArticleRepository.findAll(params)
        .then((response: any) => {
          if (isMounted) {
            const { data: responseData } = response.data;
            setData(responseData);
            setTotalPage(response.data.meta?.total);
            setIsFetchingData(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (isMounted) {
            setIsFetchingData(false);
            handleErrorLoadDataResponse(t, t("common.text.insight"));
          }
        });
    },
    [isMounted, search]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      const queryParams = qs.stringify({ page, search }, { indices: false });
      setSearchParams(queryParams);
      setCurrentPage(page);
      loadData(page);
      window.scrollTo(0, 0);
    },
    [search]
  );

  //Search
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.type === "click") {
      if (searchParams.get("search")) {
        searchParams.delete("search");
        setSearchParams(searchParams);
        setCurrentPage(1);
        loadData(undefined, e.target.value);
      }
    }
  };

  const handleSearch = () => {
    if (!(!search && !searchParams.get("search"))) {
      if (search) {
        searchParams.append("search", search);
      } else {
        searchParams.delete("search");
      }

      setSearchParams(searchParams);
      setCurrentPage(1);
      loadData();
    }
  };

  return (
    <>
      <div id="insight-articles-section" className="section">
        <div className="container">
          <Space
            className="articles-top-wrapper"
            direction={"vertical"}
            size={30}
          >
            <VuiSecondaryButton
              label={"Back"}
              type={"left"}
              onClick={() => navigate("/insight")}
            />
            <Space direction="vertical" size={2}>
              <h2 className="section-title">Articles</h2>
              <VuiSearch
                value={search}
                onChange={onSearchChange}
                onSearch={handleSearch}
              />
            </Space>
          </Space>

          {isFetchingData ? (
            <VuiContentLoading loading={isFetchingData} />
          ) : (
            <>
              <div className="data-list">
                {data.map((item: any) => {
                  return (
                    <InsightCard
                      key={`article-${item.id}`}
                      id={item.id}
                      link={`/insight/${item.permalink || item.id}`}
                      image={item.photo?.url}
                      label={item.first_tag}
                      name={item.title}
                      summary={item.summary}
                      date={formatDate(item.date)}
                      author={item.author}
                    />
                  );
                })}
              </div>

              {data.length > 0 && (
                <div className="pagination-wrapper">
                  <VuiPagination
                    total={totalPage}
                    current={currentPage}
                    onChange={onPageChange}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InsightArticles;

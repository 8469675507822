import "./_style.less";
import { Select, SelectProps } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useDebounce, useIsMounted } from "../../../../utils";
import { AxiosError, AxiosResponse } from "axios";
import { ArrowDownIcon } from "../../../../../components";
import clsx from "clsx";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

export interface valueProps {
  value: string | number;
  label?: ReactNode;
}

type SelectColorType = "primary" | "secondary";

interface Props extends SelectProps {
  repository?: any;

  type?: SelectColorType;
  value?: any;
  repositoryName?: string;
  onChange?: (value: valueProps) => void;
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  defaultOptions?: Array<any>;
  style?: any;
  labelInValue?: boolean;
  size?: SizeType;
  selectParams?: object;
  labelKey?: string;
  valueKey?: string;
  showSearch?: boolean;
  isConstant?: boolean;
}

const VuiSelectSingle: FC<Props> = ({
  value,
  onChange,
  repository = null,
  placeholder,
  allowClear = false,
  disabled = false,
  defaultOptions = [],
  style = {},
  type = "primary",
  repositoryName = "select",
  size = "middle",
  labelInValue = true,
  selectParams = {},
  labelKey = "name",
  valueKey = "id",
  showSearch = false,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasNextData, setHasNextData] = useState(false);
  const isMounted = useIsMounted();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const debouncedSearch = useDebounce<string>(search, 500);
  const [perPage, setPerPage] = useState<number>(999);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (isMounted && isOpen) {
      getData(true);
    }
  }, [debouncedSearch, isMounted, isOpen]);

  const getData = useCallback(
    async (reset: boolean = false) => {
      if (repository) {
        if (repository[repositoryName]) {
          if (!reset) {
            setPage((prevState) => prevState + 1);
          } else {
            setPage(1);
          }

          const params = {
            ...selectParams,
            per_page: perPage,
            page: page,
          };

          if (debouncedSearch) {
            Object.assign(params, {
              search: debouncedSearch,
            });
          }

          setLoading(true);
          setHasNextData(false);

          await repository[repositoryName](params)
            .then((response: AxiosResponse) => {
              let { data: responseData } = response.data;
              if (reset) {
                if (defaultOptions.length) {
                  responseData = [...defaultOptions, ...responseData];
                }
                setData(responseData);
              } else {
                setData((prevState) => [...prevState, ...responseData]);
              }

              if (responseData.length >= perPage) {
                setHasNextData(true);
              }

              setLoading(false);
            })
            .catch((error: AxiosError) => {
              setLoading(false);
            });
        }
      } else {
        setData(defaultOptions);
      }
    },
    [page, perPage, debouncedSearch, defaultOptions]
  );

  const handlePopUpScroll = useCallback(
    (event: any) => {
      const { scrollTop, offsetHeight, scrollHeight } = event.target;
      const isBottom = scrollTop + offsetHeight === scrollHeight;

      if (hasNextData && isBottom) {
        getData();
      }
    },
    [hasNextData]
  );

  return (
    <Select
      showSearch={showSearch}
      size={size}
      value={value}
      style={style}
      allowClear={allowClear}
      className={clsx({
        "vui-select-single": true,
        "secondary-type": type === "secondary",
      })}
      onSearch={showSearch ? (value) => setSearch(value) : undefined}
      dropdownClassName={clsx({
        "dropdown-secondary-type": type === "secondary",
      })}
      placeholder={placeholder}
      filterOption={false}
      labelInValue={labelInValue}
      onChange={(value) => {
        if (typeof onChange === "function") {
          onChange(value);
        }
      }}
      suffixIcon={
        loading ? <LoadingOutlined /> : <ArrowDownIcon width={12} height={12} />
      }
      onDropdownVisibleChange={(open) => {
        setIsOpen(open);
      }}
      notFoundContent={null}
      onPopupScroll={handlePopUpScroll}
      loading={loading}
      disabled={disabled}
      {...props}
    >
      {data.map((item: any) => {
        return (
          <Option
            key={item[valueKey]}
            value={item[valueKey]}
            label={item[labelKey]}
          >
            {item[labelKey]}
          </Option>
        );
      })}
    </Select>
  );
};

export default VuiSelectSingle;

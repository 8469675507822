import { api, IFindAllRepository, IFindOneRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Service } from "../models/Service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends IFindAllRepository<T>, IFindOneRepository<T> {}

const ServiceRepository: IRepository<Service> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/service`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`${endPoint()}/api/service/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ServiceRepository);

export default ServiceRepository;

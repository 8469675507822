import { api, ConfigService, IFindAllRepository, ISelectRepository } from "../@vendor/utils";
import { Category } from "../models/Category";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends ISelectRepository<T>, IFindAllRepository<T> {}

const Repository: IRepository<Category> = {
  select: function (params) {
    return api.get(`${endPoint()}/api/select/category`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },

  findAll: function (params) {
    return api.get(`${endPoint()}/api/tag`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;

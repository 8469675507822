import "./style.less";
import { Modal, Checkbox } from "antd";
import { FC, useCallback, useState } from "react";
import * as React from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import clsx from "clsx";
import { CloseIcon } from "../../../icons";

interface IModal {
  visible: boolean;
  onClose: () => void;
  title: string;
  className?: string;
  onSubmit?: () => void;
}

const TermsConditionRegisterModal: FC<IModal> = ({
  onClose,
  visible,
  className,
  title,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onChangeCheckbox = useCallback((event: CheckboxChangeEvent) => {
    setIsChecked(event.target.checked);
  }, []);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={title} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <Paragraph style={{ marginBottom: 24 }}>
        Welcome to Personal Growth! Thank you for registering with us.
        <br />
        Before creating an account, please read the following terms:
        <ol className="term-and-condition-list">
          <li>
            To create an account, you should at least be 17 years or older. You
            are responsible for any actions taken on this platform.
          </li>
          <li>
            The account you have set up can be used to book counseling sessions
            for yourself or those related to you (parents, children, spouse,
            relatives, or friends). Booked and paid sessions are non-cancellable
            and non-refundable. Rescheduling can only be done once, with the
            same psychologist, and for the same duration. This action can only
            be made at a maximum of 24 hours before the booked schedule.
          </li>
          <li>
            Patients under 17 years of age need parental consent to participate
            in counseling sessions at Personal Growth.
          </li>
          <li>
            All data that you convey in your account will be kept confidential
            and will only be used for the purposes of the counseling process at
            Personal Growth. The data can only be accessed by you and the
            psychologist.
            <ul>
              <li>
                Ensure that the data you provide is correct and in accordance
                with your actual situation.
              </li>
            </ul>
          </li>
          <li>
            By becoming an account holder, you are willing to follow the
            conditions set by Personal Growth.
          </li>
        </ol>
      </Paragraph>

      <Checkbox onChange={onChangeCheckbox}>
        {t("auth.register.termsCondition.checkbox")}
      </Checkbox>
      <VuiButton
        buttonProps={{
          type: "primary",
          onClick: onSubmit,
          disabled: !isChecked,
        }}
        label={t("common.button.submit")}
      />
    </Modal>
  );
};

export default TermsConditionRegisterModal;

const StopIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7 0C10.8598 0 14 3.14023 14 7C14 10.8598 10.8598 14 7 14C3.14022 14 0 10.8598 0 7C0 3.14023 3.14022 0 7 0ZM7 1.05C5.54402 1.05 4.22994 1.59022 3.19717 2.45479L11.5452 10.8021C12.4096 9.76943 12.95 8.45579 12.95 7C12.95 3.70769 10.2923 1.05 7 1.05ZM2.45479 3.19717C1.59022 4.22994 1.05 5.54402 1.05 7C1.05 10.2923 3.70768 12.95 7 12.95C8.45579 12.95 9.76943 12.4096 10.8021 11.5452L2.45479 3.19717Z" />
  </svg>
);

export default StopIcon;

const AssessmentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { color = "#FFA64C", ...otherProps } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M14.0625 16.7344H3.9375C3.52737 16.734 3.13416 16.5709 2.84415 16.2808C2.55415 15.9908 2.39104 15.5976 2.39062 15.1875V2.8125C2.39104 2.40237 2.55415 2.00916 2.84415 1.71915C3.13416 1.42915 3.52737 1.26604 3.9375 1.26562H14.0625C14.4726 1.26604 14.8658 1.42915 15.1558 1.71915C15.4459 2.00916 15.609 2.40237 15.6094 2.8125V15.1875C15.609 15.5976 15.4459 15.9908 15.1558 16.2808C14.8658 16.5709 14.4726 16.734 14.0625 16.7344ZM3.9375 2.10938C3.75107 2.10955 3.57233 2.18368 3.44051 2.31551C3.30868 2.44733 3.23455 2.62607 3.23438 2.8125V15.1875C3.23455 15.3739 3.30868 15.5527 3.44051 15.6845C3.57233 15.8163 3.75107 15.8905 3.9375 15.8906H14.0625C14.2489 15.8905 14.4277 15.8163 14.5595 15.6845C14.6913 15.5527 14.7655 15.3739 14.7656 15.1875V2.8125C14.7655 2.62607 14.6913 2.44733 14.5595 2.31551C14.4277 2.18368 14.2489 2.10955 14.0625 2.10938H3.9375Z"
        fill={color}
      />
      <path
        d="M11.7589 3.79688H6.24106C5.96587 3.79659 5.70203 3.68714 5.50745 3.49255C5.31286 3.29797 5.20341 3.03413 5.20312 2.75894V2.30356C5.20341 2.02837 5.31286 1.76453 5.50745 1.56995C5.70203 1.37536 5.96587 1.26591 6.24106 1.26562H11.7589C12.0341 1.26591 12.298 1.37536 12.4926 1.56995C12.6871 1.76453 12.7966 2.02837 12.7969 2.30356V2.75894C12.7966 3.03413 12.6871 3.29797 12.4926 3.49255C12.298 3.68714 12.0341 3.79659 11.7589 3.79688ZM6.24106 2.10938C6.18957 2.10942 6.1402 2.12989 6.1038 2.1663C6.06739 2.2027 6.04692 2.25207 6.04688 2.30356V2.75894C6.04692 2.81043 6.06739 2.8598 6.1038 2.8962C6.1402 2.93261 6.18957 2.95308 6.24106 2.95312H11.7589C11.8104 2.95308 11.8598 2.93261 11.8962 2.8962C11.9326 2.8598 11.9531 2.81043 11.9531 2.75894V2.30356C11.9531 2.25207 11.9326 2.2027 11.8962 2.1663C11.8598 2.12989 11.8104 2.10942 11.7589 2.10938H6.24106Z"
        fill={color}
      />
      <path
        d="M12.9375 7.17188H9.5625C9.45061 7.17188 9.34331 7.12743 9.26419 7.04831C9.18507 6.96919 9.14062 6.86189 9.14062 6.75C9.14062 6.63811 9.18507 6.53081 9.26419 6.45169C9.34331 6.37257 9.45061 6.32812 9.5625 6.32812H12.9375C13.0494 6.32812 13.1567 6.37257 13.2358 6.45169C13.3149 6.53081 13.3594 6.63811 13.3594 6.75C13.3594 6.86189 13.3149 6.96919 13.2358 7.04831C13.1567 7.12743 13.0494 7.17188 12.9375 7.17188Z"
        fill={color}
      />
      <path
        d="M11.8125 8.85938H9.5625C9.45061 8.85938 9.34331 8.81493 9.26419 8.73581C9.18507 8.65669 9.14062 8.54939 9.14062 8.4375C9.14062 8.32561 9.18507 8.21831 9.26419 8.13919C9.34331 8.06007 9.45061 8.01562 9.5625 8.01562H11.8125C11.9244 8.01562 12.0317 8.06007 12.1108 8.13919C12.1899 8.21831 12.2344 8.32561 12.2344 8.4375C12.2344 8.54939 12.1899 8.65669 12.1108 8.73581C12.0317 8.81493 11.9244 8.85938 11.8125 8.85938Z"
        fill={color}
      />
      <path
        d="M12.9375 11.6719H9.5625C9.45061 11.6719 9.34331 11.6274 9.26419 11.5483C9.18507 11.4692 9.14062 11.3619 9.14062 11.25C9.14062 11.1381 9.18507 11.0308 9.26419 10.9517C9.34331 10.8726 9.45061 10.8281 9.5625 10.8281H12.9375C13.0494 10.8281 13.1567 10.8726 13.2358 10.9517C13.3149 11.0308 13.3594 11.1381 13.3594 11.25C13.3594 11.3619 13.3149 11.4692 13.2358 11.5483C13.1567 11.6274 13.0494 11.6719 12.9375 11.6719Z"
        fill={color}
      />
      <path
        d="M11.8125 13.3594H9.5625C9.45061 13.3594 9.34331 13.3149 9.26419 13.2358C9.18507 13.1567 9.14062 13.0494 9.14062 12.9375C9.14062 12.8256 9.18507 12.7183 9.26419 12.6392C9.34331 12.5601 9.45061 12.5156 9.5625 12.5156H11.8125C11.9244 12.5156 12.0317 12.5601 12.1108 12.6392C12.1899 12.7183 12.2344 12.8256 12.2344 12.9375C12.2344 13.0494 12.1899 13.1567 12.1108 13.2358C12.0317 13.3149 11.9244 13.3594 11.8125 13.3594Z"
        fill={color}
      />
      <path
        d="M6.18761 8.86016C6.09632 8.86006 6.0075 8.83049 5.93438 8.77584L4.80938 7.93209C4.71985 7.86493 4.66067 7.76495 4.64486 7.65415C4.62905 7.54336 4.6579 7.43082 4.72506 7.34129C4.79222 7.25177 4.8922 7.19259 5.00299 7.17678C5.11379 7.16097 5.22633 7.18981 5.31585 7.25698L6.09533 7.84172L7.25054 6.22428C7.31576 6.13367 7.41424 6.07259 7.5244 6.05444C7.63456 6.03629 7.74743 6.06254 7.83827 6.12744C7.92912 6.19234 7.99054 6.2906 8.00908 6.4007C8.02762 6.5108 8.00177 6.62375 7.93719 6.71483L6.53094 8.68358C6.4919 8.73822 6.44038 8.78275 6.38067 8.81346C6.32095 8.84418 6.25477 8.86019 6.18761 8.86016Z"
        fill={color}
      />
      <path
        d="M6.1875 13.9219C5.88156 13.9219 5.58249 13.8312 5.3281 13.6612C5.07372 13.4912 4.87545 13.2496 4.75837 12.967C4.6413 12.6843 4.61066 12.3733 4.67035 12.0732C4.73003 11.7732 4.87736 11.4975 5.09369 11.2812C5.31003 11.0649 5.58566 10.9175 5.88572 10.8578C6.18579 10.7982 6.49681 10.8288 6.77946 10.9459C7.06212 11.063 7.30371 11.2612 7.47368 11.5156C7.64365 11.77 7.73438 12.0691 7.73438 12.375C7.73396 12.7851 7.57085 13.1783 7.28085 13.4683C6.99084 13.7584 6.59763 13.9215 6.1875 13.9219ZM6.1875 11.6719C6.04844 11.6719 5.91249 11.7131 5.79687 11.7904C5.68124 11.8676 5.59112 11.9774 5.5379 12.1059C5.48468 12.2344 5.47076 12.3758 5.49789 12.5122C5.52502 12.6486 5.59198 12.7739 5.69032 12.8722C5.78865 12.9705 5.91393 13.0375 6.05033 13.0646C6.18672 13.0917 6.3281 13.0778 6.45658 13.0246C6.58505 12.9714 6.69487 12.8813 6.77213 12.7656C6.84939 12.65 6.89063 12.5141 6.89063 12.375C6.89045 12.1886 6.81632 12.0098 6.68449 11.878C6.55267 11.7462 6.37393 11.672 6.1875 11.6719Z"
        fill={color}
      />
    </svg>
  );
};

export default AssessmentIcon;

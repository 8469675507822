const GoogleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1711 8.36775H17.4998V8.33317H9.99984V11.6665H14.7094C14.0223 13.6069 12.1761 14.9998 9.99984 14.9998C7.23859 14.9998 4.99984 12.7611 4.99984 9.99984C4.99984 7.23859 7.23859 4.99984 9.99984 4.99984C11.2744 4.99984 12.434 5.48067 13.3169 6.26609L15.674 3.909C14.1857 2.52192 12.1948 1.6665 9.99984 1.6665C5.39775 1.6665 1.6665 5.39775 1.6665 9.99984C1.6665 14.6019 5.39775 18.3332 9.99984 18.3332C14.6019 18.3332 18.3332 14.6019 18.3332 9.99984C18.3332 9.44109 18.2757 8.89567 18.1711 8.36775Z"
      fill="#FFC107"
    />
    <path
      d="M2.62744 6.12109L5.36536 8.129C6.10619 6.29484 7.90036 4.99984 9.99994 4.99984C11.2745 4.99984 12.4341 5.48067 13.317 6.26609L15.6741 3.909C14.1858 2.52192 12.1949 1.6665 9.99994 1.6665C6.79911 1.6665 4.02327 3.47359 2.62744 6.12109Z"
      fill="#FF3D00"
    />
    <path
      d="M9.9998 18.3331C12.1523 18.3331 14.1081 17.5094 15.5869 16.1698L13.0077 13.9873C12.1711 14.621 11.1311 14.9998 9.9998 14.9998C7.8323 14.9998 5.99189 13.6177 5.29855 11.689L2.58105 13.7827C3.96022 16.4815 6.76105 18.3331 9.9998 18.3331Z"
      fill="#4CAF50"
    />
    <path
      d="M18.1712 8.36808H17.5V8.3335H10V11.6668H14.7096C14.3796 12.5989 13.78 13.4027 13.0067 13.9881C13.0071 13.9877 13.0075 13.9877 13.0079 13.9872L15.5871 16.1697C15.4046 16.3356 18.3333 14.1668 18.3333 10.0002C18.3333 9.44141 18.2758 8.896 18.1712 8.36808Z"
      fill="#1976D2"
    />
  </svg>
);

export default GoogleIcon;

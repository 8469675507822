import authRoutes from "./auth";
import appRoutes from "./app";

const routes = [
  ...appRoutes,
  ...authRoutes,
  // {
  //   path: "*",
  //   element: <NotFound />,
  // },
];

export default routes;

import "./_style.less";
import React from "react";
import { useNavigate } from "react-router-dom";
import CalendarFullIcon from "../../../icons/CalendarFull";

interface IEventCard {
  id: number;
  image: string;
  name: string;
  date: string;
}

const EventCard: React.FC<IEventCard> = ({ id, image, name, date }) => {
  let navigate = useNavigate();

  return (
    <>
      <div className="event-card" onClick={() => navigate(`/events/${id}`)}>
        <div className="image-wrapper">
          <img className="image" src={image || '/images/no-image.jpg'} alt="event" />
        </div>
        <div className="info-wrapper">
          <h3 className="name">{name}</h3>
          <p className="date">
            <CalendarFullIcon className="icon" />
            {date}
          </p>
        </div>
      </div>
    </>
  );
};

export default EventCard;

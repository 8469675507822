import { AxiosPromise } from "axios";
import { api, IFindAllRepository, IFindOneRepository, IResource } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends IFindAllRepository<T>, IFindOneRepository<T> {
  highlight(params?: any): AxiosPromise<IResource<T>>;
}

const ArticleRepository: IRepository<Event> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/article`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`${endPoint()}/api/article/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  highlight: function(params){
    return api.get(`${endPoint()}/api/article-highlight`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  }
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  ArticleRepository
);

export default ArticleRepository;

import { FC } from "react";
import Typography from "antd/lib/typography";
import "./_style.less";

const { Text } = Typography;

interface IVuiVuiSubtitle {
  text: string;
}

const VuiSubtitle: FC<IVuiVuiSubtitle> = ({ text }) => {
  return (
    <>
      <Text className="vui-subtitle" strong>
        {text}
      </Text>
    </>
  );
};

export default VuiSubtitle;

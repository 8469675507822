import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { useCallback, useMemo, useState } from "react";
import { passportService } from "../services";
import { useIsMounted } from "./index";
import { update } from "../../../stores/system";
import { OAuthGrantType } from "../services/passport.service";
import OtpRepository from "../../../repositories/OtpRepository";
import { OtpProvider, OtpType } from "../interfaces";

const usePassportService = (disableAutoFetchUser = false) => {
  const dispatch = useAppDispatch();
  const system = useAppSelector((state) => state.system);
  const isMounted = useIsMounted();

  const [isOnSignIn, setIsOnSignIn] = useState(false);
  const [isOnRequestOtp, setIsOnRequestOtp] = useState(false);
  const [isOnFetchingUser, setIsOnFetchingUser] = useState(
    !disableAutoFetchUser
  );

  const fetchUser = async () => {
    const accessToken = passportService.getAccessToken();
    if (accessToken && !system.isLoggedIn) {
      setIsOnFetchingUser(true);
      await passportService
        .fetchUser()
        .then((response: any) => {
          if (isMounted) {
            dispatch(
              update({
                isLoggedIn: true,
                account: response.data.data,
              })
            );
          }
        })
        .catch(() => {});
    }

    setIsOnFetchingUser(false);
  };

  const refetchUser = async () => {
    await passportService
      .fetchUser()
      .then((response: any) => {
        if (isMounted) {
          dispatch(
            update({
              isLoggedIn: true,
              account: response.data.data,
            })
          );
        }
      })
      .catch(() => {});
  };

  const login = async (
    username: string,
    password: string,
    scope: string = "*",
    grantType: OAuthGrantType = "password"
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsOnSignIn(true);

      await passportService
        .login(username, password, scope, grantType)
        .then((response) => {
          setIsOnFetchingUser(true);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });

      setIsOnSignIn(false);
    });
  };

  const requestOtp = async (
    phone: string,
    provider: OtpProvider,
    type: OtpType
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsOnRequestOtp(true);

      await OtpRepository.requestOtp({
        phone_number: phone,
        provider: provider,
        type: type,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });

      setIsOnRequestOtp(false);
    });
  };

  const logout = useCallback(async () => {
    await passportService
      .logout()
      .then(() => {
        localStorage.setItem("isLoggedIn", "false");
        dispatch(
          update({
            isLoggedIn: false,
            account: null,
          })
        );
      })
      .catch(() => {});
  }, [dispatch]);

  useMemo(() => {
    (async () => {
      if (isOnFetchingUser) {
        await fetchUser()
          .then(() => {})
          .catch(() => {});
      }
    })();
  }, [isOnFetchingUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isOnSignIn,
    isOnFetchingUser,
    login,
    logout,
    fetchUser,
    requestOtp,
    isOnRequestOtp,
    refetchUser,
  };
};

export default usePassportService;

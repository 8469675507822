const PreviewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.51316 0C1.40324 0 0.5 0.913357 0.5 2.03571V16.0595C0.5 17.1819 1.40324 18.0952 2.51316 18.0952H8.39186C8.00936 17.6931 7.68273 17.2371 7.4246 16.7381H2.51316C2.14318 16.7381 1.84211 16.4336 1.84211 16.0595V2.03571C1.84211 1.6616 2.14318 1.35714 2.51316 1.35714H7.65789V5.20238C7.65789 6.32474 8.56113 7.2381 9.67105 7.2381H13.4737V8.21973C13.9412 8.29301 14.3921 8.41741 14.8158 8.597V6.55952C14.8158 6.37201 14.7406 6.20252 14.6192 6.07975L8.8034 0.1988C8.682 0.0760353 8.51438 0 8.32895 0H2.51316ZM9 2.31669L12.5248 5.88095H9.67105C9.30108 5.88095 9 5.5765 9 5.20238V2.31669ZM12.5789 9.04762C9.86118 9.04762 7.65789 11.2756 7.65789 14.0238C7.65789 16.772 9.86118 19 12.5789 19C15.2967 19 17.5 16.772 17.5 14.0238C17.5 11.2756 15.2967 9.04762 12.5789 9.04762ZM12.5789 11.7619C14.8972 11.7619 16.0489 13.7131 16.0967 13.7959C16.1777 13.937 16.1777 14.1106 16.0967 14.2518C16.0489 14.335 14.8972 16.2857 12.5789 16.2857C10.2607 16.2857 9.10903 14.3346 9.06116 14.2518C8.98019 14.1106 8.98019 13.937 9.06116 13.7959C9.10903 13.7131 10.2607 11.7619 12.5789 11.7619ZM12.5789 12.6667C11.8377 12.6667 11.2368 13.2742 11.2368 14.0238C11.2368 14.7734 11.8377 15.381 12.5789 15.381C13.3202 15.381 13.9211 14.7734 13.9211 14.0238C13.9211 13.2742 13.3202 12.6667 12.5789 12.6667Z"
      fill="#C6C8CC"
    />
  </svg>
);

export default PreviewIcon;

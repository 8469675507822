import "./style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { VuiFormCard, VuiHelmet } from "../../../../@vendor/components";
import { PGPlainLogo, TickIllustration } from "../../../../components";

const CounselingFormSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/account?tab=schedule", { replace: true });
    }, 2000);
  }, []);

  return (
    <div id="counseling-form-success-page">
      <VuiHelmet title={t("common.text.counselingFormSuccess")} />
      <div className="counseling-form-success-wrapper">
        <PGPlainLogo />
        <VuiFormCard className="counseling-form-success-card">
          <TickIllustration style={{ marginBottom: 16 }} />

          <Paragraph className="counseling-form-success-title">
            {t("common.text.thankYou")}
          </Paragraph>
          <Paragraph className="counseling-form-success-desc">
            {t("common.text.dataRecordSuccess")}
          </Paragraph>
        </VuiFormCard>
      </div>
    </div>
  );
};

export default CounselingFormSuccessPage;
